import { ReviewMachineContext, SubmitReturnEventData } from './type';

import { ItemResolution, Resolution, ReturnMethodSlug } from '../../constant';
import { PostReturnsInput, RefundDestinationType } from '../../types';
import { PickupAddressSource, PickupInfomationData } from '../returnMethodSubFlow/types';

export const resolutionIsRefund = (
  resolution?: Resolution,
): resolution is RefundDestinationType => {
  return !!(
    resolution &&
    (resolution === Resolution.OriginalPayment ||
      resolution === Resolution.StoreCredit ||
      resolution === Resolution.Refundid)
  );
};

const getPickupPayload = (pickupData?: PickupInfomationData) => {
  return {
    pickup_contact: pickupData?.pickupContact
      ? {
          first_name: pickupData.pickupContact?.firstName,
          phone_number: pickupData.pickupContact?.phoneNumber,
          last_name: pickupData.pickupContact?.lastName,
        }
      : null,
    pickup_address:
      pickupData?.pickupAddress && pickupData?.pickupAddressSource === PickupAddressSource.manual
        ? {
            address_line_1: pickupData?.pickupAddress?.addressLine1,
            city: pickupData?.pickupAddress?.city,
            country: pickupData?.pickupAddress?.country,
            postal_code: pickupData?.pickupAddress?.postalCode,
            state: pickupData?.pickupAddress?.state,
          }
        : null,
    pickup_appointment_date: pickupData?.pickupAppointmentDate ?? '',
    pickup_instructions: pickupData?.pickupInstructions ?? '',
    pickup_info_from_order: pickupData?.pickupAddressSource === PickupAddressSource.order,
  };
};

export const getPostReturnPayload = (
  context: ReviewMachineContext,
  eventData?: SubmitReturnEventData,
): PostReturnsInput['input'] => {
  const { restockItems, ...payload } = eventData || {};
  return {
    token: context?.token,
    payload: {
      ...payload,
      order: {
        id: context?.orderId,
      },
      organization: {
        id: context?.orgId,
      },
      return_items: context?.selectedItems?.map((item) => ({
        item_id: item.itemId,
        quantity: item.quantity,
        reason: item.selectedReason,
        subreason: item.selectedSubReason,
        notes_to_merchant: item.comment,
        return_images: item?.returnImages,
        resolution:
          context.resolution === Resolution.ReplaceTheSameItem
            ? ItemResolution.Exchange
            : ItemResolution.Refund,
        ...(item.exchangeVariant
          ? {
              replacement: {
                external_product_id: item.exchangeVariant?.productId,
                external_variant_id: item.exchangeVariant?.variantId,
              },
            }
          : {}),
        intended_restock_location_id: restockItems?.[item.itemId],
      })),
      // TODO 灰度策略，第一期是 rma 纬度，后续是 item 纬度
      resolution_granularity: 'rma',
      return_method: {
        id: context?.returnMethod?.id,
      },
      instant_exchange: {
        is_instant_exchange: !!context?.instantExchange,
      },
      ...(context?.exchangeRequest
        ? {
            exchange_request: context.exchangeRequest,
          }
        : {}),
      ...(context?.exchangeItems?.length
        ? {
            exchange_items: context.exchangeItems?.map((item) => ({
              external_product_id: item.productId,
              external_variant_id: item.variantId,
              quantity: item.quantity,
            })),
          }
        : {}),
      ...(context?.refundDestination
        ? { refund_destination: context.refundDestination }
        : resolutionIsRefund(context.resolution)
          ? {
              refund_destination: context.resolution,
            }
          : {}),
      ...(context?.filedBy
        ? {
            filed_by: context.filedBy,
          }
        : {}),
      gift_return: {
        is_gift_return: !!context?.isGiftReturn,
        recipient_email: context?.giftReturnShippingAddress?.email ?? null,
        recipient_first_name: context?.giftReturnShippingAddress?.first_name ?? null,
        recipient_last_name: context?.giftReturnShippingAddress?.last_name ?? null,
        recipient_shipping_address: context?.giftReturnRecipientAddress ?? null,
      },
      ...(context?.preview?.exchange_shipping_address
        ? {
            exchange_shipping_address: context?.preview?.exchange_shipping_address,
          }
        : {}),
      ...(context?.preview?.summary?.cost_of_return_set?.presentment_money
        ? {
            cost_of_return: context?.preview?.summary?.cost_of_return_set?.presentment_money,
          }
        : {}),
      ...(context?.returnMethod?.slug === ReturnMethodSlug.CarrierPickup
        ? getPickupPayload(context?.returnMethod?.pickupData)
        : {}),
    },
  };
};
