import { t } from 'i18next';
import Image from 'next/image';
import React from 'react';

import { Box, Flex, Text } from '@aftership/design-system';
import { ColorVars, RadiusVars, SpacingVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice.ts';

import { containerStyle } from './styles.css.ts';

const ReturnCareTips = () => {
  const isMobile = useDevice().mobile;
  return (
    <Box
      className={containerStyle}
      paddingX={SpacingVars['4']}
      paddingY={SpacingVars['3']}
      borderRadius={RadiusVars['4']}
    >
      <Flex gap={SpacingVars[isMobile ? '1' : '2']} alignItems={'center'}>
        <Image
          src={require('@/assets/ic-return-care.png').default?.src}
          width={24}
          height={24}
          alt='returnCare'
        />
        <Text variant={'body1'} color={ColorVars['Orange']['Orange 1200']}>
          {t('page.banner.returnCare')}
        </Text>
      </Flex>
    </Box>
  );
};

export default ReturnCareTips;
