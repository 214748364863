import { cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { Resolution } from '@aftership/returns-logics-core';

import { useSummaryContext } from '@/features/return-detail/components/Summary/SummaryProvider';
import Accordion from '@/features/return-detail/components/Summary/components/Accordion';
import CostOfReturnSection from '@/features/return-detail/components/Summary/components/CostOfReturnSection';
import ExchangesSection from '@/features/return-detail/components/Summary/components/ExchangesSection';
import ReturnCreditsSection from '@/features/return-detail/components/Summary/components/ReturnCreditsSection';
import ShippingFeeSection from '@/features/return-detail/components/Summary/components/ShippingFeeSection';
import TotalSection from '@/features/return-detail/components/Summary/components/TotalSection';
import { useGetExchangeShippingValue } from '@/features/return-detail/components/Summary/hooks';
import { getCostOfReturnValue } from '@/features/return-method/utils/costOfReturn';

const PayNowSummary = () => {
  const { t } = useTranslation();
  const { previewSummary, resolution } = useSummaryContext();
  const [isFold, setIsFold] = useState(true);

  const isExchange =
    resolution === Resolution.ReplaceTheSameItem || resolution === Resolution.ExchangeForAnything;
  const isExchangeForAnything = resolution === Resolution.ExchangeForAnything;

  const shippingFeeValue = useGetExchangeShippingValue({
    shippingFee: previewSummary?.exchange_shipping_fee_set?.presentment_money,
    shippingFeeOption: previewSummary?.exchange_shipping_fee_option,
  });

  const costOfReturnValue = getCostOfReturnValue(
    previewSummary?.cost_of_return_set?.presentment_money,
    previewSummary?.return_cost_option,
  );

  const isShowShippingFee = !!shippingFeeValue;
  const isShowCostOfReturn = !!costOfReturnValue;

  const sections = [
    isExchange && <ExchangesSection isFold={false} key='exchange' />,
    <ReturnCreditsSection
      isExchangeForAnything={isExchangeForAnything}
      isFold={false}
      key={'returnCredits'}
    />,
    isShowShippingFee && (
      <ShippingFeeSection key='shippingFee' shippingFeeValue={shippingFeeValue} />
    ),
    isShowCostOfReturn && (
      <CostOfReturnSection key='costOfReturn' costOfReturnValue={costOfReturnValue} />
    ),
  ]?.filter(Boolean) as React.ReactElement[];

  return (
    previewSummary && (
      <Flex direction='column' gap={SpacingVars['3']}>
        <Flex justifyContent='space-between'>
          <Text variant='subtitle1'>{t('page.description.summary')}</Text>
          <Accordion isFold={isFold} setIsFold={setIsFold} />
        </Flex>
        <Flex direction='column' gap={SpacingVars['3']}>
          {!isFold && (
            <>
              {sections?.map((children, index) => {
                return cloneElement(children, {
                  ...children.props,
                  showDivider: index + 1 !== sections.length,
                });
              })}
            </>
          )}
          <TotalSection />
        </Flex>
      </Flex>
    )
  );
};

export default PayNowSummary;
