'use client';

import { cloneElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardHeader, CardSection, Flex, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { Resolution } from '@aftership/returns-logics-core';

import { getCostOfReturnValue } from '@/features/return-method/utils/costOfReturn';
import { getStoreCreditIncentive } from '@/features/return-method/utils/storeCreditIncentive';

import { useSummaryContext } from './SummaryProvider';
import Accordion from './components/Accordion';
import CostOfReturnSection from './components/CostOfReturnSection';
import ExchangesSection from './components/ExchangesSection';
import ReturnCreditsSection from './components/ReturnCreditsSection';
import ShippingFeeSection from './components/ShippingFeeSection';
import StoreCreditIncentive from './components/StoreCreditIncentive';
import TotalSection from './components/TotalSection';
import { useGetExchangeShippingValue, useHideSummary } from './hooks';

import useCartStyle from '../../hooks/useCartStyle';

const Summary = () => {
  const { resolution, isFold, setIsFold, isPreview, previewSummary } = useSummaryContext();
  const { summaryCartProps, isMobile } = useCartStyle();

  const { t } = useTranslation();

  const isExchange =
    resolution === Resolution.ReplaceTheSameItem || resolution === Resolution.ExchangeForAnything;
  const isExchangeForAnything = resolution === Resolution.ExchangeForAnything;

  const { hideSummary } = useHideSummary({
    resolution,
  });

  const shippingFeeValue = useGetExchangeShippingValue({
    shippingFee: previewSummary?.exchange_shipping_fee_set?.presentment_money,
    shippingFeeOption: previewSummary?.exchange_shipping_fee_option,
  });

  const costOfReturnValue = getCostOfReturnValue(
    previewSummary?.cost_of_return_set?.presentment_money,
    previewSummary?.return_cost_option,
  );

  const storeCreditIncentiveValue = getStoreCreditIncentive(
    previewSummary?.store_credit_incentive_set?.presentment_money,
  );

  const isShowShippingFee = !!shippingFeeValue;
  const isShowCostOfReturn = !!costOfReturnValue;

  const sections = [
    isExchange ? <ExchangesSection isFold={isFold} key={'exchange'} /> : null,
    <ReturnCreditsSection
      isExchangeForAnything={isExchangeForAnything}
      isFold={isFold}
      key={'returnCredits'}
    />,
    isShowShippingFee && (
      <ShippingFeeSection key='shippingFee' shippingFeeValue={shippingFeeValue} />
    ),
    storeCreditIncentiveValue && (
      <StoreCreditIncentive storeCreditIncentiveValue={storeCreditIncentiveValue} />
    ),
    isShowCostOfReturn && (
      <CostOfReturnSection key='costOfReturn' costOfReturnValue={costOfReturnValue} />
    ),
  ]?.filter(Boolean) as React.ReactElement[];

  const summaryLayoutGap = useMemo(() => {
    if (!isMobile) {
      return isFold ? SpacingVars['3'] : 0;
    } else {
      return isFold ? SpacingVars['3'] : SpacingVars['1'];
    }
  }, [isFold, isMobile]);

  return (
    !hideSummary && (
      <Flex direction='column' gap={10}>
        <Card {...summaryCartProps}>
          <CardHeader>
            <Flex direction='row' gap={10} justifyContent='space-between'>
              <Text variant='title3'>{t('dynamic.requestReview.summary')}</Text>
              <Accordion isFold={isFold} setIsFold={setIsFold} isPreview={isPreview} />
            </Flex>
          </CardHeader>

          <CardSection>
            <Flex direction='column' gap={summaryLayoutGap}>
              {sections?.map((children, index) => {
                return cloneElement(children, {
                  ...children.props,
                  showDivider: index + 1 !== sections.length,
                });
              })}
            </Flex>
            <Flex direction='column'>
              <TotalSection />
            </Flex>
          </CardSection>
        </Card>
      </Flex>
    )
  );
};

export default Summary;
