import dayjs from 'dayjs';

export const HAPPY_RETURN_PRIVACY_POLICY_LINK = 'https://www.privacypolicy.happyreturns.com/';

export const PICK_UP_DATE_LIMIT = 14;
export const PICK_UP_MIN_DATE = dayjs().format('YYYY-MM-DD');
export const PICK_UP_MAX_DATE = dayjs()
  .add(PICK_UP_DATE_LIMIT - 1, 'day')
  .format('YYYY-MM-DD');

export const PICK_UP_LAST_14_DAYS = Array.from({ length: 14 }, (_, index) => {
  // 从明天开始，依次生成后续日期
  const date = dayjs()
    .add(index + 1, 'day')
    .startOf('day');

  return {
    date,
    disabled: false,
  };
});
