import React, { useState } from 'react';

import { ListBox, ListBoxItem } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { SelectionGroupingItem } from '@aftership/returns-logics-core';

import { SelectedItem } from '@/features/request-returns/types';
import useDevice from '@/hooks/useDevice';

import { ReturnableItem } from '../ReturnableItem';

export interface Props {
  items: Array<RequestReturn.ReturnableItemProps>;
  /**
   * 选中的 item  + line item 列表
   */
  selectedItems: Array<SelectedItem>;
  /**
   * 所有 item  + line item 可退的组合
   */
  itemSelectionGrouping: Array<SelectionGroupingItem>;
  /**
   * 删除 item or line item 时的回调
   * @param itemId
   * @param parentId
   */
  onRemoveItem: (itemId: string, parentId: Maybe<string>) => void;
  /**
   * 编辑 item or line item 时的回调
   * @param itemId
   * @param parentId
   */
  onEditItem: (itemId: string, parentId: Maybe<string>) => void;

  /**
   * 是否限制每次退货只能退一种商品
   */
  isLimitSingleItemPerReturn: boolean;
  /**
   * 是否开启了可选 item 受 routing rule 影响
   */
  isLimitSelectionByRoutingRule: boolean;
}

const ReturnableList = ({
  items,
  selectedItems,
  itemSelectionGrouping,
  isLimitSelectionByRoutingRule,
  isLimitSingleItemPerReturn,
  onRemoveItem,
  onEditItem,
}: Props) => {
  const [expandedId, setExpandedId] = useState('');
  const isMobile = useDevice().mobile;

  const handleExpandItem = (itemId: string) => {
    setExpandedId(expandedId === itemId ? '' : itemId);
  };
  const selectedKeys = selectedItems.map((item) => item.itemId);

  return (
    <ListBox
      selectionMode='single'
      rowGap={SpacingVars[isMobile ? '4' : '3']}
      items={items.map((item) => ({
        id: item.itemId,
        ...item,
      }))}
      selectedKeys={selectedKeys}
    >
      {(item) => {
        return (
          <ListBoxItem key={item.itemId}>
            {() => {
              return (
                <ReturnableItem
                  returnableItem={item}
                  selectedItems={selectedItems}
                  itemSelectionGrouping={itemSelectionGrouping}
                  isLimitSelectionByRoutingRule={isLimitSelectionByRoutingRule}
                  isLimitSingleItemPerReturn={isLimitSingleItemPerReturn}
                  onRemove={onRemoveItem}
                  onEdit={onEditItem}
                  onExpand={handleExpandItem}
                  expandId={expandedId}
                />
              );
            }}
          </ListBoxItem>
        );
      }}
    </ListBox>
  );
};

export default ReturnableList;
