import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useToast } from '@aftership/design-system';
import { ErrorCode, RCErrorDetailCode } from '@aftership/returns-logics-core';
import { ErrorResponse, IExchangeItem, MetaErrorItem } from '@aftership/returns-logics-core';

import { PayStatus } from '../type';

const useCheckoutError = ({
  exchangeItems,
  setPayStatus,
}: {
  exchangeItems?: IExchangeItem[];
  setPayStatus: Dispatch<SetStateAction<PayStatus | null>>;
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  // 错误处理
  const handleCheckoutError = useCallback(
    (error?: ErrorResponse | null) => {
      const { code, message, errors } = error ?? {};
      switch (code) {
        case ErrorCode.MultiplePendingCheckoutsError: {
          setPayStatus(PayStatus.alreadyPay);
          break;
        }
        case ErrorCode.CreateReturnErrorsDetail: {
          const errorDetailCode = (errors as MetaErrorItem[])?.[0]?.code;
          if (errorDetailCode === RCErrorDetailCode.MultiplePendingDraftReturns) {
            setPayStatus(PayStatus.alreadyPay);
            break;
          } else if (errorDetailCode === RCErrorDetailCode.MinimumInventoryError) {
            const externalVariantId = errors?.[0]?.path;
            const product = exchangeItems?.find((item) => item.variantId === externalVariantId);
            const productTitle = `${product?.productTitle} ${
              product?.variantTitle ? `- ${product.variantTitle}` : ''
            }`;
            if (product) {
              showToast(t('page.error.inventoryNotEnough', { productTitle }), {
                type: 'error',
                placement: 'bottom',
                duration: 2000,
              });
            }
            break;
          } else if (message) {
            showToast(message, {
              type: 'error',
              placement: 'bottom',
            });
          }
          break;
        }
        case ErrorCode.TooManyRequests: {
          showToast(t('popup.description.submitTooOften'), {
            type: 'info',
            placement: 'bottom',
          });
          break;
        }
        default:
          if (message) {
            showToast(message, {
              type: 'error',
              placement: 'bottom',
            });
          }
      }
    },
    [exchangeItems, setPayStatus, showToast, t],
  );

  return {
    handleCheckoutError,
  };
};

export default useCheckoutError;
