import { IDraftReturn } from '@aftership/returns-logics-core';

import ShopifyPayModal from './components/ShopifyPayModal';
import StripeModal, { getPaymentDetail } from './components/StripeModal';

export interface PaymentModalProps {
  isOpen: boolean;
  draftReturn?: IDraftReturn | null;
  hasChargePayment?: boolean;
  isInstantExchange?: boolean;
  isReplacementNeedCostOfReturnCharge?: boolean;
  onCancel: VoidFunction;
  onContinue: VoidFunction;
  onAlreadyPaid: VoidFunction;
}

const PaymentModal = ({
  isOpen,
  draftReturn,
  hasChargePayment,
  isInstantExchange,
  isReplacementNeedCostOfReturnCharge,
  onContinue,
  onCancel,
  onAlreadyPaid,
}: PaymentModalProps) => {
  const isStripePay = !!draftReturn && !draftReturn?.checkout_url;
  const isShopifyPay = !!draftReturn && !!draftReturn?.checkout_url;

  const cancelDate = draftReturn ? new Date(draftReturn.created_at) : new Date();
  cancelDate.setMinutes(cancelDate.getMinutes() + 10);
  if (isStripePay) {
    const paymentDetail = getPaymentDetail(draftReturn);
    const stripeKey = draftReturn?.payment_app_key;
    const stripeToken = draftReturn?.payment_client_token;
    return (
      stripeKey &&
      stripeToken && (
        <StripeModal
          isOpen={isOpen}
          stripeKey={stripeKey}
          stripeToken={stripeToken}
          paymentDetail={paymentDetail}
          onCancel={onCancel}
          onContinue={onContinue}
          hasChargePayment={hasChargePayment}
          isInstantExchange={isInstantExchange}
          isReplacementNeedCostOfReturnCharge={isReplacementNeedCostOfReturnCharge}
        />
      )
    );
  }

  if (isShopifyPay) {
    return (
      <ShopifyPayModal
        isOpen={isOpen}
        checkoutUrl={draftReturn.checkout_url}
        targetDate={cancelDate}
        onContinue={onContinue}
        onCancel={onCancel}
        onAlreadyPaid={onAlreadyPaid}
      />
    );
  }

  return null;
};

export default PaymentModal;
