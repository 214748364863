/* eslint-disable react/no-unescaped-entities */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Banner, Box, Button, Flex, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { OrderLookupType } from '@aftership/returns-logics-core';
import { useFlow } from 'returns-logics/react';

import { Header } from '@/components/Header';
import { StepCard } from '@/components/StepCard';
import OrderLookupForm, {
  FormData,
  ValidateData,
} from '@/features/order-lookup/components/OrderLookupForm';
import ReturnsPageHeader from '@/features/returns/components/ReturnsPageHeader';
import { useReturns } from '@/features/returns/hooks/useReturns';
import { useShopInfo } from '@/features/returns/hooks/useShopInfo';
import useDevice from '@/hooks/useDevice';
import getTrackerInstance from '@/utils/tracker';
import { EventName } from '@/utils/tracker/consts';

import { GiftReturnTitle } from './GiftReturnTitle';

import { changeActions, formItemsCalss } from '../giftCard.css';
import { useQueryGiftReturnOrder } from '../hooks/useQueryGiftReturnOrder';

const OrderLookupInput = () => {
  const { t } = useTranslation();
  const { mobile } = useDevice();
  const shopInfo = useShopInfo();
  const { isCompact } = useReturns() ?? {};

  const { submit, isLoading } = useQueryGiftReturnOrder();

  const [errorMessage, setErrorMessage] = useState('');

  const flow = useFlow();

  const giftReturnDispatch = flow?.children?.giftReturnSubFlow?.dispatch;

  const handleSubmit = (formData: FormData) => {
    if (!formData?.verifyMethod) return;

    submit({
      orderNumber: formData?.orderNumber ?? '',
      orderLookupType: formData?.verifyMethod,
      ...(formData?.verifyMethod === OrderLookupType.EMAIL
        ? { customerEmail: formData.email }
        : {}),
      ...(formData?.verifyMethod === OrderLookupType.PHONE
        ? { phoneNumber: formData.phoneNumber }
        : {}),
      ...(formData?.verifyMethod === OrderLookupType.ZIPCODE
        ? { zipCode: formData.postalCode }
        : {}),
    });
  };

  const handleToNoOrderInfo = () => {
    getTrackerInstance().reportClickEvent({ eventName: EventName.clickGiftReturnNotMatch });
    giftReturnDispatch?.({
      type: 'goToFormWithoutOrderInfo',
    });
  };

  const onBack = () => {
    giftReturnDispatch?.({ type: 'goBack' });
  };

  const onValidate = ({ isValid, errorMessage }: ValidateData) => {
    if (isValid) return;
    setErrorMessage(errorMessage);
  };

  if (mobile) {
    return (
      <Flex direction='column' justifyContent={'end'} flex={1}>
        {!isCompact && <ReturnsPageHeader shopInfo={shopInfo} />}
        <Box flex={1}></Box>
        <Box
          backgroundColor={ColorVars['B&W'].Background}
          borderTopStartRadius={SpacingVars['6']}
          borderTopEndRadius={SpacingVars['6']}
          width={'100%'}
          paddingX={SpacingVars['4']}
          paddingY={SpacingVars['8']}
          overflow='hidden'
        >
          <Header title={<GiftReturnTitle />} onBack={onBack} />
          <Flex direction='column' gap={SpacingVars['8']} height={'100%'}>
            <Flex
              direction='column'
              alignItems='center'
              paddingTop={SpacingVars['6']}
              gap={SpacingVars['4']}
            >
              <Text variant='body2' textAlign='center' color={ColorVars['Grey']['Grey 1100']}>
                {t('page.gift.description.inputHint')}
              </Text>
              {errorMessage && (
                <Banner
                  info={<Text wordBreak='break-word'>{errorMessage}</Text>}
                  variant='warning'
                />
              )}
              <Flex direction='column' gap={SpacingVars['4']} width={'100%'}>
                <OrderLookupForm
                  onValidate={onValidate}
                  classNames={{
                    formItems: formItemsCalss,
                    changeAction: changeActions,
                  }}
                  renderActionButton={(isValid, data) => {
                    return (
                      <Box marginTop={SpacingVars['5']} marginBottom={SpacingVars['4']}>
                        <Button
                          size='large'
                          onPress={() => {
                            handleSubmit(data);
                          }}
                          isDisabled={!isValid}
                          isLoading={isLoading}
                        >
                          {t('page.gift.action.startGiftReturn')}
                        </Button>
                      </Box>
                    );
                  }}
                />
              </Flex>
            </Flex>
            <Flex
              direction='column'
              alignItems='center'
              gap={SpacingVars['4']}
              paddingBottom={SpacingVars['22']}
            >
              <Button variant='link' onPress={handleToNoOrderInfo}>
                <Text color={ColorVars['Grey']['Grey1200']} variant='subtitle1'>
                  {t('page.gift.action.startGiftReturnHint')}
                </Text>
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    );
  }

  return (
    <StepCard width={792} minHeight={462} title={<GiftReturnTitle />} onBack={onBack}>
      <Flex direction='column' paddingBottom={SpacingVars['10']} width={500} margin={'auto'}>
        <Flex direction='column' alignItems='center'>
          <Text
            variant='body2'
            paddingY={SpacingVars['8']}
            textAlign='center'
            color={ColorVars['Grey']['Grey 1100']}
          >
            {t('page.gift.description.inputHint')}
          </Text>
          <Flex direction='column' gap={SpacingVars['4']} width={'100%'}>
            {errorMessage && (
              <Banner info={<Text wordBreak='break-word'>{errorMessage}</Text>} variant='warning' />
            )}
            <OrderLookupForm
              onValidate={onValidate}
              classNames={{
                formItems: formItemsCalss,
                changeAction: changeActions,
              }}
              renderActionButton={(isValid, data) => {
                return (
                  <Box marginTop={SpacingVars['5']} marginBottom={SpacingVars['4']}>
                    <Button
                      size='large'
                      onPress={() => {
                        handleSubmit(data);
                      }}
                      isDisabled={!isValid}
                      isLoading={isLoading}
                    >
                      {t('page.gift.action.startGiftReturn')}
                    </Button>
                  </Box>
                );
              }}
            />
          </Flex>
        </Flex>

        <Flex direction='column' alignItems='center'>
          <Button variant='link' onPress={handleToNoOrderInfo}>
            <Text color={ColorVars['Grey']['Grey1200']} variant='subtitle1'>
              {t('page.gift.action.startGiftReturnHint')}
            </Text>
          </Button>
        </Flex>
      </Flex>
    </StepCard>
  );
};

export default OrderLookupInput;
