import {
  DropoffLocation,
  DropoffLocations,
  ReturnCostOption,
  ReturnMethod,
  ReturnMethodSlug,
} from '../../../core';

export const mockSelectedMethodId = '2fd88b7d-f46a-4d97-a5a1-1e8f2bcfb964';
export const mockSelectedMethodSlug = ReturnMethodSlug.CustomerCourier;

export const defaultReturnMethod: ReturnMethod[] = [
  {
    id: mockSelectedMethodId,
    shop_id: '2547dbe5-e39f-4c5f-8bac-47b791d07b6c',
    slug: mockSelectedMethodSlug,
    is_enabled: true,
    name: '{Return shipping method name}',
    description: '{Return shipping method description}',
    return_cost_option: ReturnCostOption.CUSTOM,
    return_cost_amount: '',
    shipping_instructions:
      '1. Pack all the returned items, along with a print off of your confirmation of purchase email, and a note specifying your RMA number securely in a box. You can use the box the items arrived in or another box if you prefer.\n\n2. Go to local post office, or another courier, to ship the package. Return address for the package:\nddd\n\n3. You will receive an email as soon as we have received your items, and when a refund is issued.',
    retail_store_location_url: '',
    zone_id: '552cbd66-aa34-4fdf-aeff-bbf3436ca0e6',
    rule_id: '83f3e2c6-33d0-4769-afa1-5bcb2e96db55',
    approval_process: 'manual',
    label_qr_code_enabled: false,
    created_at: '',
    updated_at: '',
    cost_of_return: { amount: '10', currency: 'HKD' },
    carrier_slug: '',
    pickup_instructions_required: false,
    pickup_date_weekend_disabled: false,
  },
];

export const defaultDropoffLocation: DropoffLocation = {
  locations: [],
  link: null,
};

export const defaultDropoffLocations: DropoffLocations = {
  [ReturnMethodSlug.HappyReturns]: defaultDropoffLocation,
  [ReturnMethodSlug.RetailRework]: defaultDropoffLocation,
};
