import { t } from 'i18next';
import React, { useState } from 'react';

import { Flex, Spinner } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import { ConfirmComp } from '@/features/request-returns/components/ConfirmComp';
import { FillReturnOverlay } from '@/features/request-returns/components/FillReturnOverlay';
import { NonReturnableList } from '@/features/request-returns/components/NonReturnableList';
import { ReturnableList } from '@/features/request-returns/components/ReturnableList';
import { useRequestReturnsInfo } from '@/features/request-returns/hooks';
import useDevice from '@/hooks/useDevice.ts';
import { useFieldAWithMemo } from '@/hooks/useFieldAWithMemo';
import { EventName } from '@/utils/tracker/consts.ts';
import getTrackerInstance from '@/utils/tracker/index.ts';

import { OrderWarningTips } from './components/OrderWarningTips';
import ReturnCareTips from './components/ReturnCareTips/ReturnCareTips.tsx';
import { wrapperStyle } from './style.css.ts';
import {
  convertISelectedItem2SelectedItem,
  convertOderItem2NonReturnableItem,
  convertOderItem2ReturnableItem,
} from './utils/convertor';

import NextButton from '../../components/NextButton/NextButton.tsx';
import ScrollFlex from '../../components/ScrollFlex/ScrollFlex.tsx';
import { useScrollUpdate } from '../atta/useSrollUpdate.ts';
import { RequestReturnsCardTitleText } from '../preview/components/WithPreviewSection/index.tsx';

const RequestReturns = () => {
  const isMobile = useDevice().mobile;
  const [pendingDeleteItemId, setPendingDeleteItemId] = useState<string>('');

  const {
    policyEnable,
    policyExternalLink,
    mainCurrentStep,
    isMerchantMode,
    isReturnCare,
    orderIneligibility,
    itemSelectionCurrentStep,
    itemSelectionDispatch,
    itemSelectionSubFlowSnapshot,
  } = useRequestReturnsInfo();

  const contextMemory = useFieldAWithMemo(itemSelectionSubFlowSnapshot);

  const scrollClassName = useScrollUpdate();

  if (itemSelectionCurrentStep?.isLoading) {
    return (
      <Flex flex={1} alignItems={'center'} justifyContent={'center'}>
        <Spinner />
      </Flex>
    );
  }

  const returnableItems =
    contextMemory?.returnableItems?.map<RequestReturn.ReturnableItemProps>((item) =>
      convertOderItem2ReturnableItem(item),
    ) ?? [];
  const itemSelectionGrouping = contextMemory?.itemSelectionGrouping ?? [];

  const nonReturnableItems =
    contextMemory?.nonReturnableItems?.map((item) => convertOderItem2NonReturnableItem(item)) ?? [];
  const selectedItems =
    contextMemory?.selectedItems?.map((item) => convertISelectedItem2SelectedItem(item)) ?? [];
  const activeOrderItem = contextMemory?.activeOrderItem;

  const eligibilityRules = contextMemory?.shopEligibilityRules;
  // 是否限制每次退货只能退一种商品
  const isLimitSingleItemPerReturn =
    Boolean(eligibilityRules?.product_limit?.feature_applied) &&
    Boolean(eligibilityRules?.product_limit?.limit_single_item_per_return?.enabled);
  // 是否开启了可选 item 受 routing rule 影响
  const isLimitSelectionByRoutingRule =
    Boolean(eligibilityRules?.product_limit?.feature_applied) &&
    Boolean(eligibilityRules?.product_limit?.limit_items_with_common_routing_rule?.enabled);

  const handleEditItem = (itemId: string, parentId: Maybe<string>) => {
    let currentSelectedOrderItem;
    if (parentId) {
      currentSelectedOrderItem = contextMemory?.returnableItems
        ?.find((item) => item.external_id === parentId)
        ?.bundledItems?.find((item) => item.external_id === itemId);
    } else {
      currentSelectedOrderItem = contextMemory?.returnableItems?.find(
        (item) => item.external_id === itemId,
      );
    }

    if (currentSelectedOrderItem) {
      getTrackerInstance().reportClickEvent({ eventName: EventName.selectItem });
      itemSelectionDispatch?.({
        type: 'SELECT_ITEM',
        data: {
          orderItem: currentSelectedOrderItem,
        },
      });
    }
  };
  const handleRemoveItem = (itemId: string) => {
    getTrackerInstance().reportClickEvent({ eventName: EventName.removeSelectedItem });

    itemSelectionDispatch?.({
      type: 'REMOVE_ITEM',
      data: {
        itemId,
      },
    });
  };
  const isMultipleReturn =
    orderIneligibility &&
    orderIneligibility.some((ineligibility) => ineligibility.reason === 'multiple_returns');
  const showBannerContainer = isMerchantMode || isMultipleReturn || isReturnCare;

  const renderOrderWarningBanner = () => {
    if (isMerchantMode) {
      return <OrderWarningTips tips={t('page.banner.overrideRule')} />;
    } else if (isMultipleReturn) {
      return <OrderWarningTips tips={t('page.error.orderCannotReturn')} />;
    }
  };

  const renderReturnCareBanner = () => {
    if (isReturnCare) {
      return <ReturnCareTips />;
    }
  };

  const isButtonDisable = selectedItems.length === 0;
  const isButtonLoading = mainCurrentStep.name === 'afterItemSelection';
  return (
    <>
      <Flex className={wrapperStyle} flex={1} direction={'column'}>
        <ScrollFlex className={scrollClassName}>
          {isMobile && (
            <RequestReturnsCardTitleText variant={'title2'} textAlign={'center'}>
              {t('page.request.whatItems')}
            </RequestReturnsCardTitleText>
          )}
          <Flex direction={'column'} gap={SpacingVars['6']}>
            {showBannerContainer && (
              <Flex direction={'column'} gap={SpacingVars['2']}>
                {renderOrderWarningBanner()}
                {renderReturnCareBanner()}
              </Flex>
            )}
            <ReturnableList
              items={returnableItems}
              selectedItems={selectedItems}
              itemSelectionGrouping={itemSelectionGrouping}
              isLimitSingleItemPerReturn={isLimitSingleItemPerReturn}
              isLimitSelectionByRoutingRule={isLimitSelectionByRoutingRule}
              onEditItem={handleEditItem}
              onRemoveItem={setPendingDeleteItemId}
            />
          </Flex>
          {Boolean(nonReturnableItems.length) && (
            <NonReturnableList
              policy={{
                policyUrl: policyExternalLink,
                policyEnable,
              }}
              nonReturnableItems={nonReturnableItems}
            />
          )}
        </ScrollFlex>
        <NextButton
          isLoading={isButtonLoading}
          isDisabled={isButtonDisable}
          onPress={() => {
            getTrackerInstance().reportClickEvent({
              eventName: EventName.clickItemSelectionPageNext,
            });
            itemSelectionDispatch?.({
              type: 'NEXT',
            });
          }}
        />
      </Flex>
      {activeOrderItem && (
        <FillReturnOverlay
          orderItem={activeOrderItem}
          isOpen={itemSelectionCurrentStep?.name === 'selectSingleItem'}
        />
      )}
      <ConfirmComp
        isOpen={!!pendingDeleteItemId}
        onClose={() => {
          setPendingDeleteItemId('');
        }}
        onConfirm={() => {
          setPendingDeleteItemId((pendingDeleteItemId) => {
            handleRemoveItem(pendingDeleteItemId);
            return '';
          });
        }}
      />
    </>
  );
};

export default RequestReturns;
