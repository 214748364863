import { useCallback, useEffect, useMemo, useState } from 'react';

import { LngLat } from '@aftership/returns-logics-core';

const useGeoLocation = () => {
  const [locationPermission, setLocationPermission] = useState<PermissionState | null>(null);
  const [location, setLocation] = useState<LngLat>();

  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then((res) => {
        setLocationPermission(res.state);
        if (res.state === 'granted') {
          navigator.geolocation.getCurrentPosition((position) => {
            setLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          });
        }
      });
    }
  }, []);

  const requestPreciseLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocationPermission('granted');
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      () => setLocationPermission('denied'),
    );
  }, []);

  return useMemo(
    () => ({
      location,
      locationPermission,
      requestPreciseLocation,
    }),
    [location, locationPermission, requestPreciseLocation],
  );
};

export default useGeoLocation;
