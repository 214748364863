import { useEffect, useMemo } from 'react';

import { getSecureSessionStorage } from '@aftership/returns-logics-core';

import { useUniversalRouting } from '@/features/returns/hooks/useUniversalRouting';
import queryParamsKeys from '@/features/returns/types/QueryParamsKeys';
import { IExternalConfig } from '@/features/returns/types/externalConfig';
import decodeBase64JsonString from '@/features/returns/utils/decodeBase64JsonString';

const sessionStorageKeys = {
  EXTERNAL_CONFIG: 'external_config',
};

export const useGetExternalConfig = (): IExternalConfig => {
  return useMemo(() => {
    return getExternalConfig();
  }, []);
};

export function getExternalConfig(): IExternalConfig {
  try {
    const externalConfigJSON = getSecureSessionStorage().getItem(
      sessionStorageKeys.EXTERNAL_CONFIG,
    );
    return JSON.parse(externalConfigJSON || '');
  } catch (e) {
    return {};
  }
}

export const useSetExternalConfig = () => {
  const { getSearchParam } = useUniversalRouting();

  useEffect(() => {
    try {
      const externalConfigStr = getSearchParam<string | undefined>(queryParamsKeys.EXTERNAL_CONFIG);
      if (externalConfigStr) {
        const externalConfig = decodeBase64JsonString<IExternalConfig>(externalConfigStr);

        getSecureSessionStorage().setItem(
          sessionStorageKeys.EXTERNAL_CONFIG,
          JSON.stringify(externalConfig),
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [getSearchParam]);
};
