import { sortNonReturnableOrderItemFunction } from './sorter.ts';

import {
  AmountSet,
  BundleReturnType,
  GrayFeatureKey,
  HiddenItem,
  IGrayFeatureEnabledMap,
  NonReturnableOrderItem,
  OrderItem,
  OrderItemType,
  OrderResponse,
  ReturnableItem,
  ReturnableOrderItem,
} from '../types';

/**
 * 根据灰名单和具体策略,决定 UI 展示的价格
 * 1. 如果在 ShowProductFullValuePrice 名单,使用含税价
 * 2. 如果在 HiddenSummaryAndPriceAlways 名单,隐藏所有价格
 * 3. 如果在 HiddenBundleProductItemPrice 名单,只隐藏 line 的价格
 * @param item 待处理价格的 item
 * @param grayFeatureEnabledMap 灰度名单
 */
function getPriceByGrayStrategy(
  item: {
    type: OrderItemType;
    discounted_base_price_set: AmountSet;
    unit_price_incl_tax_set: AmountSet;
    base_price_set: AmountSet;
  },
  grayFeatureEnabledMap?: IGrayFeatureEnabledMap,
) {
  // 是否展示含税价格 https://aftership.atlassian.net/browse/RTC-19933
  const isShowFullValuePrice =
    grayFeatureEnabledMap?.[GrayFeatureKey.ShowProductFullValuePrice] ?? false;

  // 是否隐藏 item 的价格
  const isHiddenAllPrice =
    grayFeatureEnabledMap?.[GrayFeatureKey.HiddenSummaryAndPriceAlways] ?? false;
  // 是否隐藏 line item 的价格
  const isHideLineItemPrice = true;

  const shouldHidePrice =
    isHiddenAllPrice || (isHideLineItemPrice && item.type === OrderItemType.bundledItem);
  let price;
  // 如果 isHiddenAllPrice ,不管是 parentItem 还是 LineItem 都需要隐藏价格
  if (!shouldHidePrice) {
    if (isShowFullValuePrice) {
      price = item.unit_price_incl_tax_set.presentment_money;
    } else {
      price = item.discounted_base_price_set.presentment_money;
    }
  }
  return price;
}

/**
 * 过滤出 bundle 中可退的 line item,并且 map 成 ReturnableOrderItem
 * @param orderItem
 * @param returnableItems
 * @param hiddenItems
 * @param grayFeatureEnabledMap
 */
function convertBundledItems2ReturnableOrderItems(
  orderItem: OrderItem,
  returnableItems: ReturnableItem[],
  hiddenItems: HiddenItem[],
  grayFeatureEnabledMap?: IGrayFeatureEnabledMap,
) {
  return (
    orderItem.bundled_items
      // 如果在 hidden item 里,不需要展示在可退列表
      .filter(
        (item) => !hiddenItems.find((hiddenItem) => hiddenItem.external_id === item.external_id),
      )
      .reduce((acc, cur) => {
        const lineItem = returnableItems.find(
          (returnableItem) => returnableItem.external_id === cur.external_id,
        );
        if (lineItem) {
          acc.push({
            ...cur,
            returnable: true,
            returnableQuantity: lineItem.quantity,
            overridingReasons: lineItem.overriding_ineligibilities,
            frontEndPrice: getPriceByGrayStrategy(cur, grayFeatureEnabledMap),
          });
        }
        return acc;
      }, [] as Array<ReturnableOrderItem>)
  );
}

/**
 * 判断 parent item 是否可退(是否展示 return bundle 按钮)
 * 如果是 whole return 或者 partial return 且不禁用 whole return,则 parent item 可退
 * 后续应该做成通用方案,交给后端处理.
 * @param bundleReturnType
 * @param grayFeatureEnabledMap
 */
function isParentItemReturnable(
  bundleReturnType: BundleReturnType,
  grayFeatureEnabledMap?: IGrayFeatureEnabledMap,
) {
  return (
    bundleReturnType === BundleReturnType.WHOLE ||
    (bundleReturnType === BundleReturnType.PARTIAL &&
      !(grayFeatureEnabledMap?.[GrayFeatureKey.DisabledWholeReturnWhenPartialReturn] ?? false))
  );
}

/**
 *
 * todo: 这里代码待优化,先不影响整个
 * 返回 request-return page 需要的 ui 数据
 * 可退列表 和 不可退列表
 */
export const getReturnableListAndNonReturnableList = (
  orderResponse?: OrderResponse,
  grayFeatureEnabledMap?: IGrayFeatureEnabledMap,
): {
  returnableOrderItems: Array<ReturnableOrderItem>;
  nonReturnableOrderItems: Array<NonReturnableOrderItem>;
} => {
  if (!orderResponse) {
    return {
      returnableOrderItems: [],
      nonReturnableOrderItems: [],
    };
  }
  const { order, returnable_items, non_returnable_items, hidden_items } = orderResponse;
  // order, returnable_items, non_returnable_items, 都需要 过滤掉 hidden item
  const realOrderItems = order.items.filter(
    (item) => !hidden_items.find((hiddenItem) => hiddenItem.external_id === item.external_id),
  );
  const realReturnableItems = returnable_items.filter(
    (item) => !hidden_items.find((hiddenItem) => hiddenItem.external_id === item.external_id),
  );
  const realNonReturnableItems = non_returnable_items.filter(
    (item) => !hidden_items.find((hiddenItem) => hiddenItem.external_id === item.external_id),
  );

  const returnableOrderItems: Array<ReturnableOrderItem> = realOrderItems.reduce((acc, cur) => {
    const { type, return_rule } = cur;
    const returnableItem = realReturnableItems.find(
      (returnableItem) => returnableItem.external_id === cur.external_id,
    );
    if (returnableItem) {
      if (type === OrderItemType.BUNDLE) {
        const returnableLineItems = convertBundledItems2ReturnableOrderItems(
          cur,
          realReturnableItems,
          hidden_items,
          grayFeatureEnabledMap,
        );

        const returnable = isParentItemReturnable(return_rule, grayFeatureEnabledMap);
        acc.push({
          ...cur,
          returnable: returnable,
          returnableQuantity: returnableItem.quantity,
          overridingReasons: returnableItem.overriding_ineligibilities,
          frontEndPrice: getPriceByGrayStrategy(cur, grayFeatureEnabledMap),
          bundledItems: returnableLineItems,
        });
      } else {
        acc.push({
          ...cur,
          returnable: true,
          returnableQuantity: returnableItem.quantity,
          overridingReasons: returnableItem.overriding_ineligibilities,
          frontEndPrice: getPriceByGrayStrategy(cur, grayFeatureEnabledMap),
          bundledItems: [],
        });
      }
    }

    if (
      !returnableItem &&
      type === OrderItemType.BUNDLE &&
      return_rule === BundleReturnType.PARTIAL
    ) {
      // 如果是 partial 类型的 bundle, 还要看 line item 是否存在可退的
      const returnableLineItems = convertBundledItems2ReturnableOrderItems(
        cur,
        realReturnableItems,
        hidden_items,
        grayFeatureEnabledMap,
      );
      // 如果存在可退的 line item,那么这个 bundle 还是应该出现在 returnableList 中,只不过不允许 whole return
      if (returnableLineItems.length > 0) {
        acc.push({
          ...cur,
          returnable: false,
          returnableQuantity: 0,
          overridingReasons: [],
          bundledItems: returnableLineItems,
          frontEndPrice: getPriceByGrayStrategy(cur, grayFeatureEnabledMap),
        });
      }
    }

    return acc;
  }, [] as Array<ReturnableOrderItem>);

  // 如果 bundle 不可退, lineItem 不需要展示在不可退列表,只需要把 bundle 展示在 不可退列表
  // 如果 bundle 可退, 需要继续看 lineItem 是否要展示在不可退列表
  const nonReturnableOrderItems = realOrderItems
    .reduce((acc, cur) => {
      const nonReturnableItem = realNonReturnableItems.find(
        (nonReturnableItem) => nonReturnableItem.external_id === cur.external_id,
      );
      const { type, return_rule } = cur;
      if (type === OrderItemType.BUNDLE) {
        if (return_rule === BundleReturnType.WHOLE && nonReturnableItem) {
          acc.push({
            ...cur,
            nonReturnableQuantity: nonReturnableItem.quantity,
            reasons: nonReturnableItem.ineligibilities,
          });
        } else if (return_rule === BundleReturnType.PARTIAL) {
          // bundled_items 可能存在 hidden item,需要过滤掉
          const realBundledItems = cur.bundled_items.filter(
            (item) =>
              !hidden_items.find((hiddenItem) => hiddenItem.external_id === item.external_id),
          );
          // 先找到所有不可退的 line item
          const nonReturnableLineItems = realBundledItems.reduce((acc, cur) => {
            const nonReturnableLineItem = realNonReturnableItems.find(
              (nonReturnableItem) => nonReturnableItem.external_id === cur.external_id,
            );
            if (nonReturnableLineItem) {
              acc.push({
                ...cur,
                nonReturnableQuantity: nonReturnableLineItem.quantity,
                reasons: nonReturnableLineItem.ineligibilities,
                frontEndPrice: getPriceByGrayStrategy(cur, grayFeatureEnabledMap),
              });
            }
            return acc;
          }, [] as Array<NonReturnableOrderItem>);
          //  如果 parent 不可退,并且所有的 line item 不可退,则只需要把 parent item 加入到不可退列表
          if (
            nonReturnableItem &&
            nonReturnableLineItems.length === realBundledItems.length &&
            !nonReturnableLineItems.some((nonReturnableItem) =>
              realNonReturnableItems.find(
                (returnableItem) => returnableItem.external_id === nonReturnableItem.external_id,
              ),
            )
          ) {
            acc.push({
              ...cur,
              nonReturnableQuantity: nonReturnableItem.quantity,
              reasons: nonReturnableItem.ineligibilities,
              frontEndPrice: getPriceByGrayStrategy(cur, grayFeatureEnabledMap),
            });
          } else if (nonReturnableLineItems.length) {
            // 否则  只需要将 line item 加入到不可退
            acc.push(
              ...nonReturnableLineItems.map((innerItem) => ({
                ...innerItem,
                frontEndPrice: getPriceByGrayStrategy(innerItem, grayFeatureEnabledMap),
              })),
            );
          }
        }
      } else if (nonReturnableItem) {
        acc.push({
          ...cur,
          nonReturnableQuantity: nonReturnableItem.quantity,
          reasons: nonReturnableItem.ineligibilities,
          frontEndPrice: getPriceByGrayStrategy(cur, grayFeatureEnabledMap),
        });
      }
      return acc;
    }, [] as Array<NonReturnableOrderItem>)
    .sort(sortNonReturnableOrderItemFunction);
  return {
    returnableOrderItems,
    nonReturnableOrderItems,
  };
};
