import { t } from 'i18next';

import { Ineligibility, NonReturnableReason } from '@aftership/returns-logics-core';

import { AskQuestionWorkflow, genAskQuestionWorkflowCode } from '@/i18n/dynamic.ts';

export const notAllowToReturnReasonMap: Record<NonReturnableReason, string> = {
  [NonReturnableReason.ReturnWindowByOrderDate]: 'page.details.outOfReturnWindow',
  [NonReturnableReason.ItemCategories]: 'page.description.nonReturnableReasonPolicy',
  [NonReturnableReason.ItemTags]: 'page.description.nonReturnableReasonPolicy',
  [NonReturnableReason.ItemSKU]: 'page.description.nonReturnableReasonPolicy',
  [NonReturnableReason.DiscountItems]: 'page.description.nonReturnableReasonPolicy',
  [NonReturnableReason.MultipleReturns]: 'page.description.nonReturnableReasonAlreadyARequested',
  [NonReturnableReason.ReturnWindowByFulfillDate]: 'page.details.outOfReturnWindow',
  [NonReturnableReason.ReturnWindowByDeliveryDate]: 'page.details.outOfReturnWindow',
  [NonReturnableReason.ReturnWindowByCondition]: 'page.details.outOfReturnWindow',
  [NonReturnableReason.BaseReturnWindow]: 'page.details.outOfReturnWindow',
  [NonReturnableReason.OrderItemId]: 'page.description.nonReturnableReasonQuestion',
  [NonReturnableReason.UnfulfilledItem]: 'page.description.nonReturnableUnfulfilled',
  [NonReturnableReason.UnfulfilledOrder]: 'page.description.nonReturnableUnfulfilled',
  [NonReturnableReason.BlockByItemDiscountPercentage]: 'page.description.nonReturnableReasonPolicy',
  [NonReturnableReason.DiscountCode]: 'page.description.nonReturnableReasonPolicy',
  [NonReturnableReason.DiscountValue]: 'page.description.nonReturnableReasonPolicy',
  [NonReturnableReason.DiscountPercentage]: 'page.description.nonReturnableReasonPolicy',
  [NonReturnableReason.ReturnsCenterExchangeOrder]: '', // only on order level
  [NonReturnableReason.ReturnableQuantity]: 'page.description.nonReturnableReasonAlreadyRequested', // returned all quantity
};

export const getItemNonRefundableDescription = (reasons: Ineligibility[]) => {
  if (!reasons.length) {
    return t('page.description.nonReturnableReasonPolicy');
  }
  const priorityReason = reasons[0];

  if (priorityReason.reason === 'order_item_id') {
    const context = priorityReason.context?.context || {};
    const { item_selected_question_id, terminate_flow_message } = context;

    const defaultValue =
      terminate_flow_message || t('page.description.nonReturnableReasonQuestion');

    if (item_selected_question_id) {
      return t(
        genAskQuestionWorkflowCode({
          flowId: item_selected_question_id,
          type: AskQuestionWorkflow.TerminateFlowMessage,
        }),
        {
          defaultValue,
          rawValue: defaultValue,
        },
      );
    }
  }

  // show the first reason when more than one reasons exist
  if (notAllowToReturnReasonMap[priorityReason.reason]) {
    return t(notAllowToReturnReasonMap[priorityReason.reason]);
  }
  return t('page.description.nonReturnableReasonPolicy');
};

export const getMerchantModeOverrideReason = (reasons: Ineligibility[]) => {
  if (!reasons.length) {
    return;
  }
  const priorityReason = reasons[0];
  if (notAllowToReturnReasonMap[priorityReason.reason]) {
    return t(notAllowToReturnReasonMap[priorityReason.reason]);
  }

  return t('page.description.nonReturnableReasonPolicy');
};
