import { useMemo } from 'react';

import { Font, FontType } from '@aftership/returns-logics-core';

import { useCustomFont } from './useCustomFont';

export const useFonts = (fontNames: string[], fonts: Font[]) => {
  const { defaultFontFamilies, customFontFamilies } = useMemo(() => {
    const fontFamilies = fonts.filter((font) => fontNames.includes(font.name));
    return {
      defaultFontFamilies: fontFamilies.filter((font) => font.type === FontType.Default),
      customFontFamilies: fontFamilies.filter((font) => font.type === FontType.Custom),
    };
  }, [fonts, fontNames]);
  const fontStyle = useCustomFont(customFontFamilies);

  return useMemo(
    () => (
      <>
        <style dangerouslySetInnerHTML={{ __html: fontStyle }} />
        {/* fix it: Do not add stylesheets using next/head */}
        {/* https://nextjs.org/docs/messages/no-stylesheets-in-head-component */}
        {defaultFontFamilies.map((font) => (
          <link key={font.name} rel='stylesheet' href={font.url} />
        ))}
      </>
    ),
    [defaultFontFamilies, fontStyle],
  );
};
