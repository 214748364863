import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Icon, LeftChevronThin, Pressable } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import {
  EventName,
  PreviewMessageData,
  UpdatePreviewDataType,
} from '@aftership/preview-kit/business/rc';
import { Resolution } from '@aftership/returns-logics-core';
import { useFlow } from 'returns-logics/react';

import { ScrollBox } from '@/components/ScrollBox';
import { StepCard } from '@/components/StepCard';
import { RequestReviewCardTitleText } from '@/features/preview/components/WithPreviewSection';
import { PreviewSectionName } from '@/features/preview/components/WithPreviewSection/PreviewSectionName';
import useDevice from '@/hooks/useDevice';
import { useStepCardMinHeight } from '@/hooks/useStepCardMinHeight';
import { PageId } from '@/utils/tracker/consts';
import { useReportPageViewEvent } from '@/utils/tracker/useReportPageViewEvent';

import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import LabelRateUnavailableBanner from './components/Banners/LabelRateUnavailableBanner';
import OverrideRuleBanner from './components/Banners/OverrideRuleBanner';
import { ExchangeAndEditItemsRef } from './components/ExchangeAndEditItems/ExchangeAndEditItems';
import { useReturnPreviewFlow } from './hooks/useReturnPreviewFlow';
import { titleStyles } from './style/title.css';

import { useScrollUpdate } from '../atta/useSrollUpdate';
import { withPreviewSection } from '../preview/components/WithPreviewSection/withPreviewSection';
import StickyHeader from '../return-detail/components/StickyHeader';
import { useHideSummary } from '../return-detail/components/Summary/hooks';
import { SharePropsProvider } from '../return-detail/context/SharePropsContext';
import useCartStyle from '../return-detail/hooks/useCartStyle';
import { useMessageBridge } from '../returns/hooks/useMessageBridge';
import { xStateMetaData } from '../returns/hooks/useSyncXStateAndRoute';
import { useUniversalRouting } from '../returns/hooks/useUniversalRouting';

export const RightColumnWithPreviewSection = withPreviewSection(
  RightColumn,
  PreviewSectionName.REVIEW_SUMMARY_SECTION,
);

const Review = () => {
  const { goBack } = useUniversalRouting();
  const { t } = useTranslation();
  const { mobile } = useDevice();
  const { mobileBackgroundColor, isSpecialDesktopForReview, desktopLayoutGap } = useCartStyle();
  const minHeight = useStepCardMinHeight();
  const containerRef = useRef<HTMLDivElement>(null);
  const exchangeAndEditItemsRef = useRef<ExchangeAndEditItemsRef>();
  const { returnPreviewFlowDispatch, returnPreviewFlowContext, mainDispatch, resolution } =
    useReturnPreviewFlow();

  const { currentStep } = useFlow();

  const { hideSummary } = useHideSummary({
    resolution,
  });

  useEffect(() => {
    xStateMetaData.delete('review');
  }, []);

  useReportPageViewEvent(PageId.review);

  //  允许用户返回到上一步
  const allowBack = currentStep && currentStep.name === 'review' && !currentStep?.isLoading;
  const onBack = () => {
    if (!allowBack) return;
    if (
      returnPreviewFlowContext?.skipReturnMethodAfterEFA ||
      returnPreviewFlowContext?.resolution === Resolution.ExchangeForAnything
    ) {
      mainDispatch({ type: 'GO_TO_RESOLUTION' });
    } else {
      goBack();
    }
  };

  const messageBridge = useMessageBridge();

  useEffect(() => {
    const removeEventListener = messageBridge.onMessage({
      type: EventName.UpdatePreviewData,
      callback: (data: PreviewMessageData[EventName.UpdatePreviewData]) => {
        if (data.type === UpdatePreviewDataType.UpdateSelectedResolution) {
          // @ts-ignore
          if (data.payload?.resolution === Resolution.OriginalPayment) {
            returnPreviewFlowDispatch?.({
              type: 'PREVIEW_UPDATE_CONTEXT_FOR_ORIGINAL',
            });
            // @ts-ignore
          } else if (data.payload?.resolution === Resolution.ReplaceTheSameItem) {
            returnPreviewFlowDispatch?.({
              type: 'PREVIEW_UPDATE_CONTEXT_FOR_REPLACE',
            });
          }
        }
      },
    });
    return () => {
      removeEventListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnPreviewFlowDispatch]);

  const scrollClassName = useScrollUpdate();

  if (mobile) {
    return (
      <SharePropsProvider hideSummary={hideSummary}>
        <Box backgroundColor={mobileBackgroundColor} minHeight={'100vh'}>
          <StickyHeader
            scrollContainerRef={containerRef}
            backgroundColor={'white'}
            height={68}
            leftSlot={
              <Pressable onPress={onBack}>
                <Flex alignItems='center' justifyContent='center' width={SpacingVars['9']}>
                  <Icon
                    source={LeftChevronThin}
                    color={ColorVars['Grey']['Grey1200']}
                    size={SpacingVars['6']}
                  />
                </Flex>
              </Pressable>
            }
            title={null}
          />
          <RequestReviewCardTitleText
            className={titleStyles}
            elementType='p'
            variant='title2'
            textAlign='center'
            color={ColorVars['Grey']['Grey1200']}
          >
            {t('page.request.reviewYourReturns')}
          </RequestReviewCardTitleText>

          <Flex direction='column' gap={SpacingVars['8']}>
            <OverrideRuleBanner />
            <LabelRateUnavailableBanner />
            <LeftColumn exchangeAndEditItemsRef={exchangeAndEditItemsRef} />
            <RightColumn />
          </Flex>
        </Box>
      </SharePropsProvider>
    );
  }

  return (
    <StepCard
      title={
        <RequestReviewCardTitleText
          elementType='p'
          variant='title2'
          textAlign='center'
          color={ColorVars['Grey']['Grey1200']}
        >
          {t('page.request.reviewYourReturns')}
        </RequestReviewCardTitleText>
      }
      onBack={onBack}
      width={isSpecialDesktopForReview || hideSummary ? '100%' : '1080px'}
      borderRadius={isSpecialDesktopForReview ? '0px' : void 0}
      minHeight={isSpecialDesktopForReview ? minHeight : void 0}
    >
      {isSpecialDesktopForReview ? (
        <ScrollBox>
          <Box
            paddingTop={SpacingVars['3']}
            paddingX={0}
            paddingBottom={SpacingVars['10']}
            overflow='auto'
            className={scrollClassName}
          >
            <SharePropsProvider hideSummary={hideSummary}>
              <Flex direction='column' width={'100%'} padding={SpacingVars['8']}>
                <OverrideRuleBanner />
                <LabelRateUnavailableBanner />
                <Flex
                  direction={hideSummary ? 'column' : 'row'}
                  alignItems={hideSummary ? 'center' : 'start'}
                  gap={hideSummary ? SpacingVars['6'] : 0}
                >
                  <Box flex={!hideSummary && '0 0 60%'} paddingEnd={SpacingVars['3']}>
                    <LeftColumn exchangeAndEditItemsRef={exchangeAndEditItemsRef} />
                  </Box>
                  <Box
                    flex={!hideSummary && '0 0 40%'}
                    paddingStart={hideSummary ? 0 : SpacingVars['3']}
                  >
                    <RightColumnWithPreviewSection />
                  </Box>
                </Flex>
              </Flex>
            </SharePropsProvider>
          </Box>
        </ScrollBox>
      ) : (
        <Box
          paddingTop={SpacingVars['3']}
          paddingX={hideSummary ? SpacingVars['10'] : SpacingVars['12']}
          paddingBottom={SpacingVars['10']}
        >
          <SharePropsProvider hideSummary={hideSummary}>
            <Flex direction='column' width={'100%'} gap={SpacingVars['6']}>
              <OverrideRuleBanner />
              <LabelRateUnavailableBanner />
              <Flex direction={hideSummary ? 'column' : 'row'} gap={desktopLayoutGap}>
                <LeftColumn exchangeAndEditItemsRef={exchangeAndEditItemsRef} />
                <RightColumnWithPreviewSection />
              </Flex>
            </Flex>
          </SharePropsProvider>
        </Box>
      )}
    </StepCard>
  );
};

export default Review;
