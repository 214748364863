import React from 'react';

import { Box, CardProps, CardSection, Flex, TextProps } from '@aftership/design-system';
import { IconSource } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { PresentmentMoney } from '@aftership/returns-logics-core';

import ItemCardContent from '@/components/ItemCardContent/ItemCardContent';

export interface ProductInfo {
  /**
   * 商品标题
   */
  productTitle: string;
  /**
   * 商品variant标题
   */
  variantTitle?: string;
  /**
   * 商品价格信息
   */
  price?: PresentmentMoney | null;
  /**
   * 商品的原始价格
   */
  originPrice?: PresentmentMoney | null;
  /**
   * 商品标签列表
   */
  productTags?: string[];
  /**
   * 商品数量
   */
  quantity?: number;

  productCoverUrl?: string;
}

export interface ItemCardProps extends Omit<CardProps, 'children'> {
  titleClassName?: string;
  title?: React.ReactNode | string;
  image?: string | IconSource;
  footer?: React.ReactNode;
  productInfo: ProductInfo;
  returnReason?: string;
  returnSubReason?: string;
  rightContent?: React.ReactNode;
  showMoreReason?: boolean;
  showMoreReasonClick?: () => void;
  hideDivider?: boolean;
  productTitleVariant?: TextProps['variant'];
  productTitleContainerStyles?: string;
}

const ItemCard: React.FC<ItemCardProps> = ({
  footer,
  productInfo,
  returnReason,
  returnSubReason,
  rightContent,
  showMoreReason,
  hideDivider = false,
  productTitleVariant,
  productTitleContainerStyles,
  showMoreReasonClick,
}) => {
  return hideDivider ? (
    <Box>
      <Flex direction={'column'} gap={SpacingVars['6']}>
        {/* Content */}
        <ItemCardContent
          productInfo={productInfo}
          rightContent={rightContent}
          showMoreReasonClick={showMoreReasonClick}
          showMoreReason={showMoreReason}
          returnReason={returnReason}
          productTitleContainerStyles={productTitleContainerStyles}
          returnSubReason={returnSubReason}
        />
        {/* Footer */}
        {footer}
      </Flex>
    </Box>
  ) : (
    <CardSection>
      <Box>
        <Flex direction={'column'} gap={SpacingVars['6']}>
          {/* Content */}
          <ItemCardContent
            productTitleVariant={productTitleVariant}
            productInfo={productInfo}
            rightContent={rightContent}
            showMoreReasonClick={showMoreReasonClick}
            showMoreReason={showMoreReason}
            returnReason={returnReason}
            productTitleContainerStyles={productTitleContainerStyles}
            returnSubReason={returnSubReason}
          />
          {/* Footer */}
          {footer}
        </Flex>
      </Box>
    </CardSection>
  );
};

export default ItemCard;
