import { fromPromise } from 'xstate';

import { v4hostName } from '../constant';
import { request } from '../request/request';
import { ResponseBody } from '../request/response';
import {
  CourierList,
  GenerateLabelStatus,
  IShipmentAllocation,
  ShipmentsInfo,
  ShippingCourierList,
} from '../types';
import { makePollingRequest } from '../utils/makePollingRequest';

export const getCourierList = fromPromise(async ({ input }: { input: { token: string } }) => {
  const { token } = input;
  const res = await request<ResponseBody<CourierList>>(`${v4hostName()}/tracking-couriers`, {
    method: 'GET',
    headers: { 'returns-authorization': token },
  });

  return res?.data?.couriers || [];
});

export const getDisplayQrCodeSlugInfo = fromPromise(
  async ({ input }: { input: { token: string } }) => {
    const { token } = input;
    const res = await request<ResponseBody<ShippingCourierList>>(
      `${v4hostName()}/shipping-couriers`,
      {
        method: 'GET',
        headers: { 'returns-authorization': token },
      },
    );

    return res?.data?.couriers || [];
  },
);

export interface GetShipmentByIdProps {
  returnId: string;
  token: string;
}

export interface GetShipmentByIdResponse {
  shipments: ShipmentsInfo[];
  shipment_allocations: IShipmentAllocation[];
}

// 获取 shipments 信息
export const getShipmentsById = fromPromise(async ({ input }: { input: GetShipmentByIdProps }) => {
  const { returnId, token } = input;
  const res = await request<
    ResponseBody<{ shipments: ShipmentsInfo[]; shipment_allocations: IShipmentAllocation[] }>
  >(`${v4hostName()}/returns/${returnId}/shipments`, {
    method: 'GET',
    headers: { 'returns-authorization': token },
  });

  return {
    shipments: res?.data?.shipments ?? [],
    shipment_allocations: res?.data?.shipment_allocations ?? [],
  };
});

export interface PostShipmentPayload {
  returnId: string;
  trackingNumber: string;
  courierSlug: string;
}

// post shipments 信息
export const postShipmentsById = fromPromise(
  async ({ input }: { input: { token: string } & PostShipmentPayload }) => {
    const { returnId, token, ...payload } = input;
    const res = await request<ResponseBody<{}>>(`${v4hostName()}/returns/${returnId}/shipments`, {
      method: 'POST',
      payload: {
        tracking_number: payload.trackingNumber,
        courier_slug: payload.courierSlug,
      },
      headers: { 'returns-authorization': token },
    });

    return res?.data;
  },
);

export const pollingShipment = makePollingRequest<
  GetShipmentByIdResponse,
  GetShipmentByIdProps
>().provide({
  actors: {
    retrieveResource: getShipmentsById,
  },
  guards: {
    resourceIsVerified: ({ context }) => {
      const shipments = context.response?.shipments ?? [];
      const additionalLabels = shipments.reduce((acc, shipment) => {
        return [...acc, ...shipment.additional_labels];
      }, [] as ShipmentsInfo[]);
      // 停止轮询的条件:
      // 1. 预期生成 shipments 数量与实际生成 shipments 数量相等(包括成功和失败)
      // 2. 如果存在 additional_labels，那么还要求所有的additional label 都生成了，或者有失败的 label
      // fixme 2 这里其实也有问题,只不过暂时跟线上逻辑保持一致,等后端继续优化,比如: 如果期望 3 个 additional label, 存在[created,created] or [failed] 的情况, 都会停止轮询)
      const isShipmentsGenerated =
        shipments.filter(
          (shipment) =>
            shipment.auto_label_generation_status === GenerateLabelStatus.Created ||
            shipment.auto_label_generation_status === GenerateLabelStatus.Failed,
        ).length === context.response.shipment_allocations.length;

      const isExistAdditionalLabels = additionalLabels.length > 0;
      const isAllAdditionalLabelsGenerated = additionalLabels.every(
        (label) => label.auto_label_generation_status === GenerateLabelStatus.Created,
      );
      const isAnyAdditionalLabelsFailed = additionalLabels.some(
        (label) => label.auto_label_generation_status === GenerateLabelStatus.Failed,
      );

      return (
        isShipmentsGenerated &&
        (!isExistAdditionalLabels || isAllAdditionalLabelsGenerated || isAnyAdditionalLabelsFailed)
      );
    },
  },
});
