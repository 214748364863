import { isNull, isUndefined } from 'lodash-es';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AmountPrice, Resolution, ShippingFeeOption } from '@aftership/returns-logics-core';

import { toCurrency } from '@/utils/price';

import { useGrayHideMode } from '../../hooks/useGrayHideMode';

interface IExchangeShippingProps {
  shippingFee?: AmountPrice | null;
  shippingFeeOption?: ShippingFeeOption | null;
}

export const useGetExchangeShippingValue = ({
  shippingFee,
  shippingFeeOption,
}: IExchangeShippingProps) => {
  const { t } = useTranslation();

  const shippingFeeValue = useMemo(() => {
    if (shippingFeeOption === ShippingFeeOption.CUSTOM) {
      return shippingFee ? toCurrency(shippingFee.amount, shippingFee.currency) : null;
    } else if (shippingFeeOption === ShippingFeeOption.FREE) {
      return t('page.description.free');
    }
    return null;
  }, [shippingFee, shippingFeeOption, t]);

  return shippingFeeValue;
};

export const useHideSummary = ({ resolution }: { resolution?: Resolution }) => {
  const { hideSummaryAlways, hideSummaryOnlyReplace } = useGrayHideMode({
    resolution,
  });
  // 如果是ReturnMethod 是 replace the same item, 并且属于灰度名单,需要隐藏价格还有 Summary
  const hideSummary = hideSummaryAlways || hideSummaryOnlyReplace;

  return {
    hideSummary,
  };
};

interface IHideTotalSectionProps {
  resolution?: Resolution;
  isLegacyReplace?: boolean;
  totalValue?: string | null;
}
export const useHideTotalSection = ({
  resolution,
  isLegacyReplace,
  totalValue,
}: IHideTotalSectionProps) => {
  const { hiddenSumaryRefundTotal } = useGrayHideMode({ resolution }); // 是否要在 refund 的时候隐藏 total 的灰度名单里

  const isExchange =
    resolution === Resolution.ReplaceTheSameItem || resolution === Resolution.ExchangeForAnything;

  const hideRefund = !isExchange && hiddenSumaryRefundTotal;

  /**
   * 隐藏 total section 的逻辑
   * 1. 如果是 legacy replace, 隐藏 total section【因为 legacy replace 不在 RC 这边计算价格，因此不展示 total section】
   * 2. 如果是 refund resolution, 且在灰度名单里, 隐藏 total section
   * 3. 如果 total value 为 null 或者 undefined, 隐藏 total section
   */
  const hideTotalSection =
    hideRefund || isLegacyReplace || isNull(totalValue) || isUndefined(totalValue);

  return hideTotalSection;
};
