import dayjs from 'dayjs';

import { compileSync } from '@aftership/atta-engine/renderer';

import { loader } from './loader';
import { getSchema } from './schema';
import { AttaProps, PathName } from './type';

type SharedConfigs = {
  whitelist: 'after_created_at' | 'all' | 'none';
  after_created_at: string;
};

export const checkAttaEditorEnable = (createdAt: string, sharedConfigs: SharedConfigs): boolean => {
  const { whitelist, after_created_at } = sharedConfigs;

  if (whitelist === 'all') {
    return true;
  } else if (whitelist === 'none') {
    return false;
  } else if (whitelist === 'after_created_at') {
    return dayjs(createdAt).isAfter(dayjs(after_created_at));
  }

  return false;
};

type DataMap = Record<PathName, string>;

export const getServerSideAttaProps = async (isPreview: boolean): Promise<AttaProps | null> => {
  const schemaList = getSchema();
  const schema: DataMap = {} as DataMap;
  const code: DataMap = {} as DataMap;
  const deps: DataMap = {} as DataMap;

  schemaList.forEach((i) => {
    if (isPreview) {
      schema[i.name] = i.schema;
    } else {
      const { code: c, deps: d } = compileSync(i.schema, loader);
      code[i.name] = c;
      deps[i.name] = d;
    }
  });

  return isPreview
    ? {
        preview: true,
        schema,
      }
    : {
        preview: false,
        code,
        deps,
      };
};
