import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import { Flex, Link, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { DropoffLocation, LngLat, ReturnMethodSlug } from '@aftership/returns-logics-core';

import { Divider } from '@/components/Divider';

import { HAPPY_RETURN_PRIVACY_POLICY_LINK } from '../../utils/constants';
import DefaultMethod from '../DefaultMethod';
import DropoffLocations from '../DropoffLocations';
import ViewHappyReturnLocations from '../ViewHappyReturnLocations';
import ViewRetailReworkLocations from '../ViewRetailReworkLocations';

const disableTextMapping = (t: TFunction) => ({
  [ReturnMethodSlug.HappyReturns]: t('v2.return_method.happy_return.unavailable'),
  [ReturnMethodSlug.RetailRework]: t('v2.return_method.retail_rework.unavailable'),
});
const dropoffRecommendsTitleMapping = (t: TFunction) => ({
  [ReturnMethodSlug.HappyReturns]: t('page.happyReturn.recommends.title'),
  [ReturnMethodSlug.RetailRework]: t('recommendation.locations'),
});

interface DropoffMethodProps {
  isSelected?: boolean;
  slug: ReturnMethodSlug.HappyReturns | ReturnMethodSlug.RetailRework;
  title?: string;
  description?: string;
  disabled?: boolean;
  location?: DropoffLocation;
  nearbyLocations?: DropoffLocation;
  isNearbyLoading?: boolean;
  onNearByLocations?: (latitude: LngLat['latitude'], longitude: LngLat['longitude']) => void;
}

const DropoffMethod = ({
  isSelected,
  slug,
  title = '',
  description,
  disabled = false,
  location = { link: '', locations: [] },
  nearbyLocations,
  isNearbyLoading = false,
  onNearByLocations,
}: DropoffMethodProps) => {
  const { t } = useTranslation();

  return (
    <DefaultMethod
      slug={slug}
      title={title}
      disabled={disabled}
      disableText={disableTextMapping(t)[slug]}
      description={
        <Flex direction='column' gap={SpacingVars['1']} paddingTop={SpacingVars['1']}>
          <Text variant='caption1' color={ColorVars['Grey']['Grey 1100']}>
            {description}
          </Text>
          {slug === ReturnMethodSlug.HappyReturns && isSelected && (
            <Trans i18nKey='v2.return_methods.happy_return.policy'>
              <Text variant='caption1' color={ColorVars['Grey']['Grey 1100']}>
                {`By selecting, you agree to Happy Returns’ `}
                <Link variant='body2' target='_blank' href={HAPPY_RETURN_PRIVACY_POLICY_LINK}>
                  <Text
                    variant='caption1'
                    color={ColorVars['Grey']['Grey 1100']}
                    textDecoration='underline'
                  >
                    privacy policy.
                  </Text>
                </Link>
              </Text>
            </Trans>
          )}
        </Flex>
      }
    >
      {isSelected && (
        <>
          <Divider spacing={SpacingVars['2']} />
          <DropoffLocations
            title={dropoffRecommendsTitleMapping(t)[slug]}
            locations={location?.locations}
          >
            {slug === ReturnMethodSlug.HappyReturns && (
              <ViewHappyReturnLocations
                isLoading={isNearbyLoading}
                locations={nearbyLocations?.locations || []}
                allLocationsLink={nearbyLocations?.link || location.link || ''}
                onNearByLocations={onNearByLocations}
              />
            )}
            {slug === ReturnMethodSlug.RetailRework && location.locations.length > 3 && (
              <ViewRetailReworkLocations locations={location.locations} />
            )}
          </DropoffLocations>
        </>
      )}
    </DefaultMethod>
  );
};

export default DropoffMethod;
