import { Resolution } from '../../constant';
import {
  FiledByType,
  IDraftReturn,
  InputFieldErrorType,
  OrderResponse,
  RequestErrorType,
  ReturnItemType,
} from '../../types';
import { FormFieldData } from '../../types/common/formFieldData';
import { IAddress, IReturnIntentions } from '../../types/intention';

export enum OrderLookupType {
  EMAIL = 'EMAIL',
  ZIPCODE = 'ZIPCODE',
  PHONE = 'PHONE',
}
/**
 * 类型有问题，merchant mode 状态下应该可以不传 orderLookupType、email、zipCode、phoneNumber 等信息
 */
export interface SubmitPayload {
  orderNumber: string;
  filedBy?: FiledByType;
  intentionId?: string;
  merchantModeToken?: string;
  isMerchantMode?: boolean;
  resolutionWhiteList?: Resolution[];
  rmaId?: string;
  traceId?: string;
  orderLookupType: OrderLookupType;
  email?: string;
  zipCode?: string;
  googleToken?: string;
  phoneNumber?: string;
}

export type USerEvent = {
  type: 'SUBMIT';
  data: SubmitPayload;
};

export type PreviewEvent = {
  type: 'SHOW_ORDER_ERROR_MESSAGE';
};

export type Event = USerEvent | PreviewEvent;

export interface Input {
  shopId: string;
}

export type OrderLookupInputError = Partial<
  Record<FormFieldData<SubmitPayload>['name'], InputFieldErrorType>
>;

export type Emitted =
  | {
      type: 'error';
      data: {
        error: RequestErrorType;
      };
    }
  | {
      type: 'success';
      data: {
        order?: OrderResponse;
      };
    }
  | {
      type: 'inputError';
      data: {
        error: OrderLookupInputError;
      };
    };

export interface OrderLookupContext {
  errors?: {
    fields?: OrderLookupInputError;
    request?: RequestErrorType[];
  };

  input?: Input;

  orderId?: string;
  token?: string;

  request?: {
    returns?: ReturnItemType[];
    order?: OrderResponse;
    intentionData?: IReturnIntentions;
    draftReturns?: IDraftReturn[];
  };

  submitFeilds?: SubmitPayload;
}

export interface OrderLookupOutput {
  orderNumber?: string;
  email?: string;
  zipCode?: string;
  token: string;
  returns?: ReturnItemType[];
  orders?: OrderResponse;
  giftReturnRecipientData?: {
    recipientEmail: string;
    recipientFirstName: string;
    recipientLastName: string;
    recipientAddress?: IAddress;
  };
  filedBy?: FiledByType;
  isGiftReturnMode?: boolean;
  isMerchantMode?: boolean;
  draftReturns?: IDraftReturn[];
  resolutionWhiteList?: Resolution[];
  rmaId?: string;
}
