/**
 * https://aftership.atlassian.net/browse/RTC-20150
 * 与客户约定,如果 product tags 是以 'storefront_desc:' 开头的,则提取出来展示
 * @param productTags
 */
export const extractStorefrontTags = (productTags: Array<string> = []) => {
  const prefix = 'storefront_desc:';
  return productTags?.reduce<string[]>((previous, tag) => {
    if (!tag.startsWith(prefix)) return previous;
    return [...previous, tag.replace(prefix, '')];
  }, []);
};

export const decodeHtmlEntities = <T extends string | undefined>(title: T): T => {
  if (!title) return title;

  const entities: Record<string, string> = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#x27;': "'",
    '&#39;': "'",
    '&apos;': "'",
    '&nbsp;': ' ',
  };
  // 从 entities 对象的键创建一个安全的正则表达式
  // 这个正则表达式只匹配我们定义的特定实体字符串，使用 | (或) 连接
  // 这避免了使用 [^;]+ 带来的 ReDoS 风险，因为它不涉及对未知长度和内容的字符序列进行回溯匹配
  const entityKeys = Object.keys(entities);

  const entityRegex = new RegExp(entityKeys.join('|'), 'g');
  return title.replace(entityRegex, (entity) => {
    return entities[entity];
  }) as T;
};
