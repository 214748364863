import Image from 'next/image';

import { Flex, Icon, Text, Warning } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { ReturnMethodSlug } from '@aftership/returns-logics-core';

import { Divider } from '@/components/Divider';
import useDevice from '@/hooks/useDevice';

import { wrapperDisabledClassName } from './DefaultMethod.css';

interface DefaultMethodProps {
  slug: ReturnMethodSlug;
  title?: React.ReactNode;
  description?: React.ReactNode;
  disabled?: boolean;
  disableText?: React.ReactNode;
  children?: React.ReactNode;
}

const iconMap: Record<ReturnMethodSlug, string> = {
  [ReturnMethodSlug.CustomerCourier]: require('@/assets/customer-courier.png').default.src,
  [ReturnMethodSlug.InStore]: require('@/assets/in-store.png').default.src,
  [ReturnMethodSlug.RetailerLabel]: require('@/assets/retailer-label.png').default.src,
  [ReturnMethodSlug.GreenReturn]: require('@/assets/green-return.png').default.src,
  [ReturnMethodSlug.HappyReturns]: require('@/assets/happy-return.png').default.src,
  [ReturnMethodSlug.RetailRework]: require('@/assets/retail-rework.png').default.src,
  [ReturnMethodSlug.CarrierPickup]: require('@/assets/carrier-pickup.png').default.src,
  [ReturnMethodSlug.CarrierDropoff]: require('@/assets/carrier-dropoff.png').default.src,
};

const DefaultMethod = ({
  slug,
  title = '',
  description = '',
  disabled = false,
  disableText = '',
  children,
}: DefaultMethodProps) => {
  const isMobile = useDevice().mobile;

  return (
    <Flex direction='column'>
      <Flex
        gap={isMobile ? SpacingVars['2'] : SpacingVars['4']}
        className={disabled ? wrapperDisabledClassName : ''}
      >
        {!isMobile && (
          <Image width={52} height={52} src={iconMap[slug]} alt={slug} style={{ height: '52px' }} />
        )}
        <Flex flex={1} direction='column' gap={SpacingVars['4']}>
          <Flex direction='column' gap={SpacingVars['1']}>
            <Flex gap={SpacingVars['2']}>
              {isMobile && (
                <Image
                  width={32}
                  height={32}
                  src={iconMap[slug]}
                  style={{ alignSelf: 'center', height: '32px' }}
                  alt={slug}
                />
              )}
              {typeof title === 'string' ? (
                <Text
                  flex={1}
                  variant='subtitle1'
                  alignSelf='center'
                  color={ColorVars['Grey']['Grey1200']}
                  // 为后面的 selected icon 留出位置
                  paddingEnd={SpacingVars['11']}
                >
                  {title}
                </Text>
              ) : (
                title
              )}
            </Flex>

            {typeof description === 'string' ? (
              <Text variant='body2' color={ColorVars['Grey']['Grey 1100']}>
                {description}
              </Text>
            ) : (
              description
            )}
            {children}
          </Flex>
        </Flex>
      </Flex>
      {disabled && disableText && (
        <>
          <Divider spacing={SpacingVars['3']} />
          <Flex gap={SpacingVars['2']}>
            <Flex height={SpacingVars['5']} alignItems='center'>
              <Icon source={Warning} size={SpacingVars['4']} />
            </Flex>
            {typeof disableText === 'string' ? (
              <Text variant='body2' color={ColorVars['Grey']['Grey 1100']}>
                {disableText}
              </Text>
            ) : (
              disableText
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default DefaultMethod;
