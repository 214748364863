import { v4 as uuidv4 } from 'uuid';

import { getSecureSessionStorage } from '@aftership/returns-logics-core';

import { getExternalConfig } from '@/hooks/useGetExternalConfig';

import TrackerHelper from './trackerHelper';

let trackerInstance: TrackerHelper;

const traceIdStorageKey = 'trace-id';

export const generateUUId = () => {
  const sid = uuidv4();

  try {
    getSecureSessionStorage().setItem(traceIdStorageKey, JSON.stringify(sid));
  } catch (error) {
    console.error(error);
  }

  return sid;
};

const getStorageUUId = () => {
  try {
    const uuId = getSecureSessionStorage().getItem(traceIdStorageKey);
    return JSON.parse(uuId || '');
  } catch (e) {
    return '';
  }
};

export const getBaseSid = () => {
  const { merchantModeConfig } = getExternalConfig();
  return merchantModeConfig?.trace_id || getStorageUUId() || generateUUId();
};

function getTrackerInstance() {
  if (!trackerInstance) {
    trackerInstance = new TrackerHelper(getBaseSid());
  }
  return trackerInstance;
}

export default getTrackerInstance;
