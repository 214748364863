import { useEffect } from 'react';

import { RequestErrorType } from '@aftership/returns-logics-core';

import { useMemoGiftReturn } from './useMemoGiftReturn';

export const useHandleSubmitError = (onError: (error: RequestErrorType) => void) => {
  const giftReturnSubFlow = useMemoGiftReturn();
  useEffect(() => {
    if (!giftReturnSubFlow) return;
    const { unsubscribe } = giftReturnSubFlow.on('error', (error) => {
      onError(error.data.error);
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftReturnSubFlow]);
};
