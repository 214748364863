/* eslint-disable max-lines */
import Image from 'next/image';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Drawer,
  Dropdown,
  Flex,
  Global,
  Icon,
  IconCrossFill,
  Link,
  ListBox,
  ListBoxItem,
  Popup,
  Pressable,
  RightChevron,
  Text,
} from '@aftership/design-system';
import { ColorVars, RadiusVars, SpacingVars } from '@aftership/design-tokens';
import { LogoPosition, type ShopAsset, type Shops } from '@aftership/returns-logics-core';

import { FullScreenLoading } from '@/components/FullScreenLoading';
import { ScrollBox } from '@/components/ScrollBox';
import { useChangeLanguage } from '@/features/returns/hooks/useChangeLanguage';
import useDevice from '@/hooks/useDevice';
import { getNameForLanguage } from '@/i18n/utils';

import { headerBorderClassName, hiddenBox } from './ReturnsPageHeader.css';

import useShopUrls from '../hooks/useShopUrls';

const LOGO_HIGHT_MAX = 36;
const LOGO_WIDTH_MAX = 200;

interface ReturnsPageHeaderProps {
  shopInfo: Shops;
}

interface LogoProps {
  storeName: string;
  logoImage?: ShopAsset;
}

const Logo = ({ logoImage, storeName }: LogoProps) => {
  const { height = 0, width = 0, src } = logoImage || {};
  const imageWidth = Math.min(
    (height >= LOGO_HIGHT_MAX ? (width / height) * LOGO_HIGHT_MAX : width) || LOGO_WIDTH_MAX,
    LOGO_WIDTH_MAX,
  );
  const imageHeight = Math.min(height || LOGO_HIGHT_MAX, LOGO_HIGHT_MAX);

  return logoImage ? (
    <Image
      src={src ?? (logoImage?.name && URL.createObjectURL(logoImage as unknown as File))}
      style={{ width: imageWidth, height: imageHeight }}
      width={imageWidth}
      height={imageHeight}
      alt='store_logo'
    />
  ) : (
    <Text variant='title3' color={ColorVars['Grey']['Grey1200']}>
      {storeName}
    </Text>
  );
};

const ReturnsPageHeaderDesktop = ({ shopInfo }: ReturnsPageHeaderProps) => {
  const {
    logo_image,
    store_name,
    menu_items = [],
    organization,
    store_url,
    languages,
    logo_position_on_large_screen,
  } = shopInfo;

  const { i18n } = useTranslation();
  const { language: currentLanguage } = i18n;
  const { loading, changeLanguage } = useChangeLanguage(organization?.id);

  const logoDirection = useMemo(() => {
    switch (logo_position_on_large_screen) {
      case LogoPosition.MiddleLeft:
        return 'row';
      case LogoPosition.TopLeft:
      case LogoPosition.TopCenter:
        return 'column';
    }
  }, [logo_position_on_large_screen]);
  const logoJustifyContent = useMemo(() => {
    switch (logo_position_on_large_screen) {
      case LogoPosition.MiddleLeft:
      case LogoPosition.TopLeft:
        return 'start';
      case LogoPosition.TopCenter:
        return 'center';
    }
  }, [logo_position_on_large_screen]);

  return (
    <>
      <Box backgroundColor={ColorVars['B&W'].Background} padding={`0 ${SpacingVars['20']}`}>
        <Flex height={64} alignItems='center'>
          {logoJustifyContent === 'center' && (
            <>
              <Box className={hiddenBox}>
                <Dropdown
                  items={languages.map((language) => ({
                    id: language,
                    name: getNameForLanguage(language),
                  }))}
                  onAction={(language) => changeLanguage(String(language))}
                >
                  <Flex alignItems='center' gap={SpacingVars[2]} position='relative'>
                    <Icon
                      color={ColorVars['B&W'].Foreground}
                      source={Global}
                      size={SpacingVars[6]}
                    />
                    <Text variant='subtitle1'>{getNameForLanguage(currentLanguage)}</Text>
                  </Flex>
                </Dropdown>
              </Box>
              <Box flex={1} />
            </>
          )}

          <Flex
            alignItems={
              logoJustifyContent === 'start' && logoDirection === 'column' ? 'start' : 'center'
            }
            direction={logoDirection}
            gap={logoDirection === 'row' ? SpacingVars[8] : 0}
          >
            <Link href={store_url}>
              <Logo storeName={store_name} logoImage={logo_image} />
            </Link>
            <Flex alignItems='center' gap={SpacingVars[8]}>
              {menu_items.map((item) => (
                <Link key={item.url} href={item.url}>
                  <Text variant='subtitle2' color={ColorVars['Grey']['Grey 1100']}>
                    {item.name}
                  </Text>
                </Link>
              ))}
            </Flex>
          </Flex>
          <Box flex={1} />

          <Dropdown
            items={languages.map((language) => ({
              id: language,
              name: getNameForLanguage(language),
            }))}
            onAction={(language) => changeLanguage(String(language))}
          >
            <Flex alignItems='center' gap={SpacingVars[2]} position='relative'>
              <Icon color={ColorVars['B&W'].Foreground} source={Global} size={SpacingVars[6]} />
              <Text variant='subtitle1'>{getNameForLanguage(currentLanguage)}</Text>
            </Flex>
          </Dropdown>
        </Flex>
      </Box>
      <FullScreenLoading loading={loading} />
    </>
  );
};

const ReturnsPageHeaderMobile = ({ shopInfo }: ReturnsPageHeaderProps) => {
  const {
    logo_image,
    store_name,
    organization,
    languages,
    menu_items = [],
    theme_color,
  } = shopInfo;
  const { t } = useTranslation();
  const { loading, changeLanguage } = useChangeLanguage(organization?.id);
  const { socialLinks, footerLinks } = useShopUrls(shopInfo);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const noMenuLayout = menu_items.length === 0;
  const fallbackMenuItems = useMemo(() => {
    return [
      ...socialLinks.map(({ url, name }) => ({
        url,
        name,
      })),
      ...footerLinks.map(({ url, name }) => ({
        url,
        name,
      })),
    ];
  }, [socialLinks, footerLinks]);

  return (
    <>
      <Box
        backgroundColor={ColorVars['B&W'].Background}
        padding={`0 ${SpacingVars['4']}`}
        className={headerBorderClassName}
      >
        <Flex height={80} rowGap={4} alignItems='center' gap={SpacingVars[4]}>
          <Logo storeName={store_name} logoImage={logo_image} />
          <Box flex={1} />
          <Pressable onPress={() => setIsPopupOpen(true)}>
            <Image
              src={require('@/assets/i18n.png').default?.src}
              width={36}
              height={36}
              alt='i18n'
            />
          </Pressable>
          <Pressable onPress={() => setIsDrawerOpen(true)}>
            <Image
              src={require('@/assets/menu.png').default?.src}
              width={36}
              height={36}
              onClick={() => setIsDrawerOpen(true)}
              alt='menu'
            />
          </Pressable>
        </Flex>
        <Popup
          isOpen={isPopupOpen}
          // todo: 等组件库优化用法
          shouldCloseOnInteractOutside={() => {
            setIsPopupOpen(false);
            return true;
          }}
          title={t('page.request.language')}
          onClose={() => setIsPopupOpen(false)}
        >
          <ScrollBox padding={SpacingVars['4']}>
            <ListBox
              selectionMode='single'
              rowGap={SpacingVars['3']}
              items={languages.map((language, index) => ({
                id: index,
                name: language,
              }))}
            >
              {(item) => {
                return (
                  <ListBoxItem
                    padding={SpacingVars['4']}
                    onAction={() => {
                      changeLanguage(String(item.name));
                      setIsPopupOpen(false);
                    }}
                  >
                    <Flex alignItems='center' gap={SpacingVars['2']}>
                      <Text variant='body1'>{getNameForLanguage(item.name)}</Text>
                      <Box flex={1} />
                      <Icon source={RightChevron} size={SpacingVars['4']} />
                    </Flex>
                  </ListBoxItem>
                );
              }}
            </ListBox>
          </ScrollBox>
        </Popup>
        <Drawer
          width='100%'
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          header={<></>}
        >
          <Box
            height='100dvh'
            backgroundColor={theme_color}
            paddingX={SpacingVars['4']}
            overflow='auto'
          >
            <Flex height='100%' direction='column'>
              <Flex justifyContent='end' paddingY={SpacingVars[6]}>
                <Pressable onPress={() => setIsDrawerOpen(false)}>
                  <Icon source={IconCrossFill} size={SpacingVars['8']} />
                </Pressable>
              </Flex>
              <Flex justifyContent='center'>
                <Flex direction='column' gap={SpacingVars['4']} paddingBottom={SpacingVars['6']}>
                  {(noMenuLayout ? fallbackMenuItems : menu_items).map((item) => (
                    <Link key={item.url} href={item.url}>
                      <Text variant='title1' color={ColorVars['B&W']['Background']}>
                        {item.name}
                      </Text>
                    </Link>
                  ))}
                </Flex>
              </Flex>
              <Box flex={1} />
              {!noMenuLayout && (
                <Flex
                  direction='column'
                  gap={SpacingVars['6']}
                  alignItems='center'
                  paddingBottom={SpacingVars['12']}
                >
                  <Flex gap={SpacingVars['2']} alignItems='center'>
                    {socialLinks.map(({ url, icon }) => (
                      <Box
                        key={url}
                        borderRadius={RadiusVars['4']}
                        backgroundColor={ColorVars['B&W'].Background}
                      >
                        <Link href={url}>
                          {/* todo: 替换为 token */}
                          <Icon source={icon} size={SpacingVars['6']} color='#3b73af' />
                        </Link>
                      </Box>
                    ))}
                  </Flex>
                  <Flex wrap gap={SpacingVars['3']} alignItems='center' paddingX={SpacingVars['8']}>
                    {footerLinks.map(({ url, name }, index) => (
                      <Fragment key={name}>
                        <Link href={url}>
                          <Flex alignItems='center'>
                            <Text variant='caption2' color={ColorVars['B&W'].Background}>
                              {name}
                            </Text>
                          </Flex>
                        </Link>
                        {index < footerLinks.length - 1 && (
                          <Box
                            backgroundColor={ColorVars['B&W'].Background}
                            width={SpacingVars[1]}
                            height={SpacingVars[1]}
                            borderRadius={RadiusVars[2]}
                          />
                        )}
                      </Fragment>
                    ))}
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Box>
        </Drawer>
        <FullScreenLoading loading={loading} />
      </Box>
    </>
  );
};

const ReturnsPageHeader = ({ shopInfo }: ReturnsPageHeaderProps) => {
  const isMobile = useDevice().mobile;

  if (isMobile) {
    return <ReturnsPageHeaderMobile shopInfo={shopInfo} />;
  }
  return <ReturnsPageHeaderDesktop shopInfo={shopInfo} />;
};

export default ReturnsPageHeader;
