import { DropoffLocationItemBusinessHours, WeekdayArray } from '@aftership/returns-logics-core';

/**
 * 将 20:00 形式的时间转换成 12 小时制
 * 8:00PM
 */
const convert24Hto12H = (inputHour: string): string => {
  // Prepend any date.
  return new Date('1970-01-01T' + inputHour + 'Z').toLocaleTimeString('en-US', {
    timeZone: 'UTC',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  });
};

/**
 * 将相同 close time 和 open time 星期聚合
 * 譬如 :
 * 星期 1 open:10,close:10
 * 星期 2 open:10,close:10
 * 星期 3 open:10,close:10
 * 星期 5 open:10,close:11
 * =>
 * 星期 1 ~ 星期 3 open:10 close:10
 * 星期 5 open:10 close:11
 * @param businessHours
 */
export const calcBusinessHourScope = (businessHours: DropoffLocationItemBusinessHours[]) => {
  const sortedBusinessHours = businessHours
    .filter((item) => WeekdayArray.indexOf(item.day_of_week) > -1)
    .sort((a, b) => WeekdayArray.indexOf(a.day_of_week) - WeekdayArray.indexOf(b.day_of_week));
  const ret: string[] = [];
  // 双指针 end 位置与上一个位置相差一天, 并且营业时间相同,则继续前进
  // 否则 start 移动到 end 为止
  let start = 0;
  let end = 0;
  // start 或者 end 移动到队尾时,结束循环
  while (start < sortedBusinessHours.length && end < sortedBusinessHours.length) {
    const startBusinessWeek = sortedBusinessHours[start] as DropoffLocationItemBusinessHours;
    let lastBusinessWeek = sortedBusinessHours[start] as DropoffLocationItemBusinessHours;
    end = start + 1;
    while (end < sortedBusinessHours.length) {
      const curBusinessWeek = sortedBusinessHours[end] as DropoffLocationItemBusinessHours;
      // 相差一天并且营业时间相同
      if (
        WeekdayArray.indexOf(curBusinessWeek.day_of_week) -
          WeekdayArray.indexOf(lastBusinessWeek?.day_of_week) ==
          1 &&
        curBusinessWeek.open_time === lastBusinessWeek.open_time &&
        curBusinessWeek.close_time === lastBusinessWeek.close_time
      ) {
        lastBusinessWeek = curBusinessWeek;
        end++;
      } else {
        // start 移动到 end 处
        start = end;
        break;
      }
    }
    ret.push(
      startBusinessWeek.day_of_week !== lastBusinessWeek.day_of_week
        ? `${startBusinessWeek.day_of_week} - ${lastBusinessWeek.day_of_week}: ${convert24Hto12H(
            startBusinessWeek.open_time,
          )} ~ ${convert24Hto12H(startBusinessWeek.close_time)}`
        : `${startBusinessWeek.day_of_week}: ${convert24Hto12H(
            startBusinessWeek.open_time,
          )} ~ ${convert24Hto12H(startBusinessWeek.close_time)}`,
    );
  }
  return ret;
};

export const calcTodayCloseTime = (businessHours: DropoffLocationItemBusinessHours[]) => {
  const dayInt = new Date().getDay();
  const index = dayInt === 0 ? 6 : dayInt - 1;
  return businessHours.find((item) => {
    return item.day_of_week === WeekdayArray[index];
  })?.close_time;
};
