import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import OrderList from '@/features/app-proxy-order-list/OrderList';
import { Order } from '@/features/app-proxy-order-list/types';
import MainPage from '@/features/order-lookup/components/MainPage';
import { IQueryParamOrderInfo } from '@/features/returns/types/externalConfig';
import decodeBase64JsonString from '@/features/returns/utils/decodeBase64JsonString';
import useDevice from '@/hooks/useDevice';
import { useIsReCaptchaReady } from '@/hooks/useIsReCaptchaReady';

// // 解析 qs 参数，如果有 order number 和 email，跳转到查询页面而不是 order list 页面
const useGetIsRedirectToMainPage = () => {
  const params = useSearchParams();
  const qs = params ? params.get('qs') : null;

  if (!qs) {
    return false;
  }

  const { order_number, email, postal_code } =
    decodeBase64JsonString<IQueryParamOrderInfo>(qs) || {};

  return order_number && (email || postal_code);
};

interface IHomeProps {
  isAppProxy: boolean;
  orders?: Order[];
}

const toggleCaptchaBadge = (show: boolean, isMobile?: boolean) => {
  const badges = document.getElementsByClassName('grecaptcha-badge');
  Array.from(badges).forEach((badge) => {
    if (badge instanceof HTMLElement) {
      badge.style.visibility = show ? 'visible' : 'hidden';
      // https://aftership.atlassian.net/browse/CRT-4353
      // 本地存在多个 badge 元素，需要筛选出 data-style 不为 none 的
      if (badge.dataset.style !== 'none') {
        badge.style.bottom = isMobile ? '14px' : '100px';
      }
    }
  });
};

export function OrderLookupPage({ isAppProxy, orders = [] }: IHomeProps) {
  const isRedirectToMainPage = useGetIsRedirectToMainPage();
  const isReCaptchaReady = useIsReCaptchaReady();
  const isMobile = useDevice().mobile;

  useEffect(() => {
    if (isReCaptchaReady) {
      toggleCaptchaBadge(true, isMobile);
    }
    return () => toggleCaptchaBadge(false, isMobile);
  }, [isReCaptchaReady, isMobile]);

  if (!isAppProxy) {
    return <MainPage />;
  }

  const isRedirectToOrderListInAppProxy = orders && orders.length > 0 && !isRedirectToMainPage;

  return (
    <>
      {/* 恢复此页面下 shopify 的 footer 标签 */}
      <style
        dangerouslySetInnerHTML={{
          __html: `footer.footer {display: block;}`,
        }}
      />
      {isRedirectToOrderListInAppProxy ? <OrderList orders={orders} /> : <MainPage />}
    </>
  );
}
