import getConfig from 'next/config';

const ShopperV4APIUrls: Record<string, string> = {
  testing: 'https://api.automizely.io/rc/v4/shopper',
  staging: 'https://staging-api.automizely.com/rc/v4/shopper',
  production: 'https://api.automizely.com/rc/v4/shopper',
  development: 'https://api.automizely.io/rc/v4/shopper',
  'testing-pear': 'https://testing-pear-api.automizely.io/rc/v4/shopper',
  'release-pear': 'https://release-pear-api.automizely.io/rc/v4/shopper',
  'release-incy': 'https://release-incy-api.automizely.io/rc/v4/shopper',
  'release-kiwi': 'https://release-kiwi-api.automizely.io/rc/v4/shopper',
  'release-tidy': 'https://release-tidy-api.automizely.io/rc/v4/shopper',
  'release-nike': 'https://release-nike-api.automizely.io/rc/v4/shopper',
  'release-core': 'https://release-core-api.automizely.io/rc/v4/shopper',
};

const ShopperV3APIUrls: Record<string, string> = {
  testing: 'https://api.automizely.io/returnscenter/v3/shopper',
  staging: 'https://staging-api.automizely.com/returnscenter/v3/shopper',
  production: 'https://api.automizely.com/returnscenter/v3/shopper',
  development: 'https://api.automizely.io/returnscenter/v3/shopper',
  'testing-pear': 'https://testing-pear-api.automizely.io/returnscenter/v3/shopper',
  'release-pear': 'https://release-pear-api.automizely.io/returnscenter/v3/shopper',
  'release-incy': 'https://release-incy-api.automizely.io/returnscenter/v3/shopper',
  'release-kiwi': 'https://release-kiwi-api.automizely.io/returnscenter/v3/shopper',
  'release-tidy': 'https://release-tidy-api.automizely.io/returnscenter/v3/shopper',
  'release-nike': 'https://release-nike-api.automizely.io/returnscenter/v3/shopper',
  'release-core': 'https://release-core-api.automizely.io/returnscenter/v3/shopper',
};

const ShopperV2APIUrls: Record<string, string> = {
  testing: 'https://api.returnscenter.io/v2/shopper',
  staging: 'https://staging-api.returnscenter.com/v2/shopper',
  production: 'https://api.returnscenter.com/v2/shopper',
  development: 'https://api.returnscenter.io/v2/shopper',
  'testing-pear': 'https://testing-pear-api.returnscenter.io/v2/shopper',
  'release-pear': 'https://release-pear-api.returnscenter.io/v2/shopper',
  'release-incy': 'https://release-incy-api.returnscenter.io/v2/shopper',
  'release-kiwi': 'https://release-kiwi-api.returnscenter.io/v2/shopper',
  'release-tidy': 'https://release-tidy-api.returnscenter.io/v2/shopper',
  'release-nike': 'https://release-nike-api.returnscenter.io/v2/shopper',
  'release-core': 'https://release-core-api.returnscenter.io/v2/shopper',
};

const ShopperAPIUrls: Record<string, string> = {
  testing: 'https://api.returnscenter.io/shopper/v1',
  staging: 'https://staging-api.returnscenter.com/shopper/v1',
  production: 'https://api.returnscenter.com/shopper/v1',
  development: 'https://api.returnscenter.io/shopper/v1',
  'testing-pear': 'https://testing-pear-api.returnscenter.io/shopper/v1',
  'release-pear': 'https://release-pear-api.returnscenter.io/shopper/v1',
  'release-incy': 'https://release-incy-api.returnscenter.io/shopper/v1',
  'release-kiwi': 'https://release-kiwi-api.returnscenter.io/shopper/v1',
  'release-tidy': 'https://release-tidy-api.returnscenter.io/shopper/v1',
  'release-nike': 'https://release-nike-api.returnscenter.io/shopper/v1',
  'release-core': 'https://release-core-api.returnscenter.io/shopper/v1',
};

// const StoreAPIUrls: Record<string, string> = {
//   testing: 'https://api.returnscenter.io/automizely/shopping/v2',
//   staging: 'https://staging-api.returnscenter.com/automizely/shopping/v2',
//   production: 'https://api.returnscenter.com/automizely/shopping/v2',
//   development: 'https://api.returnscenter.io/automizely/shopping/v2',
//   'testing-pear': 'https://testing-pear-api.returnscenter.io/automizely/shopping/v2',
//   'release-pear': 'https://release-pear-api.returnscenter.io/automizely/shopping/v2',
//   'release-incy': 'https://release-incy-api.returnscenter.io/automizely/shopping/v2',
//   'release-kiwi': 'https://release-kiwi-api.returnscenter.io/automizely/shopping/v2',
//   'release-tidy': 'https://release-tidy-api.returnscenter.io/automizely/shopping/v2',
//   'release-nike': 'https://release-nike-api.returnscenter.io/automizely/shopping/v2',
//   'release-core': 'https://release-core-api.returnscenter.io/automizely/shopping/v2',
// };

const ReturnsWidgetAPIUrls: Record<string, string> = {
  production: 'https://widgets.automizely.com/returns',
  staging: 'https://staging-widgets.automizely.com/returns',
  testing: 'https://widgets.automizely.io/returns',
  development: 'http://localhost:3000/dist',
  'testing-pear': 'https://widgets.automizely.io/returns',
  'release-pear': 'https://release-incy-widgets.automizely.io/returns',
  'release-incy': 'https://release-incy-widgets.automizely.io/returns',
  'release-kiwi': 'https://release-incy-widgets.automizely.io/returns',
  'release-tidy': 'https://release-incy-widgets.automizely.io/returns',
  'release-nike': 'https://release-incy-widgets.automizely.io/returns',
  'release-core': 'https://release-incy-widgets.automizely.io/returns',
};

const SDKUrls: Record<string, string> = {
  testing: 'https://sdks.am-static.io/cookie-banner/sdk.js',
  staging: 'https://sdks.am-static.com/cookie-banner/sdk.js',
  production: 'https://sdks.am-static.com/cookie-banner/sdk.js',
  development: 'https://sdks.am-static.io/cookie-banner/sdk.js',
  'testing-pear': 'https://sdks.am-static.io/cookie-banner/sdk.js',
  'release-pear': 'https://sdks.am-static.io/cookie-banner/sdk.js',
  'release-incy': 'https://sdks.am-static.io/cookie-banner/sdk.js',
  'release-kiwi': 'https://sdks.am-static.io/cookie-banner/sdk.js',
  'release-tidy': 'https://sdks.am-static.io/cookie-banner/sdk.js',
  'release-nike': 'https://sdks.am-static.io/cookie-banner/sdk.js',
  'release-core': 'https://sdks.am-static.io/cookie-banner/sdk.js',
};

const ConnectorsAPIUrls: Record<string, string> = {
  testing: 'http://pltf-connectors.as-in.io/connectors/v2',
  staging: 'http://staging-pltf-connectors.as-in.com/connectors/v2',
  production: 'http://pltf-connectors.as-in.com/connectors/v2',
  development: 'https://testing-incy-platform.automizelyapi.io/connectors/v2',
  'testing-pear': 'https://platform.automizelyapi.io/connectors/v2',
  'release-pear': 'https://platform.automizelyapi.io/connectors/v2',
  'release-incy': 'https://platform.automizelyapi.io/connectors/v2',
  'release-kiwi': 'https://platform.automizelyapi.io/connectors/v2',
  'release-tidy': 'https://platform.automizelyapi.io/connectors/v2',
  'release-nike': 'https://platform.automizelyapi.io/connectors/v2',
  'release-core': 'https://platform.automizelyapi.io/connectors/v2',
};

const PlatformAPIUrls: Record<string, string> = {
  testing: 'http://pltf-account-business.as-in.io/business/v2',
  staging: 'http://staging-pltf-account-business.as-in.com/business/v2',
  production: 'http://pltf-account-business.as-in.com/business/v2',
  development: 'http://pltf-account-business.as-in.io/business/v2',
  'testing-pear': 'http://pltf-account-business.as-in.io/business/v2',
  'release-pear': 'http://pltf-account-business.as-in.io/business/v2',
  'release-incy': 'http://pltf-account-business.as-in.io/business/v2',
  'release-kiwi': 'http://pltf-account-business.as-in.io/business/v2',
  'release-tidy': 'http://pltf-account-business.as-in.io/business/v2',
  'release-nike': 'http://pltf-account-business.as-in.io/business/v2',
  'release-core': 'http://pltf-account-business.as-in.io/business/v2',
};

export const getReturnPageUrlByUserName = (username: string) => {
  if (APP_ENV === 'production') {
    return `${username}.returnscenter.com`;
  }
  return `${username}.returnscenter.io`;
};

const {
  publicRuntimeConfig: { APP_ENV },
} = getConfig();

const getEndpoint = (name: string, urlObject: Record<string, string>): string => {
  let env = APP_ENV || 'development';

  return urlObject[env] ?? (urlObject.production as string);
};

export const connectorsEndpoint = getEndpoint('connectors', ConnectorsAPIUrls);

export const sdkEndpoint = getEndpoint('sdk', SDKUrls);

export const shopperEndpoint = getEndpoint('shopper', ShopperAPIUrls);

export const shopperV2Endpoint = getEndpoint('shopperV2', ShopperV2APIUrls);

export const shopperV3Endpoint = getEndpoint('shopperV3', ShopperV3APIUrls);

export const shopperV4Endpoint = getEndpoint('shopperV4', ShopperV4APIUrls);

export const widgetEndpoint = getEndpoint('widget', ReturnsWidgetAPIUrls);
export const platformEndpoint = getEndpoint('platform', PlatformAPIUrls);
