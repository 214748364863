import { t } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IDraftReturn,
  InstantExchangeChargeTriggerStatusMap,
} from '@aftership/returns-logics-core';

import { secondToDays } from '@/utils/date';
import { toCurrency } from '@/utils/price';

import StripeElementModal from './StripeElementModal';
import { StripeContextProvider, useCreateStripeContextValue } from './StripeProvider';

import { StripeInProgressModal } from '../../../InProgressModals';
import { PaymentDetailItemProps } from '../PaymentDetail/PaymentDetail';

export interface StripeModalProps {
  isOpen: boolean;
  stripeKey: string;
  stripeToken: string;
  paymentDetail?: PaymentDetailItemProps[];
  hasChargePayment?: boolean;
  isReplacementNeedCostOfReturnCharge?: boolean;
  isInstantExchange?: boolean;
  onContinue: VoidFunction;
  onCancel: VoidFunction;
}

export const getPaymentDetail = (data?: IDraftReturn | null) => {
  if (!data) return;

  const {
    instant_exchange_charge_payment,
    instant_exchange_hold_payment,
    instant_exchange_charge_trigger_seconds,
    instant_exchange_charge_trigger_status,
    exchange_calculations,
  } = data;

  const paymentDetail = [];

  if (
    exchange_calculations?.exchange.checkout_total &&
    Number(exchange_calculations?.exchange.checkout_total?.amount || '0') > 0
  ) {
    paymentDetail.push({
      name: t('EXCHAN_708EFC'),
      value: `${toCurrency(exchange_calculations.exchange.checkout_total?.amount, exchange_calculations.exchange.checkout_total?.currency)}`,
    });
  }

  if (
    instant_exchange_hold_payment &&
    instant_exchange_charge_payment &&
    instant_exchange_charge_trigger_status &&
    instant_exchange_charge_trigger_seconds
  ) {
    paymentDetail.push({
      name: t('TEMPOR_404084'),
      value: `${toCurrency(instant_exchange_hold_payment?.amount, instant_exchange_hold_payment?.currency)}`,
      description: t('INSTAN_1160E2', {
        instantExchangeHoldPayment: toCurrency(
          instant_exchange_hold_payment?.amount,
          instant_exchange_hold_payment?.currency,
        ),
        instantExchangeChargePayment: toCurrency(
          instant_exchange_charge_payment?.amount,
          instant_exchange_charge_payment?.currency,
        ),
        triggerStatusName:
          InstantExchangeChargeTriggerStatusMap[instant_exchange_charge_trigger_status],
        triggerDays: secondToDays(instant_exchange_charge_trigger_seconds),
      }),
    });
  }

  return paymentDetail;
};

const StripeModal = ({
  isOpen,
  stripeKey,
  stripeToken,
  paymentDetail,
  hasChargePayment,
  isInstantExchange,
  isReplacementNeedCostOfReturnCharge,
  onContinue,
  onCancel,
}: StripeModalProps) => {
  const { t } = useTranslation();
  const [inProgress, setProgress] = useState<boolean>(false);
  const stripeContext = useCreateStripeContextValue();

  const handleContinue = () => {
    setProgress(true);
    onContinue();
  };
  if (inProgress) {
    const getModalTitle = () => {
      if (hasChargePayment) {
        return t('page.error.paymentInProgress');
      } else {
        if (!isInstantExchange && isReplacementNeedCostOfReturnCharge) return t('PAYMEN_AF7C51');
        return t('PROCES_35DD0F');
      }
    };

    const getModalMessage = () => {
      if (hasChargePayment) {
        return t('popup.description.processingStripePayment');
      } else {
        if (!isInstantExchange && isReplacementNeedCostOfReturnCharge) return t('STRIPE_049B0A');
        return t('STRIPE_45639E');
      }
    };

    return (
      <StripeInProgressModal
        isOpen={inProgress && isOpen}
        onCancel={onCancel}
        title={getModalTitle()}
        message={getModalMessage()}
      />
    );
  }

  return (
    <StripeContextProvider {...stripeContext}>
      <StripeElementModal
        isOpen={isOpen}
        stripeKey={stripeKey}
        stripeToken={stripeToken}
        paymentDetail={paymentDetail}
        onContinue={handleContinue}
        onCancel={onCancel}
      />
    </StripeContextProvider>
  );
};

export default StripeModal;
