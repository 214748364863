import { ActorRefFrom, SnapshotFrom, assign, fromPromise, setup } from 'xstate';

const defaultMaxPollingCount = 10;
const defaultPollingInterval = 1000;

const maxDelay = 1 * 5_000;

export interface PollingRequestInput<Payload> {
  requestPayload: Payload;
  pollingConfig?: {
    pollingInterval?: number;
    maxPollingCount?: number;
  };
}

export interface PollingContext<TResponse, Payload> {
  pollingCount: number;

  response: TResponse;

  requestPayload: Payload;

  pollingConfig: {
    pollingInterval: number;
    maxPollingCount: number;
  };
}

export const makePollingRequest = <TResponseData, TRequestPayload>() =>
  setup({
    types: {
      context: {} as PollingContext<TResponseData, TRequestPayload>,
      input: {} as PollingRequestInput<TRequestPayload>,
      output: {} as TResponseData,
    },
    actions: {},
    guards: {
      resourceIsVerified: () => {
        return false;
      },
    },
    actors: {
      retrieveResource: fromPromise<TResponseData, TRequestPayload>(async () => {
        return null as TResponseData;
      }),
    },
    delays: {
      pollingInterval: ({ context }) =>
        Math.min(1000 + 200 * 2 ** context.pollingConfig.pollingInterval, maxDelay),
    },
  }).createMachine({
    id: 'pollingMachine',

    output: ({ context }) => {
      return context.response;
    },

    context: ({ input }) => {
      return {
        pollingCount: 0,
        retrievedValue: undefined,
        pollingConfig: {
          maxPollingCount: input.pollingConfig?.maxPollingCount || defaultMaxPollingCount,
          pollingInterval: input.pollingConfig?.pollingInterval || defaultPollingInterval,
        },
        response: null as TResponseData,
        requestPayload: input.requestPayload,
      };
    },
    initial: 'polling',

    states: {
      polling: {
        entry: [assign({ pollingCount: ({ context }) => context.pollingCount + 1 })],
        // @ts-ignore
        invoke: {
          src: 'retrieveResource',
          input: ({ context }) => {
            return context.requestPayload;
          },
          onDone: {
            target: 'verify',
            actions: assign({
              response: ({ event }) => {
                return event?.output as TResponseData;
              },
            }),
          },
          onError: {
            target: 'scheduleNextPoll',
            actions: ({ event }) => {
              console.log('event.error', event.error);
            },
          },
        },
      },
      verify: {
        always: [
          { target: 'resourceFound', guard: 'resourceIsVerified' },
          { target: 'scheduleNextPoll' },
        ],
      },
      scheduleNextPoll: {
        after: {
          pollingInterval: [
            {
              target: 'polling',
              guard: ({ context }) => context.pollingCount < context.pollingConfig.maxPollingCount,
            },
            { target: 'pollingExpired' },
          ],
        },
      },
      resourceFound: {
        type: 'final',
      },
      pollingExpired: {
        type: 'final',
      },
    },
  });

export type PollingSubFlowActorRef = ActorRefFrom<typeof makePollingRequest>;

export type PollingSubFlowSnapshot = SnapshotFrom<typeof makePollingRequest>;
