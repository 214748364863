/* eslint-disable max-lines */
import type { RefundStatus } from '../../../core/promise/refundid';
import { RefundDestination, Resolution } from '../../constant';
import { SummaryTotalType } from '../../constant/return';
import {
  DropoffStatus,
  IShippingDocumentType,
  ReturnStatus,
  ReturnType,
  ShippingFeeOption,
  ShippingStatus,
} from '../../constant/returnList';
import { ReturnCostOption, ReturnMethodSlug } from '../../constant/returnMethod';
import { Maybe } from '../common';
import { AmountPrice, PresentmentMoney, Price } from '../common/price';
import { ShippingAddress } from '../order';
import { ExchangeChargeMode, ExchangeMode } from '../shopInfo';

export enum StorePlatform {
  Shopify = 'shopify',
  Bigcommerce = 'bigcommerce',
  Sfcc = 'sfcc',
  Woocommerce = 'woocommerce',
}

export interface ReturnImage {
  id: string;
  group_id: string;
  src: string;
  size: string;
  height: number;
  width: number;
}

export interface ExchangeOrder {
  checkout_url: string;
  number: string;
  external_id: string;
}

export interface ReturnItemDetailType {
  notes_to_merchant: string;
  reason: string;
  subreason: string;
  item_id: string;
  return_quantity: number;
  price: {
    presentment_money: PresentmentMoney;
  };
  unit_price_in_checkout_currency?: {
    presentment_money: PresentmentMoney;
  } | null;
  unit_price: {
    presentment_money: PresentmentMoney;
  };
  discount: {
    presentment_money: PresentmentMoney;
  };
  image: ReturnImage;
  title: string;
  product_id: string;
  sku: string;
  total_quantity: number;
  returnable_quantity: number;
  return_images?: ReturnImage[];
  price_in_checkout_currency?: {
    presentment_money: PresentmentMoney;
  };
  discount_in_checkout_currency: {
    presentment_money: PresentmentMoney;
  };
  product_title?: string;
  variant_title?: string;
  tax_in_checkout_currency?: {
    presentment_money: PresentmentMoney;
  } | null;
  tax?: {
    presentment_money: PresentmentMoney;
  } | null;
  base_price_in_checkout_currency?: {
    presentment_money: PresentmentMoney;
  } | null;
  base_price_set?: {
    presentment_money: PresentmentMoney | null;
  } | null;
  discounted_base_price_in_checkout_currency?: {
    presentment_money: PresentmentMoney;
  } | null;
  discounted_base_price_set?: {
    presentment_money: PresentmentMoney | null;
  } | null;
  type: ReturnType;
  /**
   * 当 type 为 bundle_item 时才有该字段
   */
  parent_item?: {
    item_id: string;
    sku: string;
    product_title: string;
    variant_title: string;
  };
  quantity_increments: number | null;
  /**
   * return method 为 happy return 时, dropp off 发生的时间
   */
  dropped_at: string | null;
  /**
   * return method 为 happy return 时, 会存在该 id, 通过 id 可以到 returns 接口返回的 dropoffs 匹配详细信息
   */
  dropoff_id: string | null;
  tags?: string[] | null;

  price_set: {
    shop_money: AmountPrice;
    presentment_money: AmountPrice;
  };
}

export interface DropoffErrorDetail {
  code: number;
  message: string;
}

export interface DropoffItemDetail {
  id: string;
  provider_slug: string;
  dropoff_number: string;
  status: DropoffStatus;
  failed_detail: null | DropoffErrorDetail;
  label_qr_code: null | string;
  created_at: string;
  updated_at: string;
}

export interface EFACheckoutMismatch {
  shipping_address_mismatch?: boolean;
  checkout_total_mismatch?: boolean;
}
export interface ExchangeItemDetail {
  product_id?: null;
  external_product_id: string;
  variant_id?: null;
  external_variant_id: string;
  sku: string;
  title: string;
  quantity: number;
  price: Price;
  weight: Weight;
  image_url: string;
  price_in_checkout_currency: Price;
  product_title?: string;
  product_url?: string;
  variant_title?: string;
  quantity_increments: number | null;
  tags?: string[];
  price_set: {
    shop_money: AmountPrice;
    presentment_money: AmountPrice | null;
  } | null;
}

export interface Weight {
  unit: string;
  value: number;
}

export interface ExchangeForAnythingOrder {
  id: string;
  number: string;
  external_id: string;
  order_total: Price;
  url?: null;
  checkout_url: string;
  fulfillment_status: string;
}

export interface ReplacementOrderContext {
  checkout_url: string | null;
}

export interface ShipmentItemInfo {
  sku: string;
  item_id: string;
  product_title: string;
  variant_title: string;
  quantity: number;
}

export enum GenerateLabelStatus {
  Creating = 'creating',
  Created = 'created',
  Failed = 'failed',
}
/**
 * 创建 returns 后的物流信息, 多 label 时, 会是个数组.
 * 一个 label(ShipmentInfo) 会包含多个 Item(ShipmentItemInfo)
 */
export interface ShipmentsInfo {
  // 物流单号
  tracking_number?: string;
  // 实时追踪的物流名称
  tracking_slug?: string;
  // 物流名称
  courier_slug?: string;
  // 暂时用不上
  retailer_label_source: string;
  // label 的 url, 在没有生成之前不会返回
  retailer_label_url?: string;
  // 发票地址,跨境时可能存在
  retailer_invoice_url?: string;
  // 暂时用不上
  postmen_label_id: string;
  // 当前 label 生成的状态
  auto_label_generation_status: GenerateLabelStatus;
  status: GenerateLabelStatus;
  // 二维码地址
  label_qr_code?: string;
  // 当前 label 包含的商品信息
  items: Array<ShipmentItemInfo> | null;
  // label 所绑定的 shipment id
  return_shipment_id?: string;
  // return_shipment 是否已保存了打单 payload 的快照
  additional_label_payload_available?: boolean;
  // label 创建的时间
  created_at?: string;
  // 该 shipment 是否开启了 qr code, 在 new carrier(RC 添加 carrier) 上线 之后，都由这个字段去判断一个 shipment 是否开启了 qr code
  // 如果这个值是 null，则使用 shops 接口去判断是否开启 qr code；如果是布尔值，则使用这个字段去判断
  // https://aftership.atlassian.net/browse/RTC-15092
  postmen_label_qr_code_enabled?: boolean | null;
  packing_slip_url?: string;
  shipping_documents?: IShippingDocument[];
  shipping_documents_url?: string;
  additional_labels: ShipmentsInfo[];

  /**
   * 预期生成的 label 数组
   */
  shipment_allocations: IShipmentAllocation[];
}

export interface IShipmentAllocation {
  item: {
    allocation_id: string;
  };
}
export interface IShippingDocument {
  type: IShippingDocumentType;
}

export interface AutomationContext {
  // retail label 是否开启了自动 approval
  auto_label_generation?: {
    enabled: boolean;
  };
  // HappyReturn 是否开启了自动 approval
  auto_happy_returns_dropoff_generation?: {
    enabled: boolean;
  };
  // RetailRework 是否开启了自动 approval
  auto_retail_reworks_dropoff_generation?: {
    enabled: boolean;
  };
}

export interface IShopMoneyAndPresentmentMoney {
  shop_money: AmountPrice | null;
  presentment_money: AmountPrice | null;
}
export interface ReturnPreviewSummary {
  credit_total_set: IShopMoneyAndPresentmentMoney;
  refund_credit_set: IShopMoneyAndPresentmentMoney;
  return_item_subtotal_set: IShopMoneyAndPresentmentMoney;
  return_item_tax_total_set: IShopMoneyAndPresentmentMoney;
  return_item_taxes_included: boolean;
  extra_credit_value_option: any;
  extra_credit_value: any;
  extra_credit_set: IShopMoneyAndPresentmentMoney;
  extra_credit_used_set: IShopMoneyAndPresentmentMoney;
  pre_discount_credit_set: IShopMoneyAndPresentmentMoney;
  pre_discount_credit_used_set: IShopMoneyAndPresentmentMoney;
  credit_remain_set: IShopMoneyAndPresentmentMoney;
  exchange_total_set: IShopMoneyAndPresentmentMoney;
  exchange_item_subtotal_set: IShopMoneyAndPresentmentMoney;
  exchange_item_tax_total_set: IShopMoneyAndPresentmentMoney;
  exchange_item_base_price_total: IShopMoneyAndPresentmentMoney;
  exchange_item_taxes_included: boolean;
  return_cost_option?: ReturnCostOption;
  cost_of_return_set: IShopMoneyAndPresentmentMoney;
  exchange_shipping_fee_option: ShippingFeeOption | null;
  exchange_shipping_fee_set: IShopMoneyAndPresentmentMoney;
  checkout_total_rounding_set: IShopMoneyAndPresentmentMoney;
  financial_outcome: SummaryTotalType;
  checkout_total_set: IShopMoneyAndPresentmentMoney;
  refund_total_set: IShopMoneyAndPresentmentMoney;
  checkout_custom_shipping_total_set: IShopMoneyAndPresentmentMoney;
  checkout_discount_total_set: IShopMoneyAndPresentmentMoney;
  /**
   * @description cost of return 是否参与最终计算当中，如果为 false 并且 exchange mode 为 charge by other platform，则需要展示 replace summary note
   */
  cost_of_return_included_in_calculation: boolean;
  store_credit_incentive_set: IShopMoneyAndPresentmentMoney;
}

export interface ReturnItemType {
  shipping_status: ShippingStatus | null;
  id: string;
  created_at: string;
  customer_email: string;
  customer_name: string;
  items: ReturnItemDetailType[];
  dropoff_status: DropoffStatus;
  notes_to_merchant?: null;
  order_number: string;
  reason?: null;
  reject_reason?: null | string;
  rma_id: string;
  shop_id: string;
  status: ReturnStatus;
  updated_at: string;
  tracking_number: string;
  courier_slug: string;
  source: string;
  store?: {
    platform?: StorePlatform;
  };
  resolution: Resolution;
  exchange_request?: null;
  return_method_slug: ReturnMethodSlug;
  retailer_label_url?: string;
  country_order: string;
  return_method_id: string;
  return_method_name: string;
  zone_name: string;
  cost_of_return?: AmountPrice;
  cost_of_returns_description: string;
  cost_of_returns_currency?: null | string;
  return_cost_option: ReturnCostOption;
  return_cost_amount: string;
  rule_name: string;
  postmen_label_id?: null;
  source_order_id: string;
  source_order_url: string;
  auto_label_generation_status?: string;
  order_taxes_included: boolean;
  exchange_for_anything_order_taxes_included?: boolean | null;
  has_exchange_variant: boolean;
  label_qr_code?: null | string;
  retailer_invoice_url?: string;
  last_failed_refunded_at?: null;
  refund_resolution?: null;
  has_exchange_for_anything: boolean;
  exchange_order: ExchangeOrder | null;
  exchange_for_anything_refund_resolution?: string | null;
  exchange_for_anything_checkout_mismatch?: EFACheckoutMismatch;
  // 多个 refund 可能是 refund 失败了
  // 成功的会放在第一个
  refunds?: {
    id: string;
    destination: RefundDestination;
    refunded_total?: AmountPrice;
    created_at: string;
    updated_at: string;
    status: RefundStatus;
  }[];
  exchange_items: ExchangeItemDetail[];
  refunded?: boolean | null;
  refund_failed: boolean;
  refund_destination: RefundDestination;
  last_failed_refunded_detail?: null;
  exchange_for_anything_items_tax_total: AmountPrice | null;
  exchange_for_anything_extra_credit_active: boolean;
  exchange_for_anything_extra_credit_type: string;
  exchange_for_anything_extra_credit_fixed_amount: Price | null;
  exchange_for_anything_extra_credit_percentage?: null;
  exchange_for_anything_extra_credit_used: AmountPrice | null;
  exchange_for_anything_pre_discount_credit_used: AmountPrice | null;
  exchange_for_anything_subtotal: AmountPrice | null;
  exchange_for_anything_refund_total: AmountPrice | null;
  exchange_for_anything_checkout_total: AmountPrice | null;
  return_images?: ReturnImage[];
  replacement_order_number: string | null;
  shipments?: Array<ShipmentsInfo>;
  exchange_order_shipping_address: ShippingAddress | null;
  exchange_for_anything_shipping_fee: AmountPrice | null;
  exchange_for_anything_shipping_fee_option: Maybe<ShippingFeeOption>;
  // 现在所有的 returnMethod 都支持 packing slip
  packing_slip_url?: string;
  automation_context?: AutomationContext;
  is_gift_return: boolean;
  recipient_first_name: string;
  recipient_last_name: string;
  recipient_email: string;
  recipient_shipping_address: Pick<ShippingAddress, 'first_name' | 'last_name' | 'email'>;
  shipping_documents?: IShippingDocument[];
  shipping_documents_url?: string;

  return_preview_summary: ReturnPreviewSummary;
  exchange_mode: ExchangeMode;
  exchange_rule_item_price_difference_settlement: ExchangeChargeMode;
}

export interface Returns {
  returns: ReturnItemType[];
}
