class MemoryStorage implements Storage {
  private storage: { [key: string]: string } = {};

  // TODO: implementation
  readonly length: number = 0;

  public key(index: number): string | null {
    return Object.keys(this.storage)[index] || null;
  }

  public getItem(key: string): string | null {
    return this.storage[key] || null;
  }

  public setItem(key: string, value: string): void {
    this.storage[key] = value;
  }

  public removeItem(key: string): void {
    delete this.storage[key];
  }

  public clear(): void {
    this.storage = {};
  }
}

const memoryStorage = new MemoryStorage();

export const getSecureSessionStorage = (): Storage => {
  let storage: Storage;

  // Safe access to storage unexpectedly on server side
  if (typeof window === 'undefined') {
    return memoryStorage;
  }

  try {
    storage = window.sessionStorage;
  } catch (error) {
    // For incognito mode in some browsers, especially incognito mode within iframe,
    // we can't access sessionStorage where we will get an `insecure` error, so we use memoryStorage instead.
    storage = memoryStorage;
  }

  return storage;
};

export default getSecureSessionStorage;
