'use client';

import { usePathname } from 'next/navigation';

import { ISelectedItem, PreviewExchangeItem, Resolution } from '@aftership/returns-logics-core';
import { useFlow } from 'returns-logics/react';

import { useGeneratePlatformEfaUrl } from '@/features/resolution/utils/url';
import { ReturnItem } from '@/features/return-detail/components/ReturnItems/ReturnItems';
import { useFieldAWithMemo } from '@/hooks/useFieldAWithMemo';
import { isSSR } from '@/utils/runtimeEnv';

export interface ReturnPreviewReturnItems extends ReturnItem {
  replacement?: ISelectedItem['exchangeVariant'];
}

export const useReturnPreviewFlow = () => {
  const flow = useFlow();
  const currentPathname = usePathname();
  const { generatePlatformEfaUrl } = useGeneratePlatformEfaUrl(flow?.context);

  const orders = flow?.context?.request?.orders;
  const reviewSubFlow = flow?.children?.reviewSubFlow;
  const returnPreviewFlowContext = useFieldAWithMemo(
    flow?.children.reviewSubFlow?.snapshot?.context,
  );

  const currentStep = flow?.currentStep.name;

  const isLoading = reviewSubFlow?.currentStep?.isLoading;
  const returnPreview = returnPreviewFlowContext?.preview;
  const resolution = returnPreviewFlowContext?.resolution;
  const storeConfig = flow?.context?.storeConfig;

  const shippingAddress = returnPreviewFlowContext?.isGiftReturn
    ? resolution === Resolution.ReplaceTheSameItem &&
      returnPreviewFlowContext?.giftReturnShippingAddress?.first_name &&
      returnPreviewFlowContext?.giftReturnShippingAddress?.last_name &&
      returnPreviewFlowContext?.giftReturnShippingAddress?.email
      ? returnPreviewFlowContext?.giftReturnShippingAddress
      : null
    : returnPreviewFlowContext?.shippingAddress;

  const comment = flow?.context?.replaceComment;

  const returnItems = returnPreviewFlowContext?.selectedItems?.map((item) => ({
    itemId: item.itemId,
    productTitle: item.productTitle,
    price: item.frontEndPrice,
    originPrice: item.originPrice,
    variantTitle: item.variantTitle,
    productCoverUrl: item.productCoverUrl,
    returnReason: item.selectedReason,
    returnSubReason: item.selectedSubReason,
    quantity: item.quantity,
    returnImages: item.returnImages ?? [],
    replacement: item?.exchangeVariant,
    notesToMerchant: item.comment,
    productTags: item.productTags ?? [],
  })) as ReturnPreviewReturnItems[];

  // 只是 replace 的 items
  const replacementExchangeItems =
    returnPreviewFlowContext?.selectedItems?.reduce((prev, cur) => {
      if (cur.exchangeVariant && cur.itemId) {
        const amountPrice = returnPreview?.return_items?.find(
          (r) =>
            r.replacement?.external_variant_id === cur.exchangeVariant?.variantId &&
            r.item_id === cur.itemId,
        )?.replacement?.price_set?.presentment_money;

        return [
          ...prev,
          {
            productTitle: cur.exchangeVariant.productTitle,
            variantTitle: cur.exchangeVariant.variantTitle,
            productCoverUrl: cur.exchangeVariant.variantCoverUrl,
            productId: cur.exchangeVariant.productId,
            variantId: cur.exchangeVariant.variantId,
            quantity: cur.quantity,
            price: amountPrice,
            resolution,
            productTags: cur?.productTags ?? [],
          } as PreviewExchangeItem,
        ];
      }
      return prev;
    }, [] as PreviewExchangeItem[]) ?? [];

  const exchangeEFAItems: PreviewExchangeItem[] = returnPreviewFlowContext?.exchangeItems ?? [];

  const exchangeItems = [...replacementExchangeItems, ...exchangeEFAItems];

  const costOfReturnOptions = returnPreviewFlowContext?.preview?.summary?.return_cost_option;

  const costOfReturnAmountPrice =
    returnPreviewFlowContext?.preview?.summary?.cost_of_return_set?.presentment_money;
  const returnMethod = returnPreviewFlowContext?.returnMethod;

  const onStoreUrl =
    !isSSR() &&
    generatePlatformEfaUrl({
      redirectUrl: `${window.location.origin}${currentPathname}`,
    });

  const returnError = returnPreviewFlowContext?.returnError;
  const reviewError = returnPreviewFlowContext?.reviewError;
  const returnPreviewFlowDispatch = reviewSubFlow?.dispatch;
  const returnPreviewFlowMatches = reviewSubFlow?.matches;

  return {
    currentStep,
    orders,
    comment,
    returnError,
    reviewError,
    storeConfig,
    isLoading,
    returnPreview,
    returnItems,
    exchangeItems,
    exchangeEFAItems,
    resolution,
    returnMethod,
    costOfReturnOptions,
    costOfReturnAmountPrice,
    shippingAddress,
    reviewSubFlow,
    onStoreUrl,
    mainDispatch: flow?.dispatch,
    returnPreviewFlowDispatch,
    returnPreviewFlowMatches,
    returnPreviewFlowContext,
  };
};
