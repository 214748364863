/* eslint-disable max-lines */
import {
  ActorRefFrom,
  ErrorActorEvent,
  SnapshotFrom,
  assertEvent,
  assign,
  enqueueActions,
  sendParent,
  setup,
} from 'xstate';

import {
  ReturnMethodContext,
  ReturnMethodEvent,
  ReturnMethodInput,
  ReturnMethodOutput,
} from './types';

import {
  ReturnMethodSlug,
  SupportDropoffSlug,
  maxHappyReturnSelectedItems,
} from '../../constant/returnMethod';
import {
  batchGetDropoffLocations,
  getDropoffLocations,
  getMatchingMethod,
} from '../../promise/returnMethod';
import { ErrorResponse } from '../../request/ErrorResponse';
import { ReturnMethod } from '../../types/returnMethod';
import { isJWTError, takeMainActorRef } from '../../utils/eventUtils';
import { getDropoffSlugs, parseMatchingMethodPayload } from '../../utils/returnMethod';
import { resolveIntentionData } from '../resolveIntentionData';

export const returnMethodSubFlow = setup({
  types: {
    context: {} as ReturnMethodContext,
    input: {} as ReturnMethodInput,
    output: {} as ReturnMethodOutput,
    events: {} as ReturnMethodEvent,
  },
  actors: {
    getMatchingMethod,
    getDropoffLocations,
    batchGetDropoffLocations,
    resolveIntentionData,
  },
  actions: {
    updateSelectedMethodInPreviewMode: () => {},
  },
}).createMachine({
  entry: 'updateSelectedMethodInPreviewMode',
  initial: 'validateData',
  context: ({ input }) => ({
    ...input,
    returnMethods: [],
    dropoffLocations: {},
  }),
  output: ({ context }) => {
    const method = context.returnMethods.find((method) => method.id === context.selectedMethodId);
    return {
      selectedMethod: {
        id: method?.id || '',
        name: method?.name || '',
        description: method?.description || '',
        slug: method?.slug,
        pickupData:
          method?.slug === ReturnMethodSlug.CarrierPickup ? context.pickupData : undefined,
      },
    };
  },
  states: {
    validateData: {
      tags: 'loading',
      always: [
        {
          guard: ({ context }) =>
            context.resolution === undefined || context.selectedItems.length <= 0,
          actions: sendParent({ type: 'GO_TO_ORDER_LOOKUP' }),
        },
        { target: 'beforeGetMatchingMethod' },
      ],
    },
    beforeGetMatchingMethod: {
      description: '判断是不是 EFA 模式',
      initial: 'checkEFA',
      tags: 'loading',
      states: {
        checkEFA: {
          always: [
            {
              description:
                '如果是 EFA 模式，解析 intentionId 拿到 exchangeItems 再获取对应的 matching method',
              guard: ({ context }) => Boolean(context.intentionId),
              target: '#resolveIntentionData',
            },
            {
              description: '不是 EFA 模式，拿外部传入的 exchangeItems 获取 matching method',
              target: '#getMatchingMethod',
            },
          ],
        },
      },
    },
    resolveIntentionData: {
      description: '解析 intentionId',
      id: 'resolveIntentionData',
      tags: 'loading',
      invoke: {
        src: 'resolveIntentionData',
        input: ({ context }) => ({
          token: context.token,
          intentionId: context.intentionId || '',
        }),
        onDone: {
          target: 'getMatchingMethod',
          actions: assign({
            exchangeItems: ({ event }) =>
              (event.output.exchangeItems || []).map((item) => ({
                external_product_id: item.productId,
                external_variant_id: item.variantId,
                product_id: item.productId,
                quantity: item.quantity,
              })),
          }),
        },
        onError: [
          {
            guard: ({ event }) => isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
            actions: enqueueActions(({ enqueue }) => {
              enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                type: 'HANDLE_JWT_ERROR',
              });
            }),
          },
        ],
      },
    },
    getMatchingMethod: {
      description: '获取 return method 列表',
      id: 'getMatchingMethod',
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            src: 'getMatchingMethod',
            input: ({ context }) => ({
              token: context.token,
              ...parseMatchingMethodPayload({
                resolution: context.resolution,
                exchangeItems: context.exchangeItems,
                selectedItems: context.selectedItems,
                isGiftReturnMode: context.isGiftReturn,
              }),
            }),
            onDone: {
              target: 'success',
              actions: assign({ returnMethods: ({ event }) => event.output }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        success: { type: 'final' },
        error: { tags: 'error' },
      },
      onDone: { target: 'batchGetDropoffLocations' },
    },
    batchGetDropoffLocations: {
      description: '获取 dropoff method 下的 location',
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            src: 'batchGetDropoffLocations',
            input: ({ context }) => ({
              token: context.token,
              orderId: context.orderId,
              slugs: getDropoffSlugs(context.returnMethods),
            }),
            onDone: {
              target: 'success',
              actions: assign({ dropoffLocations: ({ event }) => event.output }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        success: { type: 'final' },
        error: { tags: 'error' },
      },
      onDone: {
        // 不能在一个 assign 中同时赋值 returnMethods 和 selectedMethodId
        // 因为这里需要先更新 returnMethods，再更新 selectedMethodId
        actions: [
          assign({
            returnMethods: ({ context }) => {
              const happyReturns = context.returnMethods.find(
                (method) => method.slug === ReturnMethodSlug.HappyReturns,
              );
              const retailRework = context.returnMethods.find(
                (method) => method.slug === ReturnMethodSlug.RetailRework,
              );
              // 没有可用的 dropoff locations 时，禁用 retail rework
              const disabledRetailRework =
                (context.dropoffLocations[ReturnMethodSlug.RetailRework]?.locations || []).length <=
                0;
              // 超过 9 个 items 时，禁用 happy returns
              const disabledHappyReturns =
                context.selectedItems.reduce((acc, item) => acc + item.quantity, 0) >
                maxHappyReturnSelectedItems;

              const notDropoffMethods = context.returnMethods.filter(
                (method) =>
                  method.slug !== ReturnMethodSlug.RetailRework &&
                  method.slug !== ReturnMethodSlug.HappyReturns,
              );
              // happy return 要在 retail rework 前面，禁用时也一样
              // 因为构建时用的是 nextjs 的 tsconfig，所以这里需要强制类型转换，不然构建编译时会报错
              // 三期 headless 单独构建后可以解决这个问题
              const dropoffMethods = (
                [happyReturns, retailRework].filter((method) => !!method) as (ReturnMethod & {
                  disabled?: boolean;
                })[]
              ).map((method) => {
                if (method.slug === ReturnMethodSlug.RetailRework) {
                  return { ...method, disabled: disabledRetailRework };
                }
                if (method.slug === ReturnMethodSlug.HappyReturns) {
                  return { ...method, disabled: disabledHappyReturns };
                }
                return method;
              });
              const disabledDropoffMethods = dropoffMethods.filter((method) => method.disabled);
              const enabledDropoffMethods = dropoffMethods.filter((method) => !method.disabled);

              return [...enabledDropoffMethods, ...notDropoffMethods, ...disabledDropoffMethods];
            },
          }),
          assign({
            selectedMethodId: ({ context }) => {
              const method = context.returnMethods.find((method) => {
                if (method.id === context.selectedMethodId && !method.disabled) {
                  return true;
                }
                if (method.slug === ReturnMethodSlug.HappyReturns && !method.disabled) {
                  return true;
                }
                if (method.slug === ReturnMethodSlug.RetailRework && !method.disabled) {
                  return true;
                }
                return false;
              });
              return method ? method.id : '';
            },
          }),
        ],
        target: 'waitingSelectReturnMethod',
      },
    },
    loadNearbyLocations: {
      initial: 'loading',
      states: {
        loading: {
          invoke: {
            src: 'getDropoffLocations',
            input: ({ context, event }) => {
              assertEvent(event, 'LOAD_NEARBY_LOCATIONS');
              return {
                token: context.token,
                orderId: context.orderId,
                latitude: event.data.latitude,
                longitude: event.data.longitude,
                slug: SupportDropoffSlug.HappyReturns,
              };
            },
            onDone: {
              target: 'success',
              actions: assign({ nearbyLocations: ({ event }) => event.output }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        success: { type: 'final' },
        error: { tags: 'error' },
      },
    },
    waitingSelectReturnMethod: {},
    done: { type: 'final' },
  },
  on: {
    SELECT_RETURN_METHOD: {
      actions: assign({
        selectedMethodId: ({ event }) => event.data.methodId,
        selectedMethodSlug: ({ event }) => event.data.methodSlug,
      }),
    },
    SUBMIT_PICKUP_DATA: {
      actions: assign({
        pickupData: ({ event }) => event.data.pickupData,
      }),
    },
    LOAD_NEARBY_LOCATIONS: { target: '.loadNearbyLocations' },
    SELECT_METHOD_DONE: { target: '.done' },
  },
});

export type ReturnMethodSubFlowActorRef = ActorRefFrom<typeof returnMethodSubFlow>;
export type ReturnMethodSubFlowSnapshot = SnapshotFrom<typeof returnMethodSubFlow>;
