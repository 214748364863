import { t } from 'i18next';
import React from 'react';

import { Box, Flex, Pressable, Text } from '@aftership/design-system';
import { ColorVars, RadiusVars, SpacingVars } from '@aftership/design-tokens';
import { PresentmentMoney } from '@aftership/returns-logics-core';

import { ImageWithFallback } from '@/components/ImageWithFallback';
import {
  RecommendProductsNowrapClassName,
  bodyContainersDesktopClassName,
  bodyContainersMobileClassName,
} from '@/features/resolution/components/RecommendProducts/RecommendProducts.css.ts';
import useDevice from '@/hooks/useDevice';
import { toCurrency } from '@/utils/price';

export interface RecommendProductItem {
  productId: string;
  productUrl: string;
  productCoverUrl?: string;
  productTitle: string;
  price: PresentmentMoney;
}
export interface RecommendProductsProps {
  items: RecommendProductItem[];
  onItemClick: (productId: string, productUrl: string) => void;
}

const RecommendProducts = ({ items, onItemClick }: RecommendProductsProps) => {
  const isMobile = useDevice().mobile;
  if (!items.length) {
    return <Box width={isMobile ? 100 : 172} height={isMobile ? 100 : 172} />;
  }
  const imageStyle = isMobile ? { autoFitWidth: true } : { width: 140, height: 140 };

  return (
    <Box className={isMobile ? bodyContainersMobileClassName : bodyContainersDesktopClassName}>
      {items.map((item) => {
        return (
          <Pressable
            key={item.productId}
            onPress={() => onItemClick(item.productId, item.productUrl)}
          >
            <Flex direction={'column'} gap={SpacingVars['2']} alignItems={'center'}>
              <Box borderRadius={RadiusVars['1']} overflow='hidden' width={'100%'}>
                <ImageWithFallback
                  {...imageStyle}
                  usingShopifyPreview
                  src={item.productCoverUrl || ''}
                  alt={item.productTitle}
                />
              </Box>
              <Flex
                width={'120px'}
                gap={isMobile ? 0 : SpacingVars['1']}
                alignItems={'center'}
                justifyContent={'center'}
                direction={isMobile ? 'column' : 'row'}
              >
                <Text
                  maxWidth={'96px'}
                  variant={'body3'}
                  className={RecommendProductsNowrapClassName}
                >
                  {toCurrency(item.price.amount, item.price.currency)}
                </Text>
                <Text
                  className={RecommendProductsNowrapClassName}
                  variant={'caption2'}
                  color={ColorVars.Grey['Grey 1100']}
                >
                  {t('page.description.afterCredit')}
                </Text>
              </Flex>
            </Flex>
          </Pressable>
        );
      })}
    </Box>
  );
};

export default RecommendProducts;
