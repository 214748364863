import { useToast } from '@aftership/design-system';
import { RequestErrorType } from '@aftership/returns-logics-core';

import FormWithOrderInfo from '@/features/gift-return/components/FormWithOrderInfo';
import FormWithoutOrderInfo from '@/features/gift-return/components/FormWithoutOrderInfo';
import OrderLookupInput from '@/features/gift-return/components/OrderLookupInput';
import Submmited from '@/features/gift-return/components/Submmited';
import { useGiftReturnCurrentStep } from '@/features/gift-return/hooks/useGiftReturnCurrentStep';
import { useHandleSubmitError } from '@/features/gift-return/hooks/useHandleSubmitError';
import { PageId } from '@/utils/tracker/consts';
import { useReportPageViewEvent } from '@/utils/tracker/useReportPageViewEvent';

export function GiftReturnPage() {
  const giftReturnCurrentStep = useGiftReturnCurrentStep();

  const { showToast } = useToast();

  const onError = (error: RequestErrorType) => {
    error.message && showToast(error.message, { type: 'error' });
  };

  useHandleSubmitError(onError);

  const isOrderLookupInput = giftReturnCurrentStep === 'orderLookupInput';
  const isSubmmited = giftReturnCurrentStep === 'submmited';
  const isFormWithOrderInfo = giftReturnCurrentStep === 'formWithOrderInfo';
  const isFormWithoutOrderInfo = giftReturnCurrentStep === 'formWithoutOrderInfo';

  useReportPageViewEvent(PageId.giftReturn);

  return (
    <>
      {isOrderLookupInput && <OrderLookupInput />}
      {isFormWithOrderInfo && <FormWithOrderInfo />}
      {isFormWithoutOrderInfo && <FormWithoutOrderInfo />}
      {isSubmmited && <Submmited />}
    </>
  );
}
