import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';

import { PickupInfomationData } from '@aftership/returns-logics-core';

import FormTextArea from '@/components/Form/FormTextArea';

import { SectionWrapper } from './SectionWrapper';

export const SpecialInstruction = () => {
  const { control } = useFormContext<PickupInfomationData>();
  return (
    <SectionWrapper title={t('pickup.instruction.title')}>
      <FormTextArea
        control={control}
        name='pickupInstructions'
        placeholder={t('pickup.instruction.placeholder')}
        showCounter
        maxLength={50}
      />
    </SectionWrapper>
  );
};
