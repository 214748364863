import React from 'react';

import { Box, Button, Flex, Spinner } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { Resolution } from '@aftership/returns-logics-core';

import { useGrayHideMode } from '@/features/return-detail/hooks/useGrayHideMode';
import useDevice from '@/hooks/useDevice';
import { useShopsAndEFASettings } from '@/hooks/useShopsAndEFASettings';

import useSummaryTotalUtil from '../../hooks/useSummaryTotalUtil';
import ContinueShopping from '../ContinueShopping';

const SubmitButtonGroup = ({
  isSubmitting,
  isDisabled,
  resolution,
  onSubmit,
}: {
  isSubmitting: boolean;
  isDisabled: boolean;
  resolution?: Resolution;
  onSubmit: () => void;
}) => {
  const isMobile = useDevice().mobile;
  const { submitButtonText } = useSummaryTotalUtil();
  const { isEfaInStore } = useShopsAndEFASettings();
  const { hideSummaryAlways, hideSummaryOnlyReplace } = useGrayHideMode({ resolution });
  const isShowEfaContinueShopping = resolution === Resolution.ExchangeForAnything && isEfaInStore;

  if (hideSummaryAlways && !isMobile) {
    return (
      <Flex
        direction='row'
        gap={SpacingVars['6']}
        justifyContent={isShowEfaContinueShopping ? 'start' : 'center'}
      >
        {isShowEfaContinueShopping && (
          <Box flex={1}>
            <ContinueShopping type='button' buttonVariant='secondary' />
          </Box>
        )}
        <Box
          flex={isShowEfaContinueShopping ? 1 : void 0}
          width={!isShowEfaContinueShopping ? 480 : void 0}
        >
          <Button isLoading={isSubmitting} size='large' isDisabled={isDisabled} onPress={onSubmit}>
            {isSubmitting ? <Spinner /> : submitButtonText}
          </Button>
        </Box>
      </Flex>
    );
  }

  if (hideSummaryOnlyReplace && !isMobile) {
    return (
      <Flex direction='row' gap={SpacingVars['6']} justifyContent='center'>
        <Box width={480}>
          <Button isLoading={isSubmitting} size='large' isDisabled={isDisabled} onPress={onSubmit}>
            {isSubmitting ? <Spinner /> : submitButtonText}
          </Button>
        </Box>
      </Flex>
    );
  }

  return (
    <Box paddingX={isMobile ? SpacingVars['4'] : 0}>
      <Flex direction='column' gap={SpacingVars['6']}>
        <Box width={hideSummaryOnlyReplace && !isMobile ? 480 : void 0}>
          <Button isLoading={isSubmitting} size='large' isDisabled={isDisabled} onPress={onSubmit}>
            {isSubmitting ? <Spinner /> : submitButtonText}
          </Button>
        </Box>
        {isShowEfaContinueShopping && <ContinueShopping />}
      </Flex>
    </Box>
  );
};

export default SubmitButtonGroup;
