import { IncomingMessage, ServerResponse } from 'http';
import { NextPageContext } from 'next/types';

import { Shops } from '@aftership/returns-logics-core';

import { rcAdminOrigin } from '@/features/iframe-integration/utils/whiteList';
import { queryParamsToPersist } from '@/features/resolution/utils/url.ts';
import { ReturnsPageAccessCode, ReturnsPageAccessStatus } from '@/features/returns/utils/constants';
import { pick as pickAcceptLanguage } from '@/i18n/utils';

export interface UseReturnsPageAccessProps {
  returnsPageAccess: string;
  pathname: string;
  shopInfo: Shops;
  isAppProxy: boolean;
}

/**
 * get shopify app_key
 * eq: xxx.myshopify.com
 * result: xxx
 *
 * @param shop string
 * @returns string
 */

export function getShopifyAppKey(shop = '') {
  return shop.split('.').reverse().slice(2).reverse().join('.');
}

export const getInitialLangByQuery = (shopInfo: Shops, queryLang: string) => {
  const { languages, default_language } = shopInfo;
  const supportedLang = languages?.find((v) => v === queryLang);

  return supportedLang || default_language || 'en-US';
};

export const PERSIST_QUERY_TO_COOKIES_PREFIX = 'persisted_query_';
export function getPersistQuery(ctx: NextPageContext, key: (typeof queryParamsToPersist)[number]) {
  const cookies = (ctx.req as any)?.cookies as Record<string, string>;

  return queryToString(ctx.query[key] || cookies[`${PERSIST_QUERY_TO_COOKIES_PREFIX}${key}`]);
}

// 因为每次回首页，都会触发 index.json 更新 getInitialProps 的时候，取不到 query
// 解决方案： 需要持久化的 query 存到 cookies 中，每次服务端渲染的时候，尝试从 query 和 cookies 中取
export function persistQueryToCookies() {
  if (typeof document === 'undefined') return;

  queryParamsToPersist.forEach((key) => {
    const value = new URLSearchParams(window.location.search).get(key);

    const cookieKey = `${PERSIST_QUERY_TO_COOKIES_PREFIX}${key}`;
    if (value) {
      // iframe third-party cookies need to set SameSite=None; Secure;
      document.cookie = `${cookieKey}=${encodeURIComponent(value)}; Path=/; SameSite=None; Secure;`;
    }
  });
}

export function clearPersistQueryCookiesFromDocument() {
  if (typeof document === 'undefined') return;

  queryParamsToPersist.forEach((key) => {
    const cookieKey = `${PERSIST_QUERY_TO_COOKIES_PREFIX}${key}`;
    document.cookie = `${cookieKey}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  });
}

export function clearPreviewData() {
  if (typeof document === 'undefined') return;
  // https://nextjs.org/docs/pages/building-your-application/configuring/preview-mode#clear-the-preview-mode-cookies
  navigator.sendBeacon('/api/clearPreviewData');
}

const efaPathRegExp = /^\/exchange\/(review|cart|products)(?:\/[^?#]*)?(?:\?.*)?$/;

export function getReturnsPageAccess({
  returnsPageAccess,
  pathname,
  shopInfo,
  isAppProxy,
}: UseReturnsPageAccessProps): Returns.ReturnsPageAccessResult {
  const { applied_features, returns_page_status, exchange_for_anything_active } = shopInfo;
  const { returns_page, app_proxy_iframe } = applied_features || {};
  const returnsPageFeatureAvailable = returns_page?.available;
  const appProxyFeatureAvailable = app_proxy_iframe?.available;
  const isReturnsPagePublished = returns_page_status === 'published';
  const accessFromAdmin = returnsPageAccess === 'granted';

  if (!returnsPageFeatureAvailable) {
    return {
      status: ReturnsPageAccessStatus.Denied,
      code: ReturnsPageAccessCode.ReturnsPageNotAvailable,
    };
  } else if (!isReturnsPagePublished && !accessFromAdmin) {
    return {
      status: ReturnsPageAccessStatus.Denied,
      code: ReturnsPageAccessCode.ReturnsPageNotPublished,
    };
  } else if (isAppProxy && !appProxyFeatureAvailable) {
    return {
      status: ReturnsPageAccessStatus.Denied,
      code: ReturnsPageAccessCode.AppProxyNotAvailable,
    };
  } else if (!exchange_for_anything_active && efaPathRegExp.test(pathname)) {
    return {
      status: ReturnsPageAccessStatus.Denied,
      code: ReturnsPageAccessCode.PageForbidden,
    };
  }

  return {
    status: ReturnsPageAccessStatus.Granted,
    code: null,
  };
}

export const getInitialLng = (acceptLanguage: string | string[], shopInfo: Shops | null) => {
  const defaultLang = shopInfo?.default_language || 'en-US';
  const configuredLang = shopInfo?.languages || [];
  const accept = pickAcceptLanguage([defaultLang, ...configuredLang], acceptLanguage, {
    loose: true,
  });

  return accept || defaultLang;
};

/**
 * 从 cookie 中获取 isAppProxy
 * @param viaNextCtx
 * @returns
 */
export const isAppProxy = (res?: ServerResponse<IncomingMessage>) => {
  try {
    const responseCookie = res?.getHeader('set-cookie');
    const reg = /(?<=isAppProxy=)true/;

    if (Array.isArray(responseCookie)) {
      return reg.test(responseCookie.join());
    } else {
      return !!responseCookie && reg.test(responseCookie.toString());
    }
  } catch (error) {
    // console.error('getIsAppProxy error', error);
  }
  return false;
};

export const queryToString = (param: string | string[] | undefined) => {
  if (typeof param === 'string') {
    return param;
  }
  return '';
};

/**
 * Inserts security headers into the server response.
 *
 * @param res - The server response object.
 * @param shopInfo - The shop information object.
 * @param isDev - Whether the environment is development.
 */
export const insertSecurityHeaders = (
  res: ServerResponse<IncomingMessage>,
  shopInfo?: Shops,
  isDev?: boolean,
) => {
  const whiteListForShop = shopInfo?.allowed_returns_page_iframe_domains || [];
  const frameAncestors = rcAdminOrigin.concat(whiteListForShop);
  isDev && frameAncestors.push('http://localhost:*');

  const securityHeaders = {
    'Content-Security-Policy': `frame-ancestors ${frameAncestors.join(' ')}`,
    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload',
    'X-Content-Type-Options': 'nosniff',
  };

  for (const [header, value] of Object.entries(securityHeaders)) {
    res.setHeader(header, value);
  }
};
