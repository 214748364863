import { useFlow } from 'returns-logics/react';

export const useRequestReturnsInfo = () => {
  const flow = useFlow();
  const { context: mainContext, currentStep: mainCurrentStep, children: mainChildren } = flow;
  const policyExternalLink = mainContext?.storeConfig?.shopInfo?.policy_url;
  const policyEnable = mainContext?.storeConfig?.shopInfo?.external_return_policy_page ?? false;
  const isMerchantMode = !!mainContext?.orderLookup?.isMerchantMode;
  const itemSelectionSubFlowSnapshot = mainChildren?.itemSelectionSubFlow?.snapshot.context;
  const {
    dispatch,
    currentStep,
    context: itemSelectionContext,
  } = mainChildren?.itemSelectionSubFlow ?? {};
  const isReturnCare = itemSelectionContext?.orderInfo?.return_care?.eligible ?? false;
  return {
    mainCurrentStep,
    itemSelectionCurrentStep: currentStep,
    itemSelectionDispatch: dispatch,
    policyExternalLink,
    policyEnable,
    isMerchantMode,
    isReturnCare,
    orderIneligibility: mainContext.request?.orders?.order_ineligibilities,
    itemSelectionSubFlowSnapshot,
  };
};
