'use client';

import * as Sentry from '@sentry/nextjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useToast } from '@aftership/design-system';
import { IMainFlowInput } from '@aftership/returns-logics-core';
import { MainFlowProvider } from 'returns-logics/react';

import type getInitialProps from '@/features/returns/resources/getInitialProps';

type ReturnsContextValue = PromiseType<ReturnType<typeof getInitialProps>>['initialProps'];

// todo 优化这里,避免重复触发
// const handleErrorForJWT = (shopHostName: string, proxyPathPrefix?: string) => {
//   const homePath = proxyPathPrefix || '/';
//
//   if (window.location.pathname !== homePath) {
//     console.log('handleJWTError: ');
//     setTimeout(() => {
//       location.replace(
//         `${proxyPathPrefix || '/'}${shopHostName ? `?hostname=${shopHostName}` : ''}`,
//       );
//     }, 2000);
//   }
// };

const ReturnsLogicProvider = ({
  children,
  initialProps,
  isPreview,
}: {
  children: React.ReactNode;
  initialProps: ReturnsContextValue;
  isPreview: boolean;
}) => {
  const { t } = useTranslation();
  const initialInput: IMainFlowInput = useMemo(() => {
    return {
      storeConfig: {
        shopInfo: initialProps.shopInfo,
        giftReturnSetting: initialProps.giftReturnSetting,
        grayFeatureEnabledMap: initialProps.grayFeatureEnabledMap,
      },
    };
  }, [initialProps]);
  const { showToast } = useToast();
  return (
    <MainFlowProvider
      initialInput={initialInput}
      APP_ENV={initialProps.APP_ENV}
      isPreview={isPreview}
      handleJwtError={() => {
        showToast(t('page.error.yourSessionExpired'), { type: 'error' });
      }}
      handleXStateError={(error) => {
        Sentry.captureException(error);
      }}
    >
      {children}
    </MainFlowProvider>
  );
};

export default ReturnsLogicProvider;
