import { useTranslation } from 'react-i18next';

import {
  DropoffStatus,
  ReturnMethodSlug,
  ReturnStatus,
  ShippingStatus,
} from '@aftership/returns-logics-core';

export function useReturnTitle({
  returnStatus,
  shippingStatus,
  returnMethodSlug,
  dropoffStatus,
}: {
  returnStatus?: ReturnStatus;
  shippingStatus?: ShippingStatus;
  returnMethodSlug?: ReturnMethodSlug;
  dropoffStatus?: DropoffStatus;
}): string {
  const { t } = useTranslation();

  switch (returnStatus) {
    case ReturnStatus.SUBMITTED:
      return t('status.description.pendingStatus');
    case ReturnStatus.APPROVED: {
      if (
        shippingStatus === ShippingStatus.Pending ||
        shippingStatus === ShippingStatus.NoPrepaidLabel
      ) {
        return t('status.description.approvedStatus');
      }
      switch (returnMethodSlug) {
        case ReturnMethodSlug.HappyReturns: {
          if (
            dropoffStatus === DropoffStatus.Created ||
            dropoffStatus === DropoffStatus.Dropped ||
            dropoffStatus === DropoffStatus.PartiallyDropped
          ) {
            return t('page.happyReturn.status.generated');
          }
          return t('page.happyReturn.status.generating');
        }
        case ReturnMethodSlug.RetailRework: {
          if (
            dropoffStatus === DropoffStatus.Created ||
            dropoffStatus === DropoffStatus.Dropped ||
            dropoffStatus === DropoffStatus.PartiallyDropped
          ) {
            return t('page.retailRework.status.generated');
          }
          return t('page.retailRework.status.generating');
        }
        case ReturnMethodSlug.RetailerLabel: {
          if (shippingStatus === ShippingStatus.Received) {
            return t('status.description.receivedStatus');
          }
          return t('status.description.shippedStatus');
        }
        case ReturnMethodSlug.GreenReturn:
          return t('status.description.approvedStatusGreenReturn');
      }
      return t('status.description.approvedStatus');
    }
    case ReturnStatus.SHIPPED:
      return t('status.description.shippedStatus');
    case ReturnStatus.RECEIVED:
      return t('status.description.receivedStatus');
    case ReturnStatus.DONE:
      return t('status.description.doneStatus');
    case ReturnStatus.REJECTED:
      return t('status.description.rejectedStatus');
    case ReturnStatus.EXPIRED:
      return t('status.description.expiredStatus');
    default:
      return '';
  }
}
