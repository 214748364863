import { useTranslation } from 'react-i18next';

import { Box, Flex, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { SelectSingleItemSubFlowSnapshot } from '@aftership/returns-logics-core';

import { generateTitle } from '@/features/request-returns/utils/returnInfoModalHelper.ts';
import useDevice from '@/hooks/useDevice.ts';

export interface FillReturnTitleProps {
  isSelectQuestionAnswer: Boolean;
  stepName: SelectSingleItemSubFlowSnapshot['value'];
  questionId?: string;
  questionTitle?: string;
}
const FillReturnTitle = ({
  isSelectQuestionAnswer,
  stepName,
  questionId,
  questionTitle,
}: FillReturnTitleProps) => {
  const isMobile = useDevice().mobile;
  const { t } = useTranslation();
  const paddingX = isMobile ? SpacingVars['4'] : 0;
  const textAlign = isMobile ? 'center' : 'left';
  const variant = isMobile ? 'title2' : 'title3';

  return (
    <Box paddingX={paddingX}>
      <Flex direction={'column'} gap={SpacingVars['2']}>
        {isSelectQuestionAnswer && (
          <Text variant={'body1'} color={ColorVars.Grey['Grey1200']} textAlign={textAlign}>
            {t('popup.description.answerQuestion')}
          </Text>
        )}
        <Text variant={variant} color={ColorVars.Grey['Grey1200']} textAlign={textAlign}>
          {`${generateTitle(stepName, questionId, questionTitle)}`}
        </Text>
      </Flex>
    </Box>
  );
};

export default FillReturnTitle;
