import { PreviewExchangeItem } from '@aftership/returns-logics-core';

export enum ExchangeEditActionType {
  UpdateItem = 'UPDATE_INTENTION_DATA',
  RemoveItem = 'REMOVE_INTENTION_DATA',
  Init = 'INIT_INTENTION_DATA',
}

interface UpdateActionData {
  variantId: string;
  value: number;
}
interface InitActionData {
  items?: PreviewExchangeItem[];
}

interface ActionData {
  variantId: string;
  value?: number;
}

interface Action {
  type: ExchangeEditActionType;
  data?: ActionData;
  init?: InitActionData;
}

function isUpdateItemAction(
  action: Action,
): action is { type: ExchangeEditActionType.UpdateItem; data: UpdateActionData } {
  return action.type === ExchangeEditActionType.UpdateItem && !!action?.data?.value;
}

export function formatExchangeItem(exchangeItem: PreviewExchangeItem) {
  return {
    external_product_id: exchangeItem.productId,
    external_variant_id: exchangeItem.variantId,
    quantity: exchangeItem.quantity,
  };
}

export function formatExchangeItems(exchangeItems: PreviewExchangeItem[]) {
  return exchangeItems?.map(formatExchangeItem) ?? [];
}

export function editExchangeItemReducer(state: PreviewExchangeItem[], action: Action) {
  switch (action.type) {
    case ExchangeEditActionType.UpdateItem: {
      const currentAction = isUpdateItemAction(action) ? action : null;
      return currentAction
        ? [
            ...(state?.map((exchangeItem) => {
              if (exchangeItem.variantId === currentAction.data.variantId) {
                return {
                  ...exchangeItem,
                  quantity: currentAction.data.value,
                };
              }
              return exchangeItem;
            }) ?? []),
          ]
        : state;
    }

    case ExchangeEditActionType.RemoveItem: {
      return [
        ...(state?.filter((exchangeItem) => exchangeItem.variantId !== action?.data?.variantId) ??
          []),
      ];
    }
    case ExchangeEditActionType.Init: {
      return action.init?.items ?? [];
    }

    default:
      return state;
  }
}
