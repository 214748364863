import { ConditionType } from '../promise';
import { NonReturnableOrderItem, NonReturnableReason } from '../types';

/**
 * item 是否因为 ask a question 的原因 block
 * @param item
 */
const isBlockByQuestion = (item: NonReturnableOrderItem) =>
  item.reasons
    .map((item) => item.reason)
    .includes(ConditionType.OrderItemId as unknown as NonReturnableReason);
/**
 * 排序,将 ask a question 的 order item 放前面
 * @param a
 * @param b
 */
export const sortNonReturnableOrderItemFunction = (
  a: NonReturnableOrderItem,
  b: NonReturnableOrderItem,
) => {
  const isBlockA = isBlockByQuestion(a);
  const isBlockB = isBlockByQuestion(b);

  if (isBlockA && isBlockB) {
    return 0;
  }

  if (isBlockA) {
    return -1;
  }
  return 1;
};
