import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';

import { Flex } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { PickupInfomationData } from '@aftership/returns-logics-core';

import FormInput from '@/components/Form/FormInput';

import { SectionWrapper } from './SectionWrapper';

export const PickupContactContent = () => {
  const { control } = useFormContext<PickupInfomationData>();

  return (
    <Flex direction='column' gap={SpacingVars['4']}>
      <Flex gap={SpacingVars['2']}>
        <FormInput
          fullWidth
          control={control}
          name='pickupContact.firstName'
          placeholder={t('first_name.optional')}
        />
        <FormInput
          fullWidth
          control={control}
          name='pickupContact.lastName'
          placeholder={t('page.gift.placeholder.lastName')}
        />
      </Flex>
      <FormInput
        fullWidth
        control={control}
        name='pickupContact.phoneNumber'
        placeholder={t('page.gift.placeholder.phoneNumber')}
      />
    </Flex>
  );
};

export const PickupContactSection = () => {
  return (
    <SectionWrapper title='Contact'>
      <PickupContactContent />
    </SectionWrapper>
  );
};
