import React from 'react';

import { Flex, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { PresentmentMoney } from '@aftership/returns-logics-core';

import { ImageWithFallback } from '@/components/ImageWithFallback';
import {
  productTitleStyle,
  singleLineMobileStyle,
} from '@/features/request-returns/components/ReturnItemInfoCard/styles.css.ts';
import useDevice from '@/hooks/useDevice';
import { generatePriceWithQuantityString } from '@/utils/price.ts';

export interface ReturnItemInfoCardProps {
  productTitle: string;
  selectedQuantity?: number;
  productCoverUrl?: string;
  variantTitle?: string;
  price?: PresentmentMoney;
}

const ReturnItemInfoCard = ({
  productTitle,
  variantTitle,
  price,
  productCoverUrl,
  selectedQuantity,
}: ReturnItemInfoCardProps) => {
  const isMobile = useDevice().mobile;

  if (isMobile) {
    return (
      <Flex gap={SpacingVars['2']} alignItems={'center'} direction={'column'}>
        <ImageWithFallback
          usingShopifyPreview
          width={'100'}
          height={'100'}
          src={productCoverUrl || ''}
          alt={productTitle}
        />

        <Flex direction={'column'} gap={SpacingVars['1']} width={'100%'}>
          <Text
            variant={'subtitle1'}
            color={ColorVars.Grey['Grey1200']}
            className={singleLineMobileStyle}
            textAlign={'center'}
          >
            {productTitle}
          </Text>
          {variantTitle && (
            <Text
              variant={'body2'}
              className={singleLineMobileStyle}
              color={ColorVars.Grey['Grey 1100']}
              textAlign={'center'}
            >
              {variantTitle}
            </Text>
          )}
          <Text variant={'body2'} color={ColorVars.Grey['Grey1200']} textAlign={'center'}>
            {generatePriceWithQuantityString(price, selectedQuantity)}
          </Text>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex height={'100%'} width={'100%'} justifyContent={'center'}>
      <Flex
        direction={'column'}
        gap={SpacingVars['6']}
        justifyContent={'center'}
        height={'100%'}
        alignItems={'center'}
        width={'200px'}
      >
        <ImageWithFallback
          usingShopifyPreview
          width={'200'}
          height={'200'}
          src={productCoverUrl || ''}
          alt={productTitle}
        />
        <Flex direction={'column'} gap={SpacingVars['2']} width={'100%'}>
          <Flex direction={'column'}>
            <Text
              variant={'subtitle1'}
              color={ColorVars.Grey['Grey1200']}
              className={productTitleStyle}
            >
              {productTitle}
            </Text>
            {variantTitle && (
              <Text variant={'body2'} color={ColorVars.Grey['Grey 1100']}>
                {variantTitle}
              </Text>
            )}
          </Flex>
          <Text variant={'body2'} color={ColorVars.Grey['Grey1200']}>
            {generatePriceWithQuantityString(price, selectedQuantity)}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ReturnItemInfoCard;
