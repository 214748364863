'use client';

import { get } from 'lodash-es';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Flex, Text, TextArea, TextAreaProps } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { InputFieldErrorType } from '@aftership/returns-logics-core';

import { IErrorItem } from '@/types/error';

export type Props = TextAreaProps & {
  control: Control<any>;
  name: string;
  placeholder?: string;

  showError?: boolean;
  errorMapping?: Record<string, IErrorItem>;
  errors?: Record<string, InputFieldErrorType>;
  onInputChange?: (value: string, name: string) => void;
};

function FormTextArea({
  control,
  name,
  placeholder,
  errorMapping,
  errors,
  onInputChange,
  ...rest
}: Props) {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, onChange, value }, formState }) => {
        const error = errors?.[name];

        const msg =
          error && errorMapping?.[name]
            ? t(errorMapping?.[name]?.[error['code']])
            : get(formState?.errors, name)?.message;

        return (
          <Flex direction='column' gap={SpacingVars['1']}>
            <TextArea
              placeholder={placeholder}
              value={value}
              onChange={(v) => {
                onChange(v);
                onInputChange?.(v, name);
              }}
              onBlur={onBlur}
              {...rest}
            />
            {msg && (
              <Text variant='body2' color={ColorVars['Red']['Red 1000']}>
                {msg}
              </Text>
            )}
          </Flex>
        );
      }}
    />
  );
}

export default FormTextArea;
