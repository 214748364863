import {
  EfaMethod,
  OrderLookupType,
  Resolution,
  ReturnMethodSlug,
} from '@aftership/returns-logics-core';

export enum PageType {
  shopper = 'shopper',
  appProxy = 'appProxy',
  iframe = 'iframe',
}

export enum DeviceType {
  desktop = 'desktop',
  mobile = 'mobile',
}

// 基础、不会变的信息
export type BasicInfo = {
  trace_id: string; // 传入 jwt 中的 uuid --> 页面刷新的时候，trace id 会不会变
  org_id: string; // shopInfo 中的 user_uuid
  order_id: string; // orderInfo 中的 order_id
  platform?: string; // order.app.platform
  store?: string; // order.app.key
  page_type?: PageType; // 页面类型
  device?: DeviceType; // 设备类型
  screen_resolution?: string; // 设备分辨率
};

export enum PageId {
  orderLookup = 'order_lookup',
  returnList = 'return_list',
  itemSelection = 'item_selection',
  returnDetail = 'return_detail',
  resolutionSelection = 'resolution_selection',
  refundResolutionSelection = 'refund_resolution_selection',
  replace = 'replace',
  returnMethodSelection = 'return_method_selection',
  EFAInApp = 'EFA_in_app',
  EFAOnStore = 'EFA_on_store',
  giftReturn = 'gift_return',
  review = 'review',
  appProxyOrderList = 'app_proxy_order_list',
}

export enum EventName {
  pageEnter = 'page_enter',
  pageLeave = 'page_leave',
  findOrder = 'click:find_order',
  selectItem = 'click:select_item',
  removeSelectedItem = 'click:removed_selected_item',
  selectReturnReason = 'click:select_return_reason',
  selectReturnSubReason = 'click:select_return_sub_reason',
  selectResolution = 'click:select_resolution',
  selectReturnMethod = 'click:select_return_method',
  submitReturn = 'click:submit_return',
  continueShopping = 'click:continue_shopping', // 点击 continue shopping
  clickEFAOrRefund = 'click:click_EFA_or_refund', // EFA 营销 modal，选择 EFA 或者 Refund
  clickCreateReturnInReturnList = 'click:click_create_return_in_return_list', // return list 点击创建 return
  clickGoToGiftReturn = 'click:go_to_gift_return',
  clickGiftReturnNotMatch = 'click:gift_return_no_information',
  clickSelectReplaceVariant = 'click:select_replace_variant',

  // next button
  clickItemSelectionPageNext = 'click:item_selection_next',
  clickResolutionPageNext = 'click:resolution_page_next',
  clickRefundResolutionNext = 'click:refund_resolution_next',
  clickReturnMethodNext = 'click:return_method_next',
  clickReplacePageNext = 'click:select_replace_page_next',
}

export type PageEnterPayload = {
  pageId: PageId; // 当前页
  previousPageId?: PageId | null; // 上一页
};

export type PageLeavePayload = {
  pageId: PageId; // 当前页
};

export type PageEvent =
  | {
      eventName: EventName.pageEnter;
      payload: PageEnterPayload;
    }
  | {
      eventName: EventName.pageLeave;
      payload: PageLeavePayload;
    };

// 查找订单
export type FindOrderEvent = {
  eventName: EventName.findOrder;
  payload: {
    orderLookupType: OrderLookupType; // 查找订单的方式
    source: 'main_page_click' | 'auto' | 'order_item_click';
  };
};

// 选择 resolution
export type SelectResolution = {
  eventName: EventName.selectResolution;
  payload: {
    resolution?: Resolution; // 选择的 resolution
  };
};

// EFA 营销 modal，选择 EFA 或者 Refund
export type ClickEFAOrRefund = {
  eventName: EventName.clickEFAOrRefund;
  payload:
    | { choice: 'refund' }
    | { choice: 'shop_now' | 'EFA_product_image'; efaMethod: EfaMethod };
};

// 选择 return method
export type SelectReturnMethod = {
  eventName: EventName.selectReturnMethod;
  payload: {
    returnMethod: ReturnMethodSlug; // 选择的 return method
  };
};

export type SubmitReturn = {
  eventName: EventName.submitReturn;
  payload: {
    efaMethod: EfaMethod; // 选择的 EFA 方式
    resolution?: Resolution; // 选择的 resolution
  };
};

export type ClickEvent =
  | FindOrderEvent
  | SelectResolution
  | SelectReturnMethod
  | ClickEFAOrRefund
  | SubmitReturn
  | {
      eventName: Omit<
        EventName,
        | EventName.findOrder
        | EventName.selectResolution
        | EventName.clickEFAOrRefund
        | EventName.selectReturnMethod
        | EventName.submitReturn
      >;
      payload?: null;
    };

export type ExtraData = BasicInfo & (PageEvent | ClickEvent);
