import { PresentmentMoney } from '@aftership/returns-logics-core';
import { useFlow } from 'returns-logics/react';

import { DefaultPrice } from '@/features/resolution/consts.ts';
import { useFieldAWithMemo } from '@/hooks/useFieldAWithMemo.ts';
import { toCurrency } from '@/utils/price.ts';

export const useMemoExchangeOrRefundContext = () => {
  const { currentStep, children } = useFlow();
  const contextMemory = useFieldAWithMemo(
    children?.resolutionSubFlow?.children?.exchangeOrRefundSubFlow?.snapshot?.context,
  );

  const selectedItemsWithCreditAmount =
    contextMemory?.priceInfo?.selectedItemsWithCreditAmount || DefaultPrice;
  const selectedItemsAmount = contextMemory?.priceInfo?.selectedItemsAmount || DefaultPrice;
  const preDiscountCreditAmount = contextMemory?.priceInfo?.preDiscountCreditAmount || DefaultPrice;
  const extraCreditAmount = contextMemory?.priceInfo?.extraCreditAmount || DefaultPrice;

  const recommendProductItems =
    contextMemory?.products?.map((product) => {
      const originalPrice = Number(
        product.lowest_price_variant.price_set.presentment_money?.amount ?? 0,
      );
      const credit = Number(selectedItemsWithCreditAmount.amount);
      // 计算减去 returnItem + totalCredit 之后的价格,如果为负数,则设为 0
      const overridePrice: PresentmentMoney = {
        amount: originalPrice - credit > 0 ? `${originalPrice - credit}` : '0.00',
        currency:
          product.lowest_price_variant.price_set.presentment_money?.currency ??
          selectedItemsWithCreditAmount?.currency,
      };
      return {
        productUrl: product.product_url,
        productId: product.external_id,
        productTitle: product.title,
        productCoverUrl: product.image_urls[0],
        price: overridePrice,
      };
    }) ?? [];

  return {
    isJumpingEfaOnStore:
      children?.resolutionSubFlow?.currentStep?.name === 'beforeConfirmResolution' ||
      currentStep?.name === 'exchangeOnStore' ||
      currentStep?.name === 'beforeExchangeInStore',
    products: recommendProductItems,
    selectedItemsWithCreditAmountString: `${toCurrency(
      selectedItemsWithCreditAmount.amount,
      selectedItemsWithCreditAmount.currency,
      true,
    )}`,
    selectedItemsAmount,
    preDiscountCreditAmount,
    extraCreditAmount,
  };
};
