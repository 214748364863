import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Box, Text } from '@aftership/design-system';
import { ColorVars } from '@aftership/design-tokens';
import { AmountPrice } from '@aftership/returns-logics-core';

import useDevice from '@/hooks/useDevice';
import { toCurrency } from '@/utils/price';

import { deststopExtraBonusStyle, extraBonusStyle } from './index.css';

type StoreCreditIncentiveTagProps = {
  storeCreditIncentive?: AmountPrice | null;
};

export const StoreCreditIncentiveTag = ({ storeCreditIncentive }: StoreCreditIncentiveTagProps) => {
  const { t } = useTranslation();

  const isMobile = useDevice().mobile;

  if (!storeCreditIncentive?.amount || !storeCreditIncentive?.currency) return null;

  return (
    <Box
      className={clsx(extraBonusStyle, {
        [deststopExtraBonusStyle]: !isMobile,
      })}
    >
      <Text variant='body2' color={ColorVars['Orange']['Orange 1200']}>
        {t('bonus.included', {
          price: toCurrency(storeCreditIncentive?.amount ?? '', storeCreditIncentive?.currency),
        })}
      </Text>
    </Box>
  );
};
