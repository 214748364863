import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Flex, Pressable, Spinner, Text, useToast } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { ErrorCode, GrayFeatureKey } from '@aftership/returns-logics-core';

import { EllipsisText } from '@/components/EllipsisText';
import { ScrollBox } from '@/components/ScrollBox';
import { StepCard } from '@/components/StepCard';
import { ReturnItem } from '@/features/returns-list/components/ReturnItem';
import useDevice from '@/hooks/useDevice';
import useGetGrayFeatureEnabled from '@/hooks/useGetGrayFeatureEnabled';
import { useMainFlow } from '@/hooks/useMainFlow';
import { useStepCardMinHeight } from '@/hooks/useStepCardMinHeight';
import getTrackerInstance from '@/utils/tracker';
import { EventName, PageId } from '@/utils/tracker/consts';
import { useReportPageViewEvent } from '@/utils/tracker/useReportPageViewEvent';

import { DraftReturnItem } from './components/DraftReturnItem';
import { useReturnsListSubFlow } from './hooks/useReturnsListSubFlow';
import { createReturnClassName } from './index.css';

import { useUniversalRouting } from '../returns/hooks/useUniversalRouting';

export default function ReturnsList() {
  const isMobile = useDevice().mobile;
  const router = useUniversalRouting();
  const toast = useToast();
  const mainFlow = useMainFlow();
  const returnListSubFlow = useReturnsListSubFlow();
  const { t } = useTranslation();
  const { context, currentStep } = returnListSubFlow || {};
  const {
    returns = [],
    draftReturns = [],
    orderName,
    orderNumber,
    createTime,
    deleteDraftReturnError,
  } = context || {};

  const minHeight = useStepCardMinHeight();
  const showExternalOrder = useGetGrayFeatureEnabled(GrayFeatureKey.ShowExternalOrder);
  const displayOrderNumberOrName = useMemo(
    () => (showExternalOrder ? orderName : orderNumber),
    [showExternalOrder, orderName, orderNumber],
  );

  const handleCreate = () => {
    mainFlow.dispatch?.({ type: 'CREATE_RETURN' });
    getTrackerInstance().reportClickEvent({
      eventName: EventName.clickCreateReturnInReturnList,
    });
  };

  useReportPageViewEvent(PageId.returnList);

  useEffect(() => {
    router.beforePopState(() => {
      mainFlow.dispatch?.({ type: 'GO_TO_ORDER_LOOKUP' });
      return false;
    });
    return () => router.beforePopState(() => true);
  }, [mainFlow, router]);

  // 删除仅有一个点 draft return 时，需要跳转至 item selection
  // 这里 memo 了 returnsList 的 flow 数据，所以这里需要拿 mainFlow 的 currentStep.name 判断
  useEffect(() => {
    if (
      mainFlow.currentStep.name === 'returnsList' &&
      !currentStep?.isLoading &&
      returns.length <= 0 &&
      draftReturns.length <= 0
    ) {
      mainFlow.dispatch({ type: 'CREATE_RETURN' });
    }
  }, [currentStep, draftReturns.length, mainFlow, returns.length, router]);

  useEffect(() => {
    if (deleteDraftReturnError) {
      deleteDraftReturnError.code === ErrorCode.CancelConfirmedDraftReturn &&
        toast.error(t('v2.toast.cancel_confirmed_draft_return'));
      returnListSubFlow?.dispatch?.({
        type: 'RESET_ERROR',
        data: { type: 'deleteDraftReturnError' },
      });
    }
  }, [deleteDraftReturnError, returnListSubFlow, t, toast]);

  if (isMobile) {
    return (
      <Box flex={1} height={0} backgroundColor={ColorVars['B&W'].Background}>
        <ScrollBox height='100%' paddingX={SpacingVars[4]} offsetY={SpacingVars[10]}>
          <Flex minHeight='100%' direction='column' gap={SpacingVars[8]}>
            <Flex
              paddingX={isMobile ? 0 : SpacingVars['4']}
              alignItems='center'
              paddingTop={SpacingVars[8]}
              gap={SpacingVars[5]}
            >
              <Flex direction='column'>
                <EllipsisText
                  variant='title3'
                  color={ColorVars['Grey']['Grey1200']}
                  text={`${t('page.details.order')} ${displayOrderNumberOrName}`}
                />
                <Text variant='caption1' color={ColorVars['Grey']['Grey 1100']}>
                  {dayjs(createTime).format(
                    t('v2.date_formate.at', { date: 'MMM DD, YYYY', time: 'hh:mm a' }),
                  )}
                </Text>
              </Flex>
              <Box flex={1} />
              <Box flexShrink={0}>
                <Button variant='secondary' onPress={handleCreate}>
                  {t('page.request.create')}
                </Button>
              </Box>
            </Flex>
            {currentStep?.isLoading && draftReturns.length <= 0 && returns.length <= 0 ? (
              <Flex flex={1} alignItems='center' justifyContent='center'>
                <Spinner />
              </Flex>
            ) : (
              <Flex direction='column' gap={SpacingVars[5]} paddingBottom={SpacingVars[8]}>
                {draftReturns.map((item) => (
                  <DraftReturnItem key={item.id} item={item} />
                ))}
                {returns.map((item) => (
                  <ReturnItem key={item.id} item={item} />
                ))}
              </Flex>
            )}
          </Flex>
        </ScrollBox>
      </Box>
    );
  }

  return (
    <StepCard
      width={800}
      minHeight={minHeight}
      headerContainerAlignItems='start'
      title={
        <Flex direction='column' alignItems='center'>
          <Text variant='title3' color={ColorVars['Grey']['Grey1200']}>
            {t('page.details.order')} {displayOrderNumberOrName}
          </Text>
          <Text variant='caption1' color={ColorVars['Grey']['Grey 1100']}>
            {dayjs(createTime).format(
              t('v2.date_formate.at', { date: 'MMM DD, YYYY', time: 'hh:mm a' }),
            )}
          </Text>
        </Flex>
      }
      rightSlot={
        <Pressable onPress={handleCreate}>
          <Text
            position='relative'
            className={createReturnClassName}
            variant='title3'
            color={ColorVars['Grey']['Grey1200']}
          >
            {t('page.request.create')}
          </Text>
        </Pressable>
      }
      onBack={() => {
        mainFlow.dispatch?.({ type: 'GO_TO_ORDER_LOOKUP' });
      }}
    >
      {currentStep?.isLoading && draftReturns.length <= 0 && returns.length <= 0 ? (
        <Flex flex={1} alignItems='center' justifyContent='center'>
          <Spinner />
        </Flex>
      ) : (
        <Flex
          direction='column'
          gap={SpacingVars[5]}
          marginTop={SpacingVars[5]}
          paddingX={SpacingVars[10]}
          paddingBottom={SpacingVars[10]}
        >
          {draftReturns.map((item) => (
            <DraftReturnItem key={item.id} item={item} />
          ))}
          {returns.map((item) => (
            <ReturnItem key={item.id} item={item} />
          ))}
        </Flex>
      )}
    </StepCard>
  );
}
