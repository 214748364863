import { t } from 'i18next';
import React from 'react';

import {
  Box,
  Button,
  Flex,
  ListBox,
  ListBoxItem,
  Pressable,
  Text,
  useToast,
} from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { SelectionGroupingItem } from '@aftership/returns-logics-core';

import { ItemCardContent } from '@/components/ItemCardContent';
import { lineItemBoxStyle } from '@/features/request-returns/components/ReturnableItem/styles.css.ts';
import { SelectedItem } from '@/features/request-returns/types';
import { queryReturnItemStatus } from '@/features/request-returns/utils/returnableItemHelper';
import useDevice from '@/hooks/useDevice.ts';

import { ReturnItemRightContent } from '../ReturnItemRightContent';
import { SelectedReturnInfoBanner } from '../SelectedReturnInfoBanner';
import WarningTips from '../WarningTips/WarningTips.tsx';

export interface Props {
  /**
   * 商品的基础信息
   */
  returnableItem: RequestReturn.ReturnableItemProps;
  /**
   * 选中的 item  + line item 列表
   */
  selectedItems: Array<SelectedItem>;
  /**
   * 所有 item  + line item 可退的组合
   */
  itemSelectionGrouping: Array<SelectionGroupingItem>;
  /**
   * 是否限制每次退货只能退一种商品
   */
  isLimitSingleItemPerReturn: boolean;
  /**
   * 是否开启了可选 item 受 routing rule 影响
   */
  isLimitSelectionByRoutingRule: boolean;
  onEdit: (itemId: string, parentId: Maybe<string>) => void;
  onRemove: (itemId: string, parentId: Maybe<string>) => void;
  /**
   * 展开中的 id
   */
  expandId: string;
  /**
   * warning 的提示文案
   */
  warningTips?: string;
  onExpand?: (itemId: string) => void;
}

const ReturnableItem = ({
  returnableItem,
  selectedItems,
  itemSelectionGrouping,
  isLimitSelectionByRoutingRule,
  isLimitSingleItemPerReturn,
  onRemove,
  onEdit,
  expandId,
  onExpand,
}: Props) => {
  const {
    returnInfo,
    isSelected,
    cantSelectedByLimit,
    canWholeReturn,
    cantSelectedByRoutingRule,
    expanded,
    expandability,
  } = queryReturnItemStatus(
    returnableItem,
    selectedItems,
    itemSelectionGrouping,
    expandId,
    isLimitSingleItemPerReturn,
  );
  const { showToast } = useToast();
  const {
    itemId,
    parentId,
    productTitle,
    productCoverUrl,
    variantTitle,
    returnableQuantity,
    price,
    originPrice,
    productTags,
    bundles,
    overridingReason,
  } = returnableItem;
  const isMobile = useDevice().mobile;

  const footer = () => {
    if (returnInfo) {
      return (
        <SelectedReturnInfoBanner
          selectedCount={returnInfo.returnedQuantity}
          onEdit={() => onEdit(itemId, parentId)}
        />
      );
    } else if (expanded && bundles) {
      const selectedKeys = selectedItems.map((item) => item.itemId);
      return (
        <Flex marginTop={SpacingVars['2']} direction={'column'} gap={SpacingVars['4']}>
          <ListBox
            rowGap={SpacingVars['3']}
            selectionMode='single'
            items={bundles.map((item) => ({
              id: item.itemId,
              ...item,
            }))}
            selectedKeys={selectedKeys}
          >
            {(item) => {
              return (
                <ListBoxItem className={lineItemBoxStyle}>
                  {() => {
                    return (
                      <ReturnableItem
                        key={item.itemId}
                        // line item 不存在展开的情况
                        expandId={''}
                        returnableItem={item}
                        selectedItems={selectedItems}
                        itemSelectionGrouping={itemSelectionGrouping}
                        onRemove={onRemove}
                        onEdit={onEdit}
                        isLimitSelectionByRoutingRule={isLimitSelectionByRoutingRule}
                        isLimitSingleItemPerReturn={isLimitSingleItemPerReturn}
                      />
                    );
                  }}
                </ListBoxItem>
              );
            }}
          </ListBox>

          {canWholeReturn && (
            <Box width={'auto'} paddingX={SpacingVars ? 0 : '134px'}>
              <Button
                variant={'secondary'}
                size={'large'}
                onPress={() => {
                  onEdit?.(itemId, parentId);
                }}
              >
                {t('page.description.ReturnBundleAction')}
              </Button>
            </Box>
          )}
        </Flex>
      );
    } else if (cantSelectedByRoutingRule || cantSelectedByLimit) {
      return (
        <WarningTips
          tips={t('page.description.limitReturn.separate')}
          marginTop={isMobile ? 0 : SpacingVars['1']}
        />
      );
    }
    return null;
  };

  return (
    <Pressable
      width={'100%'}
      onPress={() => {
        if (cantSelectedByLimit || cantSelectedByRoutingRule) {
          // 如果不可选,需要提醒
          if (!expanded) {
            showToast(t('page.description.limitReturnWarn'));
          }
        } else if (expandability) {
          onExpand?.(itemId);
        } else if (!expanded) {
          onEdit(itemId, parentId);
        }
      }}
    >
      <Box padding={SpacingVars['4']} width={'100%'}>
        <Flex direction={'column'} gap={SpacingVars[isMobile ? '4' : '2']}>
          {/* Content */}
          <div
            style={{
              opacity: cantSelectedByRoutingRule || cantSelectedByLimit ? '0.5' : '1',
            }}
          >
            <ItemCardContent
              productTitleVariant={'body1'}
              productInfo={{
                productTitle: productTitle,
                variantTitle: variantTitle,
                productTags: productTags,
                price: price,
                quantity: returnableQuantity,
                originPrice: originPrice,
                productCoverUrl: productCoverUrl,
              }}
              rightContent={
                <ReturnItemRightContent
                  expanded={expanded}
                  expandability={expandability}
                  isSelected={isSelected}
                  onRemove={() => onRemove(itemId, parentId)}
                  onEdit={() => onEdit(itemId, parentId)}
                />
              }
            />
          </div>
          {overridingReason && (
            <Text variant={'body1'} color={ColorVars.Cyan['Cyan 11']}>
              {`${t('page.tip.overrideRulePrefix')}: ${overridingReason}`}
            </Text>
          )}
          {footer()}
        </Flex>
      </Box>
    </Pressable>
  );
};

export default ReturnableItem;
