import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { SummaryTotalType } from '@aftership/returns-logics-core';

import { Divider } from '@/components/Divider';
import useDevice from '@/hooks/useDevice';
import { toCurrency } from '@/utils/price';

import SectionLayout, { SummarySectionName } from './SectionLayout';

import { useSummaryContext } from '../SummaryProvider';
import { useHideTotalSection } from '../hooks';
import { desktopTotalValueFontStyle, mobileTotalValueFontStyle } from '../styles/summary.css';

const TotalSection = () => {
  const isMobile = useDevice().mobile;
  const { t } = useTranslation();
  const { previewSummary, refundName, isLegacyReplace, resolution } = useSummaryContext();

  const SummaryLabel = {
    [SummaryTotalType.Refund]: t('page.description.totalRefund'),
    [SummaryTotalType.Upsell]: t('page.description.totalPay'),
    [SummaryTotalType.Unchanged]: t('page.description.totalRefund'),
  };
  const financialOutcome = previewSummary?.financial_outcome;
  const totalLable = (financialOutcome && SummaryLabel[financialOutcome]) ?? null;

  const checkoutTotal = previewSummary?.checkout_total_set;
  const refundTotal = previewSummary?.refund_total_set;
  // 跟后端确认了，这里不需要用 refund credit fallback
  const refundCredit = previewSummary?.refund_credit_set;
  const isRefund = financialOutcome === SummaryTotalType.Refund;

  const getTotalValue = () => {
    if (financialOutcome === SummaryTotalType.Upsell) {
      return toCurrency(
        checkoutTotal?.presentment_money?.amount ?? '',
        checkoutTotal?.presentment_money?.currency,
        true,
      );
    } else if (financialOutcome === SummaryTotalType.Refund) {
      return toCurrency(
        refundTotal?.presentment_money?.amount ?? refundCredit?.presentment_money?.amount ?? '',
        refundTotal?.presentment_money?.currency ?? refundCredit?.presentment_money?.currency ?? '',
        true,
      );
    } else if (financialOutcome === SummaryTotalType.Unchanged) {
      // TODO 需要和 PO 确认是否展示 0
      return toCurrency('0', checkoutTotal?.presentment_money?.currency, true);
    }
    return null;
  };

  const getTotal = () => {
    return {
      label: totalLable,
      value: getTotalValue(),
    };
  };
  const total = getTotal();

  const hideTotalSection = useHideTotalSection({
    resolution,
    isLegacyReplace,
    totalValue: total?.value,
  });

  if (hideTotalSection) {
    return null;
  }

  return (
    <>
      <Divider spacing={SpacingVars['5']} />
      <Flex direction='column' gap={SpacingVars['1']}>
        <SectionLayout name={SummarySectionName.Total}>
          <Text variant='subtitle1'>{total.label}</Text>
          <Flex direction='column' gap={SpacingVars['1']}>
            <Text
              className={isMobile ? mobileTotalValueFontStyle : desktopTotalValueFontStyle}
              textAlign='right'
            >
              {total.value}
            </Text>
          </Flex>
        </SectionLayout>
        {isRefund && refundName && (
          <Flex justifyContent='end'>
            <Text variant='body2' textAlign='right' maxWidth={264}>
              {refundName}
            </Text>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default TotalSection;
