import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SummaryTotalType } from '@aftership/returns-logics-core';

import { useReturnPreviewFlow } from './useReturnPreviewFlow';

const useSummaryTotalUtil = () => {
  const { t } = useTranslation();
  const { returnPreview, returnPreviewFlowContext } = useReturnPreviewFlow();
  const financialOutcome = returnPreview?.summary?.financial_outcome;

  const submitButtonText = useMemo(() => {
    if (financialOutcome === SummaryTotalType.Refund) {
      return t('page.request.submit');
    }
    if (financialOutcome === SummaryTotalType.Upsell) {
      return t('page.request.payAndSubmit');
    }

    return t('page.request.submit');
  }, [financialOutcome, t]);

  const isRefund = useMemo(() => financialOutcome === SummaryTotalType.Refund, [financialOutcome]);
  // 存在 instant exchange 或者需要支付的情况都属于需要支付
  const isNeedPay = useMemo(
    () =>
      financialOutcome === SummaryTotalType.Upsell || !!returnPreviewFlowContext?.instantExchange,
    [financialOutcome, returnPreviewFlowContext?.instantExchange],
  );

  return {
    submitButtonText,
    isRefund,
    isNeedPay,
  };
};

export default useSummaryTotalUtil;
