import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Modal, Popup, Uploader, useToast } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { usePreviewContext } from '@aftership/preview-kit/client';
import { ReturnableOrderItem } from '@aftership/returns-logics-core';

import { ChooseAnswer } from '@/features/request-returns/components/ChooseAnswer';
import { ChooseQuantity } from '@/features/request-returns/components/ChooseQuantity';
import { ChooseReason } from '@/features/request-returns/components/ChooseReason';
import { ChooseSubReason } from '@/features/request-returns/components/ChooseSubReason';
import { FillMoreDetail } from '@/features/request-returns/components/FillMoreDetail';
import { FillReturnFooter } from '@/features/request-returns/components/FillReturnFooter';
import Header from '@/features/request-returns/components/FillReturnOverlay/components/Header.tsx';
import { checkFillDetailInfoBtnAvailable } from '@/features/request-returns/components/FillReturnOverlay/utls/reasonUtls.ts';
import { ReturnItemInfoCard } from '@/features/request-returns/components/ReturnItemInfoCard';
import useDerivedState from '@/hooks/useDerivedState.ts';
import useDevice from '@/hooks/useDevice';
import { decodeHtmlEntities } from '@/utils/products.ts';
import { EventName } from '@/utils/tracker/consts.ts';
import getTrackerInstance from '@/utils/tracker/index.ts';

import { useReturnInfoModal } from './hooks/useReturnInfoModal';
import {
  bodyMobileClassName,
  chooseInfoBodyMobileClassName,
  containerClass,
} from './styles.css.ts';

import FillReturnTitle from '../FillReturnTitle/FillReturnTitle.tsx';

interface ContainerProps extends PropsWithChildren {
  isOpen: boolean;
  header: ReactNode;
  returnInfoCard: ReactNode;
  title?: ReactNode;
  footer?: ReactNode;
}

const ContainerComp: FC<ContainerProps> = ({
  children,
  isOpen,
  header,
  footer,
  returnInfoCard,
  title,
}) => {
  const { isPreview } = usePreviewContext();

  const isMobile = useDevice().mobile;
  return isMobile ? (
    <Popup
      width={'100%'}
      isOpen={isOpen}
      header={header}
      disableFocusManagement={isPreview}
      height={'90%'}
    >
      <Box className={bodyMobileClassName}>
        <Box className={chooseInfoBodyMobileClassName}>
          {returnInfoCard}
          <Flex direction={'column'} gap={SpacingVars['6']}>
            {title}
            {children}
          </Flex>
        </Box>
        {footer}
      </Box>
    </Popup>
  ) : (
    <Modal isOpen={isOpen} size={'medium'} disableFocusManagement={isPreview}>
      <Flex direction={'row'} height={'540px'}>
        <Box paddingX={'44px'} height={'100%'} backgroundColor={ColorVars.Grey['Grey 100']}>
          {returnInfoCard}
        </Box>
        <Box flex={2} height={'100%'} backgroundColor={ColorVars['B&W'].Background}>
          <Flex direction={'column'} height={'100%'}>
            {header}
            <Box paddingX={SpacingVars['10']}>{title}</Box>
            <Box className={containerClass}>{children}</Box>
            {footer}
          </Flex>
        </Box>
      </Flex>
    </Modal>
  );
};

export interface FillReturnInfoProps {
  orderItem: ReturnableOrderItem;
  isOpen: boolean;
}
const FillReturnOverlay = ({ orderItem, isOpen }: FillReturnInfoProps) => {
  const {
    external_id: itemId,
    returnableQuantity: returnableQuantity,
    product_title: productTitleOrigin = '',
    variant_title: variantTitleOrigin,
    image_urls,
    frontEndPrice: price,
  } = orderItem;
  const productCoverUrl = image_urls?.[0];
  const { t } = useTranslation();
  const {
    questionInfo,
    isLimitQuantityExactlyOne,
    backPreState,
    cancelFillReturnInfo,
    addToBlockList,
    selectReason,
    selectSubReason,
    selectQuantity,
    fillMoreDetail,
    context,
    currentStep,
    matches,
    canBack,
  } = useReturnInfoModal(itemId);
  const { showToast } = useToast();

  const reasons = context?.reasonGroup?.return_reasons ?? [];
  const selectedReasonId = context?.selectedReasonId;
  const subReasons = reasons.find((reason) => reason.id === selectedReasonId)?.subreasons ?? [];
  const selectedSubReasonId = context?.selectedSubReasonId;
  const [selectedQuantity, setSelectedQuantity] = useDerivedState(context?.quantity ?? 1);
  const [comments, setComments] = useDerivedState(context?.comment ?? '');

  const [images, setImages] = useDerivedState(context?.returnImages);
  const [uploadState, setIsUploadState] = React.useState<Uploader['status'] | 'idle'>('idle');

  const clearMoreDetailInfo = () => {
    setImages([]);
    setComments('');
  };
  const rendFooter = () => {
    if (currentStep?.name === 'selectQuantity') {
      return (
        <FillReturnFooter
          isDisabled={Number.isNaN(selectedQuantity)}
          onPress={() => selectQuantity(selectedQuantity)}
          btnText={t('page.request.nextStep')}
        />
      );
    }
    if (currentStep?.name === 'fillMoreDetail' && selectedReasonId) {
      const isButtonDisable = checkFillDetailInfoBtnAvailable(
        reasons,
        selectedReasonId,
        uploadState,
        images,
        comments,
      );
      return (
        <FillReturnFooter
          isDisabled={isButtonDisable}
          onPress={() => fillMoreDetail(comments, images)}
          btnText={t('popup.request.done')}
          isLoading={uploadState === 'uploading'}
        />
      );
    }
    return null;
  };

  const handleUploadError = (error: Uploader['uploadError']) => {
    if (error) {
      let errorI18nKey = '';
      switch (error.type) {
        case 'limit-length':
          errorI18nKey = 'v2.page.error.exceedImageLength';
          break;
        case 'overSize':
          errorI18nKey = 'v2.page.error.exceedImageSize';
          break;
        case 'non-repeatable':
          errorI18nKey = 'v2.page.error.nonRepeatableImage';
          break;
        case 'request':
        default:
          errorI18nKey = 'v2.page.error.uploadImageNetError';
          break;
      }
      showToast(t(errorI18nKey));
    }
  };
  const productTitle = useMemo(() => decodeHtmlEntities(productTitleOrigin), [productTitleOrigin]);
  const variantTitle = useMemo(() => decodeHtmlEntities(variantTitleOrigin), [variantTitleOrigin]);

  return (
    <ContainerComp
      isOpen={isOpen}
      returnInfoCard={
        <ReturnItemInfoCard
          productTitle={productTitle}
          variantTitle={variantTitle}
          price={price}
          selectedQuantity={selectedQuantity}
          productCoverUrl={productCoverUrl}
        />
      }
      header={
        <Header
          canBack={canBack}
          backPreState={backPreState}
          cancelFillReturnInfo={cancelFillReturnInfo}
        />
      }
      title={
        currentStep?.name && (
          <FillReturnTitle
            stepName={currentStep?.name}
            questionTitle={questionInfo?.questionTitle}
            isSelectQuestionAnswer={!!matches?.('selectQuestionAnswer')}
            questionId={questionInfo?.questionId}
          />
        )
      }
      footer={rendFooter()}
    >
      <>
        {matches?.('selectQuestionAnswer') && questionInfo && (
          <ChooseAnswer {...questionInfo} itemId={itemId} onBtnClick={addToBlockList} />
        )}
        {currentStep?.name === 'selectQuantity' && (
          <ChooseQuantity
            isLimitQuantityExactlyOne={isLimitQuantityExactlyOne}
            returnableQuantity={returnableQuantity}
            selectedQuantity={selectedQuantity}
            onQuantityChange={(quantity) => setSelectedQuantity(quantity)}
            onSelectedQuantity={selectQuantity}
          />
        )}
        {currentStep?.name === 'selectReason' && (
          <ChooseReason
            reasons={reasons.map((reason) => ({
              name: reason.name,
              id: reason.id,
            }))}
            selectedReasonId={selectedReasonId}
            onSelectReason={(reasonId) => {
              if (reasonId !== selectedReasonId) {
                clearMoreDetailInfo();
              }

              getTrackerInstance().reportClickEvent({ eventName: EventName.selectReturnReason });

              selectReason(reasonId);
            }}
          />
        )}
        {currentStep?.name === 'selectSubReason' && (
          <ChooseSubReason
            reasonId={selectedReasonId ?? ''}
            subReasons={subReasons
              .filter((subReason) => subReason.enabled)
              .map((subReason) => ({
                name: subReason.name,
                id: subReason.key,
              }))}
            selectedSubReasonId={selectedSubReasonId}
            onSelectSubReason={(subReasonId) => {
              if (subReasonId !== selectedSubReasonId) {
                clearMoreDetailInfo();
              }

              getTrackerInstance().reportClickEvent({ eventName: EventName.selectReturnSubReason });
              selectSubReason(subReasonId);
            }}
          />
        )}
        {currentStep?.name === 'fillMoreDetail' && selectedReasonId && (
          <FillMoreDetail
            selectedReason={reasons.find((reason) => reason.id === selectedReasonId)!}
            comments={comments}
            images={images}
            onCommentsChange={(comments) => setComments(comments)}
            onImagesChange={(images) => setImages(images)}
            onUploadingChange={(status) => {
              setIsUploadState(status);
            }}
            onUploadError={handleUploadError}
          />
        )}
      </>
    </ContainerComp>
  );
};

export default FillReturnOverlay;
