import { useMemo } from 'react';

import { PickupAddressSource } from '@aftership/returns-logics-core';

import { useReturnMethodSubFlow } from './useReturnMethodSubFlow';

export const useGetPickupDefaultValues = () => {
  const returnMethodSubFlow = useReturnMethodSubFlow();

  const { context } = returnMethodSubFlow || {};

  return useMemo(() => {
    return {
      ...context?.pickupData,
      pickupAppointmentDate: context?.pickupData?.pickupAppointmentDate ?? '',
      pickupAddressSource: context?.pickupData?.pickupAddressSource ?? PickupAddressSource.order,
    };
  }, [context?.pickupData]);
};
