'use client';

import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Button, Flex, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { GiftReturnResolution } from '@aftership/returns-logics-core';
import { useFlow } from 'returns-logics/react';

import FormInput from '@/components/Form/FormInput';
import { FullScreenCard } from '@/components/FullScreenCard';
import { ScrollBox } from '@/components/ScrollBox';
import { StepCard } from '@/components/StepCard';
import useDevice from '@/hooks/useDevice';
import { useStepCardMinHeight } from '@/hooks/useStepCardMinHeight';

import { HowContact } from './FormItemForHowContact';
import { HowResolve } from './FormItemForHowResolve';
import FormItemForReplaceOption from './FormItemForReplaceOption';
import FormItemForReturnSame from './FormItemForShippingAddress';
import { WhatOthers } from './FormItemForWhatOthers';
import { WhichProduct } from './FormItemForWhichProduct';
import { GiftReturnTitle } from './GiftReturnTitle';

import { FormWithoutOrderInfoFormValues } from '../constant/errorMapping';
import { giftReturnCardContent } from '../giftCard.css';
import useCountry from '../hooks/useCountry';
import useResolutionOptions from '../hooks/useResolutionOptions';
import { useSubmitGiftReturnFormNotMatch } from '../hooks/useSubmitGiftReturnFormNotMatch';
import { NotMatchingOrderSchema } from '../schema';

const FormWithoutOrderInfo = () => {
  const { t } = useTranslation();
  const resolutions = useResolutionOptions();

  const { i18n } = useTranslation();

  const { mobile } = useDevice();
  const { options: countryOptions, findHasState, getStateOption, countriesMap } = useCountry();

  const { control, watch, handleSubmit } = useForm<FormWithoutOrderInfoFormValues>({
    mode: 'all',
    defaultValues: {
      resolution: resolutions[0].value as GiftReturnResolution,
    },
    resolver: yupResolver(NotMatchingOrderSchema()) as any,
    context: {
      countryMap: countriesMap,
    },
  });

  const countryCode = watch('address.country');
  const currentResolution = watch('resolution');

  const stateOptions = useMemo(() => getStateOption(countryCode), [countryCode, getStateOption]);

  const { submit, isLoading } = useSubmitGiftReturnFormNotMatch();

  const showStateField = findHasState(countryCode);

  const flow = useFlow();

  const onSubmit = handleSubmit((values) => {
    submit({ ...values, langCode: i18n.language });
  });

  const height = useStepCardMinHeight();

  const onBack = () => {
    flow.children.giftReturnSubFlow?.dispatch?.({ type: 'goBack' });
  };

  const replaceOption = useMemo(() => {
    return (
      currentResolution === GiftReturnResolution.replaceWithTheSameItem && (
        <FormItemForReplaceOption control={control} />
      )
    );
  }, [control, currentResolution]);

  const whoGave = useMemo(() => {
    return (
      <Flex direction='column' gap={8}>
        <Text variant='subtitle1'>{t('page.gift.description.whoGave')}</Text>
        <Flex direction='column' gap={8}>
          <Flex direction='row' gap={8}>
            <Box flex={1}>
              <FormInput
                control={control}
                name='customer.firstName'
                placeholder={t('page.gift.placeholder.firstName')}
                fullWidth={true}
              />
            </Box>
            <Box flex={1}>
              <FormInput
                control={control}
                name='customer.lastName'
                placeholder={t('page.gift.placeholder.lastName')}
                fullWidth={true}
              />
            </Box>
          </Flex>
          <FormInput
            control={control}
            name='customer.email'
            placeholder={t('page.gift.placeholder.emailOptional')}
            fullWidth={true}
          />
        </Flex>
      </Flex>
    );
  }, [control, t]);

  if (mobile) {
    return (
      <FullScreenCard
        onBack={onBack}
        footer={
          <Box paddingY={SpacingVars['4']} paddingX={SpacingVars['4']}>
            <Button
              onPress={() => {
                onSubmit();
              }}
              size='large'
              isLoading={isLoading}
              isDisabled={false}
            >
              {t('page.gift.action.submit')}
            </Button>
          </Box>
        }
      >
        <Flex direction='column' gap={SpacingVars['6']} width={'100%'} paddingX={SpacingVars['2']}>
          <GiftReturnTitle />
          {whoGave}
          <WhichProduct control={control} />
          <HowResolve resolutions={resolutions} control={control} />
          {replaceOption}
          <FormItemForReturnSame
            control={control}
            countryOptions={countryOptions}
            hasStateField={showStateField}
            stateOptions={stateOptions}
          />
          <HowContact control={control} />
          <WhatOthers control={control} />
        </Flex>
      </FullScreenCard>
    );
  }

  return (
    <StepCard
      height={height}
      width={792}
      title={<GiftReturnTitle />}
      onBack={onBack}
      overflow='hidden'
    >
      <Flex
        flex={1}
        direction='column'
        paddingTop={SpacingVars['8']}
        className={giftReturnCardContent}
      >
        <ScrollBox>
          <Flex direction='column' gap={24} width={'500px'} margin={'auto'}>
            {whoGave}
            <WhichProduct control={control} />
            <HowResolve resolutions={resolutions} control={control} />
            {replaceOption}
            <FormItemForReturnSame
              control={control}
              countryOptions={countryOptions}
              hasStateField={showStateField}
              stateOptions={stateOptions}
            />
            <HowContact control={control} />
            <WhatOthers control={control} />
          </Flex>
        </ScrollBox>
        <Box width={500} margin={'auto'} paddingY={SpacingVars['4']}>
          <Button
            onPress={() => {
              onSubmit();
            }}
            size='large'
            isLoading={isLoading}
            isDisabled={false}
          >
            {t('page.gift.action.submit')}
          </Button>
        </Box>
      </Flex>
    </StepCard>
  );
};

export default FormWithoutOrderInfo;
