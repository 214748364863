import { t } from 'i18next';
import * as yup from 'yup';

import { GiftReturnResolution, isEmail } from '@aftership/returns-logics-core';

export const addressSchema = () =>
  yup.object({
    firstName: yup.string().required(t('page.gift.form.firstName')),
    lastName: yup.string().required(t('page.gift.form.lastName')),
    line1: yup.string().required(t('page.gift.form.address')),
    line2: yup.string(),
    country: yup.string().required(t('page.gift.form.country')),
    city: yup.string().required(t('page.gift.form.city')),
    postalCode: yup.string().required(t('page.gift.form.postalCode')),
    phoneNumber: yup.string().required(t('page.gift.form.phoneNumber')),
    state: yup.string().when(['country', '$countryMap'], ([country, countryMap], schema) => {
      if (countryMap && countryMap[country]?.sublist?.length > 0) {
        return schema.required(t('page.gift.form.state'));
      }
      return schema.notRequired();
    }),
  });

const userSchema = () =>
  yup.object({
    firstName: yup.string().required(t('page.gift.form.firstName')),
    lastName: yup.string().required(t('page.gift.form.lastName')),
    email: yup
      .string()
      .required(t('page.gift.form.email'))
      .test({
        test: isEmail,
        message: t('page.gift.form.emailValid'),
        name: 'email',
      }),
  });

const customerSchema = () =>
  yup.object({
    firstName: yup.string().required(t('page.gift.form.firstName')),
    lastName: yup.string().required(t('page.gift.form.lastName')),
    email: yup
      .string()
      .trim()
      .test({
        test: (value) => isEmail(value) || !value,
        message: t('page.gift.form.emailValid'),
        name: 'email',
      }),
  });

const basicSchema = () => ({
  productName: yup.string().required(t('page.gift.form.productName')),
  resolution: yup.mixed<GiftReturnResolution>().oneOf(Object.values(GiftReturnResolution)),
  option: yup.string().when('resolution', {
    is: GiftReturnResolution.replaceWithTheSameItem,
    then: (schema) => schema.required(t('page.gift.form.options')),
    otherwise: (schema) => schema.notRequired(),
  }),
  notes: yup.string().notRequired(),
  address: addressSchema(),
  recipient: userSchema().notRequired(),
  langCode: yup.string().notRequired(),
});

export const NotMatchingOrderSchema = () =>
  yup.object({
    ...basicSchema(),
    customer: customerSchema().notRequired(),
  });

export const MatchingOrderSchema = () =>
  yup.object({
    ...basicSchema(),
  });
