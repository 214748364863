import { Scope, ErrorBoundary as SentryErrorBoundary } from '@sentry/nextjs';
import { Resource } from 'i18next';
import { AppProps } from 'next/app';
import { AppInitialProps } from 'next/app';
import { AppContext } from 'next/app';
import Head from 'next/head';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeProvider, ToastProvider, clsx } from '@aftership/design-system';
import { defaultFont } from '@aftership/returns-logics-core';

import { ErrorPage } from '@/components/ErrorPage';
import OpenGraph from '@/components/OpenGraph';
import PreviewEntry from '@/features/preview/components/PreviewEntry';
import BreakPointProvider from '@/features/returns/components/BreakPointProvider';
import ClickwrapProvider from '@/features/returns/components/ClickwrapProvider';
import GoogleReCaptchaProviderWrapper from '@/features/returns/components/GoogleReCaptchaProviderWrapper';
import ReturnsLogicProvider from '@/features/returns/components/ReturnsLogicProvider';
import ReturnsPage from '@/features/returns/components/ReturnsPage';
import ReturnsPageWrapper from '@/features/returns/components/ReturnsPageWrapper';
import ReturnsProvider from '@/features/returns/components/ReturnsProvider';
import { useGetErrorPage } from '@/features/returns/hooks/useGetErrorPage';
import getInitialProps from '@/features/returns/resources/getInitialProps';
import initialize3rdLibs from '@/features/returns/utils/3rd-party';
import {
  clearPersistQueryCookiesFromDocument,
  clearPreviewData,
  persistQueryToCookies,
} from '@/features/returns/utils/common.ts';
import { useSetExternalConfig } from '@/hooks/useGetExternalConfig';
import { useScrollAttribute } from '@/hooks/useScrollAttribute';
import { initI18NextForClient } from '@/i18n/client';
import { getServerSideTranslation } from '@/i18n/server';
import '@/styles/global.css';
import { themeProviderPreviewClass } from '@/styles/global.css';
import { getUserTheme } from '@/styles/theme';
import { isIOS } from '@/utils/runtimeEnv';

const rcFavicon = 'https://websites.am-static.com/assets/brands/glyph/aftership-colored.svg';

interface RootLayoutProps {
  initialProps: PromiseType<ReturnType<typeof getInitialProps>>['initialProps'];
  resources: Resource;
}

const beforeCapture = (scope: Scope) => {
  scope.setLevel('fatal');
};

export default function RootLayout({
  Component,
  pageProps,
  initialProps,
  resources,
}: RootLayoutProps & AppProps & { Component: NextjsPage }) {
  const {
    isPreview,
    shopInfo,
    shopHostName,
    initialLang,
    uaString,
    returnsPageAccess,
    appProxy,
    APP_ENV,
  } = initialProps;
  initI18NextForClient(initialLang, resources, shopInfo.default_language);
  useSetExternalConfig();

  const enableReCaptcha = shopInfo?.order_lookup?.by_postal_code_enabled;

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('%c[RC App version]: v5.0.0 ', 'background: #22dd9a; color: #ffffff');
    // eslint-disable-next-line no-console
    console.log('%c[ env ]', 'font-size:13px; background:#22dd9a; color:#ffffff;', APP_ENV);
    // todo: remove this
    return () => {
      // eslint-disable-next-line no-console
      console.log('%c[ unload ]', 'font-size:13px; background:#dd2222; color:#ffffff;');
    };
  }, [APP_ENV]);

  useEffect(() => {
    persistQueryToCookies();
    const handleBeforeUnload = () => {
      clearPersistQueryCookiesFromDocument();
      if (isPreview) {
        clearPreviewData();
      }
    };
    window.addEventListener('unload', handleBeforeUnload);
    return () => {
      window.removeEventListener('unload', handleBeforeUnload);
    };
  }, [isPreview]);

  const { t } = useTranslation();
  const {
    returns_page_primary_font: primaryFont = defaultFont.name,
    returns_page_body_font: bodyFont = defaultFont.name,
    store_name,
    organization,
    favicon,
    theme_color,
  } = shopInfo;
  const { short_name } = organization ?? {};

  const title = store_name || `${short_name}.returnscenter.com`;
  const docTitle = store_name
    ? `${t('page.landing.title')} - ${store_name}`
    : t('page.landing.title');
  const docDescription = t('page.landing.description', { title });
  const canonicalUrl = shopHostName
    ? `https://${shopHostName}`
    : 'https://www.aftership.com/returns';

  const errorPage = useGetErrorPage({
    returnsPageAccessResult: returnsPageAccess,
    shopInfo,
    shopHostName,
    t,
  });

  const userThemeConfig = useMemo(() => {
    return getUserTheme({
      primaryFont: `"${primaryFont}", "${defaultFont.name}"`,
      secondaryFont: `"${bodyFont}", "${defaultFont.name}"`,
      primaryColor: theme_color,
    });
  }, [primaryFont, bodyFont, theme_color]);

  useEffect(() => {
    initialize3rdLibs({ shopInfo, isAppProxy: !!appProxy?.shopifyProxyMode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useScrollAttribute();

  return (
    <BreakPointProvider isPreview={isPreview} uaString={uaString}>
      <ReturnsPageWrapper appProxy={appProxy}>
        <Head>
          <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
          {isIOS(uaString) ? (
            /* 苹果有点击文本框自动放大的功能，使用 maximum-scale=1.0, user-scalable=no 可以解决；
             * 但苹果同时会忽略 maximum-scale=1.0, user-scalable=no 的本义，允许用户手动缩放；
             * 因此采用该属性实现：需要用户可以缩放页面，又希望选中文本框不自动放大
             */
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
            />
          ) : (
            <meta name='viewport' content='initial-scale=1.0, width=device-width' />
          )}
          {shopInfo.returns_page_block_search_engine ? (
            <meta name='robots' content='noindex' />
          ) : null}
          <OpenGraph url={canonicalUrl} />
          {/* 浏览器主题色: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/meta/name/theme-color */}
          <meta name='theme-color' content={theme_color} />
          <meta name='description' content={docDescription} />
          <link rel='shortcut icon' type='image/x-icon' href={favicon?.src ?? rcFavicon} />
          <link rel='icon' type='image/x-icon' href={favicon?.src ?? rcFavicon} />
          <link rel='apple-touch-icon' href={favicon?.src ?? rcFavicon} />
          <link rel='canonical' href={canonicalUrl} />
          <title>{docTitle}</title>
        </Head>
        <SentryErrorBoundary
          beforeCapture={beforeCapture}
          fallback={
            <ErrorPage
              title={t('page.description.somethingWentWrong')}
              redirectUrl={`https://${shopHostName}`}
            />
          }
        >
          <ReturnsProvider data={initialProps}>
            <ThemeProvider
              customThemeVars={userThemeConfig}
              className={clsx({
                [themeProviderPreviewClass]: isPreview,
              })}
            >
              <ToastProvider outOfQueueStrategy='replace'>
                <ReturnsLogicProvider initialProps={initialProps} isPreview={isPreview}>
                  <PreviewEntry isPreview={isPreview}>
                    <GoogleReCaptchaProviderWrapper
                      enableReCaptcha={enableReCaptcha}
                      APP_ENV={APP_ENV}
                    >
                      <ClickwrapProvider>
                        {errorPage ||
                          (Component.getLayout ? (
                            Component.getLayout(<Component {...pageProps} />)
                          ) : (
                            <ReturnsPage>
                              <Component {...pageProps} />
                            </ReturnsPage>
                          ))}
                      </ClickwrapProvider>
                    </GoogleReCaptchaProviderWrapper>
                  </PreviewEntry>
                </ReturnsLogicProvider>
              </ToastProvider>
            </ThemeProvider>
          </ReturnsProvider>
        </SentryErrorBoundary>
      </ReturnsPageWrapper>
    </BreakPointProvider>
  );
}

RootLayout.getInitialProps = async (
  context: AppContext,
): Promise<RootLayoutProps & AppInitialProps> => {
  const { req, res } = context.ctx;
  if (req && res) {
    const { appProps, initialProps } = await getInitialProps(context);
    const { shopInfo, initialLang } = initialProps;
    const { resources } = await getServerSideTranslation(
      shopInfo,
      initialLang,
      context,
      initialProps,
    );

    return { ...appProps, initialProps, resources };
  } else {
    return window.__NEXT_DATA__.props as RootLayoutProps & AppInitialProps;
  }
};
