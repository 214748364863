import { useTranslation } from 'react-i18next';

import { Flex, Icon, Position, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { DistanceUnit, DropoffLocationItem } from '@aftership/returns-logics-core';

import LocationItem from '../LocationItem';

interface DropoffLocationsProps {
  title?: React.ReactNode;
  locations?: DropoffLocationItem[];
  children?: React.ReactNode;
  count?: number;
  showMore?: boolean;
}

const DropoffLocations = ({
  title,
  locations = [],
  children,
  count = 3,
  showMore = true,
}: DropoffLocationsProps) => {
  const { t } = useTranslation();

  return (
    <Flex direction='column' gap={SpacingVars['3']}>
      {locations.length > 0 && (
        <Flex direction='column' gap={SpacingVars['1']}>
          {typeof title === 'string' ? (
            <Text variant='subtitle2' color={ColorVars['Grey']['Grey1200']}>
              {title}
            </Text>
          ) : (
            title
          )}
          <Flex alignItems='center' gap={SpacingVars['1']}>
            <Icon
              source={Position}
              size={SpacingVars['4']}
              color={ColorVars['Grey']['Grey 1100']}
            />
            <Text variant='caption1' color={ColorVars['Grey']['Grey 1100']}>
              {t('page.happyReturn.baseShippingAddress')}
            </Text>
          </Flex>
        </Flex>
      )}
      {locations
        ?.slice(0, count)
        ?.map((location) => (
          <LocationItem
            key={location.name}
            location={location}
            distanceUnit={DistanceUnit.MI}
            showMore={showMore}
          />
        )) ?? []}
      {children}
    </Flex>
  );
};

export default DropoffLocations;
