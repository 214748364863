import { useTranslation } from 'react-i18next';

import { Button, ModalTrigger, Text } from '@aftership/design-system';
import { DropoffLocationItem } from '@aftership/returns-logics-core';

import RetailReworkModal from '../RetailReworkModal';

interface ViewRetailReworkLocationsProps {
  locations?: DropoffLocationItem[];
}

const ViewRetailReworkLocations = ({ locations }: ViewRetailReworkLocationsProps) => {
  const { t } = useTranslation();

  return (
    <ModalTrigger>
      <Button variant='link'>
        <Text variant='body2'>{t('view.more.locations')}</Text>
      </Button>
      <RetailReworkModal locations={locations} />
    </ModalTrigger>
  );
};

export default ViewRetailReworkLocations;
