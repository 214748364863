import { Fragment } from 'react';

import { Box, Flex, Icon, Link, Text } from '@aftership/design-system';
import { ColorVars, RadiusVars, SpacingVars } from '@aftership/design-tokens';
import { Shops } from '@aftership/returns-logics-core';

import useShopUrls from '../hooks/useShopUrls';

interface ReturnsPageFooterProps {
  shopInfo: Shops;
  isPreview?: boolean;
}

const ReturnsPageFooter = ({ shopInfo }: ReturnsPageFooterProps) => {
  const { theme_color } = shopInfo;
  const { socialLinks, footerLinks } = useShopUrls(shopInfo);

  return (
    <Box backgroundColor={theme_color} padding={`0 ${SpacingVars['10']}`}>
      <Flex alignItems='center' height={SpacingVars['20']}>
        <Flex flex={1} alignItems='center' gap={SpacingVars[3]}>
          {footerLinks.map((link, index) => (
            <Fragment key={link.name}>
              <Link href={link.url}>
                <Flex alignItems='center'>
                  <Text variant='caption2' color={ColorVars['B&W'].Background}>
                    {link.name}
                  </Text>
                </Flex>
              </Link>
              {index < footerLinks.length - 1 && (
                <Box
                  backgroundColor={ColorVars['B&W'].Background}
                  width={SpacingVars[1]}
                  height={SpacingVars[1]}
                  borderRadius={RadiusVars[2]}
                />
              )}
            </Fragment>
          ))}
        </Flex>

        <Flex gap={SpacingVars[2]} alignItems='center'>
          {socialLinks.map((socialLink) => (
            <Link href={socialLink.url} key={socialLink.url}>
              <Icon
                source={socialLink.icon}
                size={SpacingVars['9']}
                color={ColorVars['B&W'].Background}
              />
            </Link>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

export default ReturnsPageFooter;
