import { useActorRef, useSelector } from '@xstate/react';
import { useEffect } from 'react';

import {
  ReturnPolicySubFlowActorRef,
  getReturnPageUrlByUserName,
  mockReturrnPolicySubFlow,
  returnPolicySubFlow,
} from '@aftership/returns-logics-core';

import { RecursiveResult } from '../types';
import useFlow from '../useFlow';

export const useReturnPolicy = (
  isPreview?: boolean,
): RecursiveResult<ReturnPolicySubFlowActorRef> => {
  const userName = useFlow().context?.storeConfig?.shopInfo?.organization?.short_name;
  const shopHostName = userName ? getReturnPageUrlByUserName(userName) : '';

  const actorRef = useActorRef(isPreview ? mockReturrnPolicySubFlow : returnPolicySubFlow);

  const { value, isLoading, context, matches, snapshot } = useSelector(actorRef, (state) => {
    return {
      value: state.value,
      children: state.children,
      context: state.context,
      matches: state.matches,
      isLoading: state.hasTag('loading'),
      snapshot: state,
    };
  });

  useEffect(() => {
    if (shopHostName) {
      actorRef.send({ type: 'GET_RETURN_POLICY', data: { shopHostName } });
    }
  }, [shopHostName, actorRef]);

  return {
    id: actorRef.id,
    currentStep: {
      name: value,
      isLoading,
    },
    context,
    matches,
    ref: actorRef,
    dispatch: actorRef['send'],
    on: actorRef['on'],
    snapshot,
    children: {},
  };
};
