import { clsx } from 'clsx';
import dayjs from 'dayjs';
import { ReactNode, useState } from 'react';

import {
  Button,
  Flex,
  Icon,
  LeftChevronCircle,
  RightChevronCircle,
} from '@aftership/design-system';
import { ColorVars } from '@aftership/design-tokens';

import * as classnames from './DatePicker.css';

export type DatePickerProps = {
  value?: string;
  showPagination?: boolean;
  limit?: number;
  onSelect?: (date: string) => void;
  scrollable?: boolean;
  formatter?: (date: dayjs.Dayjs) => {
    weekDate: string;
    dayOfMonth: string;
    monthOfYear: string;
  };
  maxTooltip?: ReactNode;
  minTooltip?: ReactNode;
  dates: {
    date: dayjs.Dayjs;
    disabled: boolean;
  }[];
};

type DateItemProps = {
  date: dayjs.Dayjs;
  isDisabled: boolean;
  isSelected: boolean;
  onSelect: (date: dayjs.Dayjs) => void;
  formatter: DatePickerProps['formatter'];
};

const defaultFomatter = (date: dayjs.Dayjs) => {
  const isToday = dayjs(date).isSame(dayjs(), 'd');

  const weekDate = dayjs(date).format('ddd');
  const dayOfMonth = dayjs(date).format('D');
  const monthOfYear = isToday ? 'Today' : dayjs(date).format('MMM');

  return { weekDate, dayOfMonth, monthOfYear };
};

const DateItem = ({
  date,
  isSelected,
  onSelect,
  formatter = defaultFomatter,
  isDisabled,
}: DateItemProps) => {
  const { weekDate, dayOfMonth, monthOfYear } = formatter(date);

  return (
    <div
      className={clsx(classnames.dateItemStyle, {
        [classnames.selectedDateItemStyle]: isSelected,
        [classnames.dateItemDisabledStyle]: isDisabled,
      })}
      onClick={() => {
        if (isDisabled) return;
        onSelect(date);
      }}
    >
      <div
        className={clsx(classnames.weekDayClassname, {
          [classnames.weekDaySelectedClassname]: isSelected,
          [classnames.weekDayDisabledClassname]: isDisabled,
        })}
      >
        {weekDate}
      </div>
      <div
        className={clsx(classnames.dayOfMonthClassname, {
          [classnames.dayOfMonthSelectedClassname]: isSelected,
          [classnames.dayOfMonthDisabledClassname]: isDisabled,
        })}
      >
        {dayOfMonth}
      </div>
      <div
        className={clsx(classnames.monthOfYearClassname, {
          [classnames.monthOfYearSelectedClassname]: isSelected,
          [classnames.monthOfYearDisabledClassname]: isDisabled,
        })}
      >
        {monthOfYear}
      </div>
    </div>
  );
};

export const DatePicker = ({
  value,
  dates: allDates,
  limit = 7,
  onSelect,
  scrollable = false,
  showPagination = false,
  formatter = defaultFomatter,
  maxTooltip,
  minTooltip,
}: DatePickerProps) => {
  const pageNumber = Math.ceil(allDates.length / limit);

  const [pageIndex, setPageIndex] = useState(0);

  const data = allDates.slice(pageIndex * limit, (pageIndex + 1) * limit);

  const handlePreviousPage = () => {
    setPageIndex(pageIndex - 1);
  };

  const handleNextPage = () => {
    setPageIndex(pageIndex + 1);
  };

  const handleSelect = (date: dayjs.Dayjs) => {
    onSelect?.(date.format('YYYY-MM-DD'));
  };

  const isDisabledPreviousPage = pageIndex <= 0;
  const isDisabledNextPage = pageIndex >= pageNumber - 1;

  return (
    <div className={classnames.datePickerWrapper}>
      {showPagination && (
        <div
          data-tooltipContent={minTooltip}
          className={clsx({
            [classnames.chevronTooltip]: isDisabledPreviousPage && !!minTooltip,
          })}
        >
          <Button
            className={classnames.chevronClass}
            onPress={handlePreviousPage}
            isDisabled={isDisabledPreviousPage}
            iconOnly
            variant='plain'
          >
            <Icon
              size='24px'
              source={LeftChevronCircle}
              color={isDisabledPreviousPage ? ColorVars['Grey']['Grey 600'] : undefined}
            />
          </Button>
        </div>
      )}
      <Flex
        flex={1}
        justifyContent='space-around'
        className={clsx({
          [classnames.scrollableContainer]: scrollable,
        })}
      >
        {data.map((dateInfo, index) => (
          <DateItem
            key={index}
            date={dateInfo.date}
            isSelected={dayjs(value).isSame(dateInfo.date, 'd')}
            onSelect={handleSelect}
            formatter={formatter}
            isDisabled={dateInfo.disabled}
          />
        ))}
      </Flex>

      {showPagination && (
        <div
          data-tooltipContent={maxTooltip}
          className={clsx({
            [classnames.chevronTooltip]: isDisabledNextPage && !!maxTooltip,
          })}
        >
          <Button
            className={classnames.chevronClass}
            onPress={handleNextPage}
            isDisabled={isDisabledNextPage}
            iconOnly
            variant='plain'
          >
            <Icon
              size='24px'
              source={RightChevronCircle}
              color={isDisabledNextPage ? ColorVars['Grey']['Grey 600'] : undefined}
            />
          </Button>
        </div>
      )}
    </div>
  );
};
