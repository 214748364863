import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Resolution } from '@aftership/returns-logics-core';

import { useShopInfo } from '@/features/returns/hooks/useShopInfo';

import { ResolutionSuffix, genRefundExchangeCode } from '../i18n/dynamic';

export const useResolutionInfo = () => {
  const shopInfo = useShopInfo();

  const {
    exchange_for_anything_name,
    exchange_for_anything_description,
    exchange_name,
    exchange_description,
    refund_to_original_payment_name,
    refund_to_original_payment_description,
    refund_to_store_credit_name,
    refund_to_store_credit_description,
    instant_refund_with_refundid_name,
    instant_refund_with_refundid_description,
  } = shopInfo ?? {};

  const resolutionNameMap: Record<Resolution, { name?: string; description?: string }> =
    useMemo(() => {
      return {
        [Resolution.OriginalPayment]: {
          name: refund_to_original_payment_name,
          description: refund_to_original_payment_description,
        },
        [Resolution.StoreCredit]: {
          name: refund_to_store_credit_name,
          description: refund_to_store_credit_description,
        },
        [Resolution.ReplaceTheSameItem]: {
          name: exchange_name,
          description: exchange_description,
        },
        [Resolution.ExchangeForAnything]: {
          name: exchange_for_anything_name,
          description: exchange_for_anything_description,
        },
        [Resolution.Refundid]: {
          name: instant_refund_with_refundid_name,
          description: instant_refund_with_refundid_description,
        },
      };
    }, [
      exchange_for_anything_name,
      exchange_name,
      refund_to_original_payment_name,
      refund_to_store_credit_name,
      instant_refund_with_refundid_name,
      exchange_for_anything_description,
      exchange_description,
      refund_to_original_payment_description,
      refund_to_store_credit_description,
      instant_refund_with_refundid_description,
    ]);

  return resolutionNameMap;
};

export const useTranslateResolutionInfo = () => {
  const resolutionInfo = useResolutionInfo();
  const { t } = useTranslation();

  const translatedResolutionInfo = useMemo(() => {
    return Object.entries(resolutionInfo).reduce((pre, [key, value]) => {
      return {
        ...pre,
        [key]: {
          name: t(
            genRefundExchangeCode({
              type: key as Resolution,
              suffix: ResolutionSuffix.Name,
            }),
            {
              rawValue: value.name,
              defaultValue: value.name,
            },
          ),
          description: t(
            genRefundExchangeCode({
              type: key as Resolution,
              suffix: ResolutionSuffix.Description,
            }),
            {
              rawValue: value.description,
              defaultValue: value.description,
            },
          ),
        },
      };
    }, {});
  }, [resolutionInfo, t]);

  return translatedResolutionInfo as Record<Resolution, { name: string; description: string }>;
};
