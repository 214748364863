import { t } from 'i18next';
import { get } from 'lodash-es';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Flex, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { PickupInfomationData } from '@aftership/returns-logics-core';

import { DatePicker, DatePickerProps } from '@/components/DatePicker/DatePicker';
import useDevice from '@/hooks/useDevice';

import { SectionWrapper } from './SectionWrapper';

import { PICK_UP_DATE_LIMIT, PICK_UP_LAST_14_DAYS } from '../../utils/constants';

interface PickupDateSectionProps {
  isDisabledWeekend?: boolean;
}

export const PickupDateSection = ({ isDisabledWeekend }: PickupDateSectionProps) => {
  const { control } = useFormContext<PickupInfomationData>();
  const isMobile = useDevice().mobile;

  const dates: DatePickerProps['dates'] = useMemo(() => {
    if (!isDisabledWeekend) return PICK_UP_LAST_14_DAYS;

    // 如果禁用周末，需要将周末日期禁用
    return PICK_UP_LAST_14_DAYS.map((date) => {
      return {
        ...date,
        disabled: date.date.day() === 0 || date.date.day() === 6,
      };
    });
  }, [isDisabledWeekend]);

  return (
    <SectionWrapper title={t('pickup.info.title')}>
      <Flex direction='column' gap={SpacingVars['2']}>
        <Controller
          control={control}
          name='pickupAppointmentDate'
          render={({ field: { value, onChange }, formState }) => {
            const msg = get(formState?.errors, 'pickupAppointmentDate')?.message as string;

            return (
              <Flex direction='column' gap={SpacingVars['2']}>
                <DatePicker
                  dates={dates}
                  limit={isMobile ? PICK_UP_DATE_LIMIT : 7}
                  value={value}
                  onSelect={onChange}
                  showPagination={!isMobile}
                  scrollable={isMobile}
                  minTooltip={t('pick_up.date.limitation')}
                  maxTooltip={t('pick_up.date.limitation')}
                />
                {msg && (
                  <Text variant='body2' color={ColorVars['Red']['Red 1000']}>
                    {msg}
                  </Text>
                )}
              </Flex>
            );
          }}
        />
        <Text variant='body2' color={ColorVars['Grey']['Grey 1000']}>
          {t('pick_up.date.instructions')}
        </Text>
      </Flex>
    </SectionWrapper>
  );
};
