import { useActor } from '@xstate/react';

import { getCountries } from '@aftership/returns-logics-core';

export const useCountries = () => {
  const [state] = useActor(getCountries);

  return {
    data: {
      countries: state.output,
    },
    status: state.status,
  };
};
