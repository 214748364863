import getConfig from 'next/config';

import { internalHostname } from '@aftership/returns-logics-core';

import { request } from '@/utils/request.ts';

export interface PatchAppProxyPathPayload {
  storeKey: string;
  orgId: string;
  proxyUrl: string;
}
const {
  serverRuntimeConfig: { AM_API_KEY },
} = getConfig();

export default async function putAppProxyPath(payload: PatchAppProxyPathPayload) {
  const uri = `${internalHostname()}/shopify-proxy-config/store/${payload.storeKey}`;
  const response = await request(uri, {
    method: 'PUT',
    body: JSON.stringify({
      url: payload.proxyUrl,
    }),
    headers: {
      'am-api-key': AM_API_KEY,
      'am-organization-id': payload.orgId,
      'Content-Type': 'application/json',
    },
  });
  return response.data || Promise.reject(response);
}
