import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  ChevronDownFilled,
  ChevronUpFilled,
  Flex,
  Icon,
  Link,
  Position,
  Pressable,
  Text,
} from '@aftership/design-system';
import { ColorVars, SpacingVars, StrokeWidthVars } from '@aftership/design-tokens';
import { DistanceUnit, DropoffLocationItem } from '@aftership/returns-logics-core';

import useDevice from '@/hooks/useDevice';
import { calcBusinessHourScope, calcTodayCloseTime } from '@/utils/weeksUtils';

import { addressTextClassName, positionIconClassName } from './LocationItem.css';

interface LocationItemProps {
  location: DropoffLocationItem;
  distanceUnit: DistanceUnit;
  isRedirectToMap?: boolean;
  showMore?: boolean;
}

const LocationItem = ({
  location,
  showMore = true,
  distanceUnit,
  isRedirectToMap = false,
}: LocationItemProps) => {
  const { t } = useTranslation();
  const [isExpand, setExpand] = useState(!showMore);
  const { country, city, longitude, street, latitude, postal_code } = location.address ?? {};

  const secondaryTitle = useMemo(() => {
    const todayCloseTime = calcTodayCloseTime(location.business_hours);
    if (todayCloseTime) {
      return `${location.distance.toFixed(1)} ${distanceUnit} | ${t(
        'page.happyReturn.description.closeTime',
        { closeTime: todayCloseTime },
      )}`;
    } else {
      return `${location.distance.toFixed(1)} ${distanceUnit}`;
    }
  }, [distanceUnit, location.business_hours, location.distance, t]);
  const businessHourScope = useMemo(
    () => calcBusinessHourScope(location.business_hours),
    [location],
  );

  const addressText = [street, city, postal_code, country].filter(Boolean).join(', ');

  const handleClick = () => {
    setExpand((prevState) => !prevState);
  };

  const needMoreEntrance = businessHourScope.length > 0 || addressText.length > 0;

  const AddressDescription = () => {
    const isMobile = useDevice().mobile;
    return (
      <Flex gap={SpacingVars['2']}>
        <Text variant='caption1' color={ColorVars['Grey']['Grey 1100']}>
          {secondaryTitle}
        </Text>
        {needMoreEntrance && showMore && (
          <Pressable onPress={handleClick}>
            <Flex alignItems='center' gap={SpacingVars['1']} height={SpacingVars['5']}>
              <Text
                flexShrink={0}
                variant={isMobile ? 'caption2' : 'caption1'}
                color={ColorVars['Grey']['Grey 1100']}
              >
                {isExpand ? t('page.action.showLess') : t('page.action.showAll')}
              </Text>
              <Icon
                color={ColorVars['Grey']['Grey 1100']}
                source={isExpand ? ChevronUpFilled : ChevronDownFilled}
              />
            </Flex>
          </Pressable>
        )}
      </Flex>
    );
  };

  return (
    <Flex direction='column'>
      <Flex alignItems='center'>
        <Flex flex={1} direction='column' gap={StrokeWidthVars['2']}>
          <Text variant='subtitle2' color={ColorVars['Grey']['Grey1200']}>
            {location.name}
          </Text>
          <AddressDescription />
        </Flex>
        {isRedirectToMap && (
          <Link
            className={positionIconClassName}
            href={`https://maps.google.com/maps?q=${location.address?.latitude},${location.address?.longitude}`}
            target='_blank'
          >
            <Icon source={Position} />
          </Link>
        )}
      </Flex>

      {isExpand && (
        <Box>
          {businessHourScope.length > 0 && (
            <Flex direction='column' paddingY={SpacingVars['1']}>
              {businessHourScope.map((item) => (
                <Text variant='caption1' color={ColorVars['Grey']['Grey 1100']} key={item}>
                  {item}
                </Text>
              ))}
            </Flex>
          )}
          {/* RMA Detail 场景下设计要求 address 应该是纯文案而不是 link */}
          {/* 因为 RMA Detail 底下已经有 Get directions 按钮，所以只需要展示地址即可 */}
          {Boolean(addressText) &&
            (!showMore ? (
              <Text
                variant='caption1'
                color={ColorVars['Grey']['Grey 1100']}
                elementType='p'
                className={addressTextClassName}
              >
                {addressText}
              </Text>
            ) : (
              <Link
                target='_blank'
                href={`https://maps.google.com/maps?q=${latitude},${longitude}`}
              >
                <Text
                  variant='caption1'
                  color={ColorVars['Grey']['Grey 1100']}
                  textDecoration='underline'
                  elementType='p'
                  className={addressTextClassName}
                >
                  {addressText}
                </Text>
              </Link>
            ))}
        </Box>
      )}
    </Flex>
  );
};

export default LocationItem;
