import { FindOrderFields } from '@aftership/returns-logics-core';
import { useFlow } from 'returns-logics/react';

export const useQueryGiftReturnOrder = () => {
  const flow = useFlow();
  const giftReturnFlow = flow?.children?.giftReturnSubFlow;

  const submit = (data: FindOrderFields) => {
    giftReturnFlow?.dispatch?.({
      type: 'findOrder',
      data,
    });
  };

  const isLoading = giftReturnFlow?.matches('fetchOrderInfo');

  return {
    submit,
    isLoading,
  };
};
