'use client';

import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Button, Flex } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { GiftReturnResolution } from '@aftership/returns-logics-core';
import { useFlow } from 'returns-logics/react';

import { FullScreenCard } from '@/components/FullScreenCard';
import { ScrollBox } from '@/components/ScrollBox';
import { StepCard } from '@/components/StepCard';
import useDevice from '@/hooks/useDevice';
import { useStepCardMinHeight } from '@/hooks/useStepCardMinHeight';

import { HowContact } from './FormItemForHowContact';
import { HowResolve } from './FormItemForHowResolve';
import FormItemForReplaceOption from './FormItemForReplaceOption';
import FormItemForShippingAddress from './FormItemForShippingAddress';
import { WhatOthers } from './FormItemForWhatOthers';
import { WhichProduct } from './FormItemForWhichProduct';
import { GiftReturnTitle } from './GiftReturnTitle';

import { FormWithOrderInfoFormValues } from '../constant/errorMapping';
import { giftReturnCardContent } from '../giftCard.css';
import useCountry from '../hooks/useCountry';
import useResolutionOptions from '../hooks/useResolutionOptions';
import { useSubmitGiftReturnFormMatch } from '../hooks/useSubmitGiftReturnFormMatch';
import { MatchingOrderSchema } from '../schema';

const FormWithOrderInfo = () => {
  const { t, i18n } = useTranslation();

  const { mobile } = useDevice();

  const resolutions = useResolutionOptions();

  const { options: countryOptions, findHasState, getStateOption } = useCountry();

  const { control, watch, handleSubmit } = useForm<FormWithOrderInfoFormValues>({
    mode: 'all',

    defaultValues: {
      resolution: resolutions[0].value,
    },
    resolver: yupResolver(MatchingOrderSchema()) as any,
    context: {
      countryMap: countryOptions,
    },
  });

  const countryCode = watch('address.country');
  const currentResolution = watch('resolution');

  const stateOptions = useMemo(() => getStateOption(countryCode), [countryCode, getStateOption]);

  const showStateField = findHasState(countryCode);

  const height = useStepCardMinHeight();

  const flow = useFlow();

  const { submit, isLoading } = useSubmitGiftReturnFormMatch();

  const onBack = () => {
    flow.children.giftReturnSubFlow?.dispatch?.({ type: 'goBack' });
  };

  const onSubmit = handleSubmit((values) => {
    submit({
      ...values,
      langCode: i18n.language,
    });
  });

  const replaceOption = useMemo(() => {
    return (
      currentResolution === GiftReturnResolution.replaceWithTheSameItem && (
        <FormItemForReplaceOption control={control} />
      )
    );
  }, [control, currentResolution]);

  if (mobile) {
    return (
      <FullScreenCard
        onBack={onBack}
        footer={
          <Box paddingY={SpacingVars['4']} paddingX={SpacingVars['4']}>
            <Button
              onPress={() => {
                onSubmit();
              }}
              size='large'
              isLoading={isLoading}
              isDisabled={false}
            >
              {t('page.gift.action.submit')}
            </Button>
          </Box>
        }
      >
        <Flex direction='column' gap={SpacingVars['6']} width={'100%'} paddingX={SpacingVars['2']}>
          <GiftReturnTitle />
          <WhichProduct control={control} />
          <HowResolve control={control} resolutions={resolutions} />
          {replaceOption}
          <FormItemForShippingAddress
            control={control}
            countryOptions={countryOptions}
            stateOptions={stateOptions}
            hasStateField={showStateField}
          />
          <HowContact control={control} />
          <WhatOthers control={control} />
        </Flex>
      </FullScreenCard>
    );
  }

  return (
    <StepCard
      height={height}
      width={792}
      title={<GiftReturnTitle />}
      onBack={onBack}
      overflow='hidden'
    >
      <Flex
        flex={1}
        direction='column'
        paddingTop={SpacingVars['8']}
        className={giftReturnCardContent}
      >
        <ScrollBox>
          <Flex direction='column' gap={24} width={'500px'} margin={'auto'}>
            <WhichProduct control={control} />
            <HowResolve control={control} resolutions={resolutions} />
            {replaceOption}
            <FormItemForShippingAddress
              control={control}
              countryOptions={countryOptions}
              stateOptions={stateOptions}
              hasStateField={showStateField}
            />
            <HowContact control={control} />
            <WhatOthers control={control} />
          </Flex>
        </ScrollBox>
        <Box width={500} margin={'auto'} paddingY={SpacingVars['4']}>
          <Button
            onPress={() => {
              onSubmit();
            }}
            isLoading={isLoading}
            isDisabled={false}
            size='large'
          >
            {t('page.gift.action.submit')}
          </Button>
        </Box>
      </Flex>
    </StepCard>
  );
};

export default FormWithOrderInfo;
