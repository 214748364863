'use client';

import * as Sentry from '@sentry/nextjs';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Flex, Text, useToast } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { OrderLookupType } from '@aftership/returns-logics-core';

import { useFindOrder } from '@/features/order-lookup/hooks/useFindOrder';
import { useOrderLookupSubFlow } from '@/features/order-lookup/hooks/useOrderLookupSubFlow';
import { useShopInfo } from '@/features/returns/hooks/useShopInfo';
import { formatDate } from '@/features/returns/utils/formatUtils';
import getTrackerInstance, { getBaseSid } from '@/utils/tracker';
import { EventName } from '@/utils/tracker/consts';

import ProductItem from './ProductItem';
import { orderItemHeader, orderItemWrapper } from './style.css';

import { Order } from '../types';

interface OrderItemProps {
  order: Order;
}

const OrderItem: FC<OrderItemProps> = ({ order }) => {
  const createdAt = formatDate(order.created_at, 'MMM D, YYYY');

  const [isLoading, setIsLoading] = useState(false);

  const orderLookupSubFlow = useOrderLookupSubFlow();
  const shopInfo = useShopInfo();
  const { showToast } = useToast();
  const { t } = useTranslation();

  const { findOrder } = useFindOrder({
    onSuccess: (order) => {
      getTrackerInstance().updateExtraDataByOrderInfo(order);

      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
      showToast(t('validation.error.wrongOrderNoEmail'), { type: 'error' });
    },
  });

  useEffect(() => {
    if (shopInfo?.organization?.id) {
      Sentry.setTag('orgId', shopInfo?.organization?.id);
    }

    if (orderLookupSubFlow?.snapshot?.status === 'done') {
      const email = orderLookupSubFlow?.snapshot?.output?.email;
      const orderNumber = orderLookupSubFlow?.snapshot?.output?.orderNumber;
      if (email) {
        Sentry.setUser({ email });
        Sentry.setTag('email', email);
      }
      if (orderNumber) {
        Sentry.setTag('orderNumber', orderNumber);
      }
    }
  }, [
    shopInfo?.organization?.id,
    orderLookupSubFlow?.snapshot?.status,
    orderLookupSubFlow?.snapshot?.output?.email,
    orderLookupSubFlow?.snapshot?.output?.orderNumber,
  ]);

  const enterReturnPage = (email: string, number: string) => {
    const orderNumber = number.trim();
    const customerEmail = email.trim();

    setIsLoading(true);

    getTrackerInstance().reportClickEvent({
      eventName: EventName.findOrder,
      payload: {
        orderLookupType: OrderLookupType.EMAIL,
        source: 'order_item_click',
      },
    });

    findOrder({
      orderLookupType: OrderLookupType.EMAIL,
      email: customerEmail,
      orderNumber,
      traceId: getBaseSid(),
    });
  };

  return (
    <Box className={orderItemWrapper}>
      <Flex direction='column'>
        <Box className={orderItemHeader}>
          <Flex alignItems='center' justifyContent='space-between' height={'100%'}>
            <Flex direction='column' gap={0}>
              <Text variant='subtitle2' color={ColorVars['Grey']['Grey1200']}>
                {t('page.details.order')} #{order.order_number}
              </Text>
              {createdAt ? (
                <Text variant='body2' color={ColorVars['Grey']['Grey 1100']}>
                  {t('page.description.placedOn')} {createdAt}
                </Text>
              ) : null}
            </Flex>
            <Button
              isLoading={isLoading}
              variant='secondary'
              onPress={() => {
                enterReturnPage(order.email, order.order_number);
              }}
            >
              <Text variant='subtitle2'>{t('page.request.create')}</Text>
            </Button>
          </Flex>
        </Box>
        <Box padding={24}>
          <Flex direction='column' gap={SpacingVars['6']}>
            {order.products.map((item) => (
              <ProductItem key={item.number} product={item} />
            ))}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default OrderItem;
