'use client';

import { get } from 'lodash-es';
import { Control, Controller } from 'react-hook-form';

import { Flex, Text, TextField, TextFieldProps } from '@aftership/design-system';
import { ColorVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice';

import { mobileTextFieldClassName } from './index.css';

export type Props = {
  control: Control<any>;
  name: string;
  placeholder?: string;
  fullWidth?: boolean;
  onInputChange?: (value: string, name: string) => void;
  hiddenError?: boolean;
  transform?: (value: string) => string;
} & TextFieldProps;

function FormInput({
  control,
  name,
  placeholder,
  onInputChange,
  fullWidth,
  hiddenError,
  transform,
  ...rest
}: Props) {
  const isMobile = useDevice().mobile;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, onChange, value }, formState }) => {
        const msg = get(formState?.errors, name)?.message as string;
        return (
          <Flex direction='column' gap={2} flex={1}>
            <TextField
              placeholder={placeholder}
              value={value}
              onChange={(v) => {
                const transformValue = transform ? transform(v) : v;
                onChange(transformValue);
                onInputChange?.(transformValue, name);
              }}
              fullWidth={fullWidth}
              onBlur={onBlur}
              className={isMobile ? mobileTextFieldClassName : undefined}
              {...rest}
            />
            {msg && !hiddenError && (
              <Text variant='body2' color={ColorVars['Red']['Red 1000']}>
                {msg}
              </Text>
            )}
          </Flex>
        );
      }}
    />
  );
}

export default FormInput;
