import { fromPromise } from 'xstate';

import { v4hostName } from '../constant';
import { request } from '../request/request';
import { ResponseBody } from '../request/response';
import { ReturnItemType } from '../types';

// 获取单个 return 详情
export const getReturnByRMAId = fromPromise(
  async ({ input }: { input: { rmaId: string; returnId: string; token: string } }) => {
    const { rmaId, token } = input;
    const res = await request<ResponseBody<ReturnItemType>>(
      `${v4hostName()}/returns/rma_id/${rmaId}`,
      {
        method: 'GET',
        headers: { 'returns-authorization': token },
      },
    );

    return res?.data;
  },
);
