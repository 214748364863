// import { createBrowserInspector } from '@statelyai/inspect';
import { useActorRef } from '@xstate/react';
import { createContext, useContext, useEffect } from 'react';
import { createEmptyActor } from 'xstate';

import {
  IMainFlowInput,
  MainMachineActorRef,
  getSecureSessionStorage,
  mainFlowEngine,
  mockMainFlow,
  updateAppEnv,
} from '@aftership/returns-logics-core';

// import { MainMachineActorRef, mainFlowEngine } from '../core/machine/mainFlow';

// const { inspect } = createBrowserInspector();

const MainFlowContext = createContext(createEmptyActor() as MainMachineActorRef);

export const useMainFlowContext = () => {
  const flow = useContext(MainFlowContext);

  return flow;
};

const updateSessionStorage = (actor: MainMachineActorRef) => {
  getSecureSessionStorage().setItem(
    'returns-machine',
    JSON.stringify(actor.getPersistedSnapshot()),
  );
};

function getParsedSnapshot(key: string) {
  const item = getSecureSessionStorage().getItem(key);
  return item ? JSON.parse(item) : null;
}
interface Props {
  children: React.ReactNode;
  initialInput: IMainFlowInput;
  APP_ENV: string;
  isPreview: boolean;
  handleJwtError: VoidFunction;
  handleXStateError: (error: any) => void;
}
const PreviewModeContent = ({ initialInput, children }: Props) => {
  const mainFlow = useActorRef(mockMainFlow, {
    id: 'mainFlow',
    input: initialInput,
  });
  return <MainFlowContext.Provider value={mainFlow}>{children}</MainFlowContext.Provider>;
};

const MainFlowContent = ({ initialInput, children, handleJwtError, handleXStateError }: Props) => {
  const saveSnapshot = getParsedSnapshot('returns-machine');

  const shouldUseSnapshot =
    saveSnapshot &&
    initialInput.storeConfig?.shopInfo?.id === saveSnapshot?.context?.storeConfig?.shopInfo?.id;

  const mainFlow = useActorRef(
    mainFlowEngine.provide({
      actions: {
        handleJwtError,
      },
    }),
    {
      id: 'mainFlow',
      input: initialInput,
      snapshot: shouldUseSnapshot ? saveSnapshot : undefined,
    },
  );

  useEffect(() => {
    const { unsubscribe } = mainFlow.subscribe(
      () => {
        updateSessionStorage(mainFlow);
      },
      (error) => {
        handleXStateError(error);
      },
    );
    return () => {
      unsubscribe();
    };
  }, [handleXStateError, mainFlow]);

  useEffect(() => {
    mainFlow.send({
      type: 'UPDATE_STORE_CONFIG',
      data: {
        storeConfig: initialInput.storeConfig,
      },
    });
  }, [initialInput, mainFlow]);

  return <MainFlowContext.Provider value={mainFlow}>{children}</MainFlowContext.Provider>;
};

const MainFlowProvider = (props: Props) => {
  const { APP_ENV, isPreview, children } = props;

  updateAppEnv(APP_ENV);

  return isPreview ? (
    <PreviewModeContent {...props}>{children}</PreviewModeContent>
  ) : (
    <MainFlowContent {...props}>{children}</MainFlowContent>
  );
};

export default MainFlowProvider;
