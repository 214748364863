import { useTranslation } from 'react-i18next';

import { Button, Flex, ModalTrigger, Text } from '@aftership/design-system';
import { DropoffLocationItem, GrayFeatureKey } from '@aftership/returns-logics-core';

import useGetGrayFeatureEnabled from '@/hooks/useGetGrayFeatureEnabled';

import HappyReturnModal from '../HappyReturnModal';

interface ViewHappyReturnLocationsProps {
  isLoading?: boolean;
  allLocationsLink?: string | null;
  locations?: DropoffLocationItem[];
  nearByLayout?: 'start' | 'center' | 'end';
  onNearByLocations?: (latitude: number, longitude: number) => void;
}

const ViewHappyReturnLocations = ({
  isLoading = false,
  locations = [],
  allLocationsLink,
  nearByLayout = 'start',
  onNearByLocations,
}: ViewHappyReturnLocationsProps) => {
  const { t } = useTranslation();
  const hiddenAllLocations = useGetGrayFeatureEnabled(
    GrayFeatureKey.HiddenHappyReturnViewAllLocation,
  );

  return (
    <ModalTrigger>
      <Flex justifyContent={nearByLayout}>
        <Button variant='link'>
          <Text variant='body2'>{t('view.nearby.locations')}</Text>
        </Button>
      </Flex>
      <HappyReturnModal
        isLoading={isLoading}
        locations={locations}
        allLocationsLink={allLocationsLink}
        showAllLocations={!hiddenAllLocations}
        onNearByLocations={onNearByLocations}
      />
    </ModalTrigger>
  );
};

export default ViewHappyReturnLocations;
