import { useMemo } from 'react';

import { PickupInfomationData, ReturnMethodSlug } from '@aftership/returns-logics-core';

import getTrackerInstance from '@/utils/tracker';
import { EventName } from '@/utils/tracker/consts';

import { useReturnMethodSubFlow } from './useReturnMethodSubFlow';

export const useSelectReturnMethod = () => {
  const returnMethodFlow = useReturnMethodSubFlow();
  const { dispatch, context } = returnMethodFlow || {};

  return useMemo(() => {
    return {
      selectedMethodData: {
        selectedMethodId: context?.selectedMethodId,
        selectedMethodSlug: context?.selectedMethodSlug,
      },

      // 确认选中的 return method
      handleSelectReturnMethod: (methodId: string, methodSlug: ReturnMethodSlug) => {
        dispatch?.({ type: 'SELECT_RETURN_METHOD', data: { methodId, methodSlug } });

        getTrackerInstance().reportClickEvent({
          eventName: EventName.selectReturnMethod,
          payload: {
            returnMethod: methodSlug,
          },
        });
      },

      handleFillPickupData: (pickupData: PickupInfomationData) => {
        dispatch?.({ type: 'SUBMIT_PICKUP_DATA', data: { pickupData } });
      },

      // 选择 return method 完成
      handleSelectMethodDone: () => {
        dispatch?.({ type: 'SELECT_METHOD_DONE' });
      },
    };
  }, [dispatch, context?.selectedMethodId, context?.selectedMethodSlug]);
};
