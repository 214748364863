import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Checked,
  Flex,
  Icon,
  ListBox,
  ListBoxItem,
  Spinner,
} from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { PickupInfomationData, ReturnMethodSlug } from '@aftership/returns-logics-core';

import { FullScreenCard } from '@/components/FullScreenCard';
import { ScrollBox } from '@/components/ScrollBox';
import { SomethingWentWrong } from '@/components/SomethingWentWrong';
import { StepCard } from '@/components/StepCard';
import {
  ReturnMethodItemBox,
  ReturnMethodPageTitleText,
} from '@/features/preview/components/WithPreviewSection';
import DefaultMethod from '@/features/return-method/components/DefaultMethod';
import useDevice from '@/hooks/useDevice';
import { useStepCardMinHeight } from '@/hooks/useStepCardMinHeight';
import { ReturnMethodSuffix, genReturnRoutingRuleCode } from '@/i18n/dynamic';
import getTrackerInstance from '@/utils/tracker';
import { EventName } from '@/utils/tracker/consts';

import DropoffMethod from './components/DropoffMethod';
import { PickupInfomationForm } from './components/PickupInfomationModal/PickupInfomationModal';
import { useGetNearByLocation } from './hooks/useGetNearByLocation';
import { useReturnMethodPage } from './hooks/useReturnMethodPage';
import { useSelectReturnMethod } from './hooks/useSelectReturnMethod';
import { formatCostOfReturn } from './utils/costOfReturn';

import { OrderWarningTips } from '../request-returns/components/OrderWarningTips';

const ReturnMethodList = () => {
  const { t } = useTranslation();

  const { handleLoadNearbyLocations, nearbyLocations, isNearbyLoading } = useGetNearByLocation();

  const { returnMethods, isLoading, dropoffLocations } = useReturnMethodPage();

  const { selectedMethodData, handleSelectReturnMethod } = useSelectReturnMethod();

  const listBoxDependencies = useMemo(() => {
    return [nearbyLocations, returnMethods, isNearbyLoading];
  }, [isNearbyLoading, nearbyLocations, returnMethods]);

  return (
    <ListBox
      selectionMode='single'
      rowGap={SpacingVars['4']}
      dependencies={listBoxDependencies}
      selectedKeys={new Set([selectedMethodData?.selectedMethodId || ''])}
      items={isLoading ? [] : returnMethods || []}
      onSelectionChange={(selectedKeys) => {
        // tips: 不支持反选和多选
        if (typeof selectedKeys !== 'string' && selectedKeys.keys().next().value) {
          const methodId = selectedKeys.keys().next().value;
          handleSelectReturnMethod(
            methodId,
            returnMethods.find((method) => method.id === methodId)?.slug!,
          );
        }
      }}
    >
      {(method) => {
        return (
          <ListBoxItem isDisabled={method.disabled}>
            {({ isSelected }) => {
              const title = t(
                genReturnRoutingRuleCode({
                  methodId: method.id,
                  suffix: ReturnMethodSuffix.Name,
                }),
                { rawValue: method.name, defaultValue: method.name },
              );
              const costOfReturn = formatCostOfReturn(
                method.cost_of_return,
                method.return_cost_option,
              );
              const description = t(
                genReturnRoutingRuleCode({
                  methodId: method.id,
                  suffix: ReturnMethodSuffix.Description,
                }),
                { rawValue: method.description, defaultValue: method.description },
              );
              return (
                <ReturnMethodItemBox position='relative' padding={SpacingVars['4']}>
                  {isSelected && (
                    <Box position='absolute' top={SpacingVars['4']} right={SpacingVars['4']}>
                      <Icon source={Checked} size={SpacingVars['6']} variant='primary' />
                    </Box>
                  )}
                  {method.slug === ReturnMethodSlug.HappyReturns ||
                  method.slug === ReturnMethodSlug.RetailRework ? (
                    <DropoffMethod
                      slug={method.slug}
                      title={title + costOfReturn}
                      description={description}
                      location={dropoffLocations?.[method.slug]}
                      nearbyLocations={nearbyLocations}
                      onNearByLocations={handleLoadNearbyLocations}
                      isSelected={isSelected}
                      isNearbyLoading={isNearbyLoading}
                      disabled={method.disabled}
                    />
                  ) : (
                    <DefaultMethod
                      slug={method.slug}
                      title={title + costOfReturn}
                      description={description}
                      disabled={method.disabled}
                    />
                  )}
                </ReturnMethodItemBox>
              );
            }}
          </ListBoxItem>
        );
      }}
    </ListBox>
  );
};

const ReturnMethod = () => {
  const { mobile: isMobile } = useDevice();
  const { t } = useTranslation();
  const { isLoading, returnMethods, showMerchantModeOverrideBanner, handleGoBack } =
    useReturnMethodPage();

  const {
    selectedMethodData,
    handleSelectReturnMethod,
    handleFillPickupData,
    handleSelectMethodDone,
  } = useSelectReturnMethod();

  const minHeight = useStepCardMinHeight();
  const isWrongInput = returnMethods.length <= 0;

  const [isOpenCarrierPickupModal, setIsOpenCarrierPickupModal] = useState(false);

  const handleNext = () => {
    const { selectedMethodSlug } = selectedMethodData;

    getTrackerInstance().reportClickEvent({ eventName: EventName.clickReturnMethodNext });

    // 如果是 carrier pickup，需要填写信息
    if (selectedMethodSlug === ReturnMethodSlug.CarrierPickup) {
      setIsOpenCarrierPickupModal(true);
      return;
    }

    handleSelectReturnMethod(selectedMethodData.selectedMethodId, selectedMethodSlug!);
    handleSelectMethodDone();
  };

  const handleCloseCarrierPickupModal = () => {
    setIsOpenCarrierPickupModal(false);
  };

  const handleComfirmPickupInfo = (pickupData: PickupInfomationData) => {
    handleFillPickupData(pickupData);
    handleSelectMethodDone();
  };

  if (isMobile) {
    return (
      <FullScreenCard
        title={
          <ReturnMethodPageTitleText
            variant='title2'
            textAlign='center'
            color={ColorVars['Grey']['Grey1200']}
            paddingX={SpacingVars['2']}
          >
            {t('page.description.howWillYouReturnTheItems')}
          </ReturnMethodPageTitleText>
        }
        footer={
          !isLoading &&
          !isWrongInput && (
            <Box padding={SpacingVars['4']}>
              <Button
                size='large'
                isDisabled={!selectedMethodData.selectedMethodId}
                onPress={handleNext}
              >
                {t('page.request.nextStep')}
              </Button>
            </Box>
          )
        }
        onBack={handleGoBack}
      >
        {showMerchantModeOverrideBanner && (
          <Box paddingTop={SpacingVars['6']} paddingX={SpacingVars['2']}>
            <OrderWarningTips tips={t('page.banner.overrideRule')} />
          </Box>
        )}

        {isLoading ? (
          <Flex flex={1} alignItems='center' justifyContent='center'>
            <Spinner />
          </Flex>
        ) : isWrongInput ? (
          <SomethingWentWrong type='return-method' paddingBottom={SpacingVars['25']} />
        ) : (
          <Box flex={1} height={0} paddingY={SpacingVars['8']} paddingX={SpacingVars['2']}>
            <ReturnMethodList />
          </Box>
        )}
        <PickupInfomationForm
          open={isOpenCarrierPickupModal}
          onClose={handleCloseCarrierPickupModal}
          handleComfirmPickupInfo={handleComfirmPickupInfo}
        />
      </FullScreenCard>
    );
  }

  return (
    <StepCard
      width={800}
      height={minHeight}
      title={t('page.description.howWillYouReturnTheItems')}
      onBack={handleGoBack}
    >
      <Flex flex={1} height='0' direction='column' paddingBottom={SpacingVars['4']}>
        {showMerchantModeOverrideBanner && (
          <Box paddingTop={SpacingVars['8']} paddingX={SpacingVars['10']}>
            <OrderWarningTips tips={t('page.banner.overrideRule')} />
          </Box>
        )}
        {isLoading ? (
          <Flex flex={1} alignItems='center' justifyContent='center'>
            <Spinner />
          </Flex>
        ) : isWrongInput ? (
          <SomethingWentWrong type='return-method' paddingBottom={SpacingVars['15']} />
        ) : (
          <>
            <ScrollBox
              offsetX={SpacingVars['2']}
              paddingX={SpacingVars['10']}
              paddingTop={SpacingVars['3']}
              paddingBottom={SpacingVars['4']}
            >
              <ReturnMethodList />
            </ScrollBox>
            <Box width={480} paddingTop={SpacingVars['4']} marginX='auto'>
              <Button
                size='large'
                isDisabled={!selectedMethodData.selectedMethodId}
                onPress={handleNext}
              >
                {t('page.request.nextStep')}
              </Button>
            </Box>
          </>
        )}
      </Flex>
      <PickupInfomationForm
        open={isOpenCarrierPickupModal}
        onClose={handleCloseCarrierPickupModal}
        handleComfirmPickupInfo={handleComfirmPickupInfo}
      />
    </StepCard>
  );
};

export default ReturnMethod;
