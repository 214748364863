import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Flex, useToast } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { ERROR_DUPLICATED } from '@aftership/returns-logics-core';

import { useReturnInfoModal } from '@/features/request-returns/components/FillReturnOverlay/hooks/useReturnInfoModal.ts';
import SelectableItem from '@/features/request-returns/components/SelectableItem/SelectableItem';
import { AskQuestionWorkflow, genAskQuestionWorkflowCode } from '@/i18n/dynamic';

export interface ChooseAnswerProps {
  itemId: string;
  questionId: string;
  questionTitle: string;
  primaryBtnText: string;
  secondaryBtnText: string;
  onBtnClick: (addToBlockBtn: boolean) => void;
}

const ChooseAnswer = ({
  itemId,
  questionId,
  primaryBtnText,
  secondaryBtnText,
  onBtnClick,
}: ChooseAnswerProps) => {
  const { showToast } = useToast();
  const { t } = useTranslation();
  const { on, cancelFillReturnInfo } = useReturnInfoModal(itemId);
  useEffect(() => {
    const errorSubscription = on?.('error', (event) => {
      if (event.data.error.code !== ERROR_DUPLICATED) {
        showToast(event.data.error.message ?? 'error');
      } else {
        cancelFillReturnInfo();
      }
    });
    const successSubscription = on?.('blockItemSuccess', (event) => {
      showToast(
        t('page.description.add2NonReturnListSuccess', {
          title: event.data.productTitle,
        }),
      );
    });
    return () => {
      errorSubscription?.unsubscribe();
      successSubscription?.unsubscribe();
    };
  }, [cancelFillReturnInfo, on, showToast, t]);
  return (
    <Flex direction={'column'} gap={SpacingVars['3']}>
      <Card padding={0}>
        <SelectableItem
          content={t(
            genAskQuestionWorkflowCode({
              flowId: questionId,
              type: AskQuestionWorkflow.PrimaryButton,
            }),
            {
              rawValue: primaryBtnText,
              defaultValue: primaryBtnText,
            },
          )}
          onClick={() => onBtnClick(true)}
        />
      </Card>
      <Card padding={0}>
        <SelectableItem
          content={t(
            genAskQuestionWorkflowCode({
              flowId: questionId,
              type: AskQuestionWorkflow.SecondaryButton,
            }),
            {
              rawValue: secondaryBtnText,
              defaultValue: secondaryBtnText,
            },
          )}
          onClick={() => onBtnClick(false)}
        />
      </Card>
    </Flex>
  );
};

export default ChooseAnswer;
