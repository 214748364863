export const SHOPPER_HOST_KEY = 'shopper-hostname';
export const SHOPPER_PROXY_KEY = 'shopper-proxy-by';
export const SHOPPER_PREVIEW_KEY = 'shopper-preview';

export const SHOPPER_HOST_KEY_FROM_SEARCH = 'hostname';
export const SHOPPER_PROXY_KEY_FROM_SEARCH = 'proxy_by';
export const TARGET_STEP_KEY_FROM_SEARCH = 'target_step';
export const PREVIEW_KEY_FROM_SEARCH = 'preview';

// Block some sniff request, check it out for more details:
// https://grafana.automizely.org/d/-BMrSW24k/shopper-returnscenter-com?orgId=1&var-datasource=Prometheus-Prod-GKE-1&var-job=service-shopper-returnscenter-com&var-service=service-shopper-returnscenter-com&var-deployment=deployment-shopper-returnscenter-com&from=now-6h&to=now
export const PATH_BLOCK_LIST =
  /\.php|admin|\.xml|wp-|\.rar|\.zip|well-known|\.alfa|\.aws|\.env|ads\.txt|\.sh|\.github|grafana|pass|\.git|\.well-known/;
