import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Card, CardHeader, CardSection, Flex, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import {
  AmountPrice,
  PresentmentMoney,
  Resolution,
  ReturnCostOption,
  ReturnImage,
} from '@aftership/returns-logics-core';

import { Divider } from '@/components/Divider';
import {
  ReturnItemsSectionTitleText,
  ReturnMethodSectionTitleText,
} from '@/features/preview/components/WithPreviewSection';
import { formatCostOfReturn } from '@/features/return-method/utils/costOfReturn';
import { ReturnMethodSuffix, genReturnRoutingRuleCode } from '@/i18n/dynamic';

import ItemCard from '../../../../components/ItemCard';
import useCartStyle from '../../hooks/useCartStyle';
import useHideSummaryWidth from '../../hooks/useHideSummaryWidth';
import { productTitleContainerStyles } from '../../styles/product.css';
import { ReturnItemCardLoading } from '../CardLoading';
import ReasonDetailModal from '../ReasonDetailModal';

export interface ReturnItem {
  itemId: string;
  productTitle: string;
  price?: PresentmentMoney;
  originPrice: PresentmentMoney;
  variantTitle: string;
  productCoverUrl: string;
  returnReason: string;
  returnSubReason?: string;
  quantity: number;
  returnImages: ReturnImage[];
  notesToMerchant: string;
  productTags: string[];
}

export interface ReturnItemsComponentReturnMethod {
  id?: string;
  name?: string;
  description?: string;
}

export interface ReturnItemsComponentProps {
  resolution?: Resolution;
  returnItems?: ReturnItem[];
  costOfReturnOption?: ReturnCostOption;
  costOfReturAmountPrice?: AmountPrice | null;
  returnMethod?: ReturnItemsComponentReturnMethod;
  exchangeItemsExist?: boolean;
  isLoading?: boolean;
}

const ReturnItems = ({
  returnItems = [],
  costOfReturnOption,
  costOfReturAmountPrice,
  returnMethod,
  exchangeItemsExist,
  isLoading = false,
}: ReturnItemsComponentProps) => {
  const { t } = useTranslation();
  const hideSummaryWidth = useHideSummaryWidth();
  const { normalStyle, mobileNoOutlineClassname, isMobile, cardHeaderBottomStyle } = useCartStyle();
  const [returnDetailVisiable, setReturnDetailVisiable] = useState(false);
  const [returnItemId, setReturnItemId] = useState<string>();
  const appendCost = formatCostOfReturn(costOfReturAmountPrice, costOfReturnOption);
  const showReturnMethod = !!returnMethod?.id;
  const returnI18nMethodName = t(
    genReturnRoutingRuleCode({
      methodId: returnMethod?.id || '',
      suffix: ReturnMethodSuffix.Name,
    }),
    {
      rawValue: returnMethod?.name,
      defaultValue: returnMethod?.name,
    },
  );

  const returnMethodI18Description = t(
    genReturnRoutingRuleCode({
      methodId: returnMethod?.id || '',
      suffix: ReturnMethodSuffix.Description,
    }),
    { rawValue: returnMethod?.description, defaultValue: returnMethod?.description },
  );

  return (
    <>
      {isMobile && exchangeItemsExist && <Divider spacing={SpacingVars['1']} />}
      <Card
        {...normalStyle}
        width={hideSummaryWidth || normalStyle.width}
        className={mobileNoOutlineClassname}
      >
        <CardHeader className={cardHeaderBottomStyle}>
          <ReturnItemsSectionTitleText variant='title3'>
            {t('dynamic.requestReview.returnItems')}
          </ReturnItemsSectionTitleText>
        </CardHeader>
        {isLoading ? (
          <ReturnItemCardLoading />
        ) : (
          <>
            <CardSection>
              <Flex direction='column' gap={isMobile ? 32 : 0}>
                {returnItems?.map((item) => (
                  <ItemCard
                    hideDivider={isMobile}
                    key={item.itemId}
                    borderWidth='0'
                    productInfo={{
                      productTitle: item?.productTitle,
                      price: item.price,
                      originPrice: item.originPrice,
                      variantTitle: item?.variantTitle,
                      productCoverUrl: item.productCoverUrl,
                      quantity: isMobile ? item.quantity : void 0,
                      productTags: item.productTags,
                    }}
                    showMoreReason={!!item?.returnImages?.length || !!item?.notesToMerchant}
                    showMoreReasonClick={() => {
                      setReturnDetailVisiable(true);
                      setReturnItemId(item.itemId);
                    }}
                    returnReason={item.returnReason}
                    returnSubReason={item?.returnSubReason}
                    rightContent={!isMobile && <Text variant='body2'>x {item.quantity}</Text>}
                    productTitleContainerStyles={isMobile ? productTitleContainerStyles : undefined}
                  />
                ))}
              </Flex>
            </CardSection>

            {showReturnMethod && (
              <CardSection>
                <Flex gap={SpacingVars['2']} direction='column'>
                  <ReturnMethodSectionTitleText variant='subtitle2'>
                    {t('dynamic.requestReview.shipping')}
                  </ReturnMethodSectionTitleText>
                  <Flex gap={SpacingVars['1']} direction='column'>
                    {returnI18nMethodName && (
                      <Box>
                        <Text variant='body2'>{returnI18nMethodName}</Text>
                        <Text variant='subtitle2'>{` ${appendCost ?? ''}`}</Text>
                      </Box>
                    )}

                    {!!returnMethodI18Description && (
                      <Text variant='body2' color={ColorVars['Grey']['Grey 1100']}>
                        {returnMethodI18Description}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </CardSection>
            )}

            {returnItemId && (
              <ReasonDetailModal
                returnItemId={returnItemId}
                returnItems={returnItems}
                isOpen={returnDetailVisiable}
                onClose={() => {
                  setReturnDetailVisiable(false);
                }}
              />
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default ReturnItems;
