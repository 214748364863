import { useCallback, useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { ActorRef, AnyMachineSnapshot, Subscription, isMachineSnapshot } from 'xstate';

import { MainMachineActorRef } from '@aftership/returns-logics-core';

import { useMainFlowContext } from './MainFlowProvider.tsx';
import { ExtractChildren, ExtractSnapshotValue, IsMachineRef, RecursiveResult } from './types.js';

const defaultFn = () => {};

const convertRefToObject = <T extends ActorRef<AnyMachineSnapshot, any, any>>(ref: T) => {
  const snapshot = ref.getSnapshot();

  const result: RecursiveResult<T> = {
    id: ref.id,
    currentStep: {
      name: snapshot.value,
      isLoading: snapshot?.hasTag?.('loading'),
    },
    context: snapshot.context ?? {},
    dispatch: ref.send ?? defaultFn,
    matches: snapshot.matches?.bind?.(snapshot) ?? defaultFn,
    snapshot: snapshot as ExtractSnapshotValue<T>,
    ref: ref,
    on: ref.on?.bind?.(ref) ?? defaultFn,
    children: {} as {
      [K in keyof ExtractChildren<T>]: RecursiveResult<IsMachineRef<ExtractChildren<T>[K]>>;
    },
  };

  for (const id in snapshot.children) {
    const childRef = snapshot.children[id];
    const childSnapshot = childRef?.getSnapshot();

    if (childSnapshot?.status !== 'error' && isMachineSnapshot(childSnapshot)) {
      // @ts-ignore
      result.children[id] = convertRefToObject(childRef);
    }
  }

  return result;
};

// TODO: 会不会有性能问题
export const useFlow = (): RecursiveResult<MainMachineActorRef> => {
  const mainFlow = useMainFlowContext();

  const [result, setResult] = useState(convertRefToObject(mainFlow));

  const subscribeToChildren = useCallback(
    (actorRef: ActorRef<any, any, any>) => {
      const unsubscribes: Array<Subscription['unsubscribe']> = [];
      const snapshot = actorRef.getSnapshot();

      for (let k in snapshot.children) {
        const childRef = snapshot.children[k];
        if (
          childRef &&
          isMachineSnapshot(childRef.getSnapshot()) &&
          childRef.getSnapshot().status !== 'error'
        ) {
          const { unsubscribe } = childRef.subscribe(() => {
            flushSync(() => {
              setResult(convertRefToObject(mainFlow));
            });
            // 递归监听 children
            unsubscribes.push(...subscribeToChildren(childRef));
          });
          unsubscribes.push(unsubscribe);
          unsubscribes.push(...subscribeToChildren(childRef));
        }
      }
      return unsubscribes;
    },
    [mainFlow],
  );

  useEffect(() => {
    let unsubscribeChildren: Subscription['unsubscribe'][] = subscribeToChildren(mainFlow);

    const { unsubscribe: unsubscribeMain } = mainFlow.subscribe(() => {
      flushSync(() => {
        setResult(convertRefToObject(mainFlow));
      });

      unsubscribeChildren.forEach((unsub) => unsub());
      // 当 mainFlow 更新时，需要重新订阅当前的 children
      unsubscribeChildren = subscribeToChildren(mainFlow);
    });

    return () => {
      unsubscribeMain();
      unsubscribeChildren.forEach((unsub) => unsub());
    };
  }, [mainFlow, subscribeToChildren]);

  return result;
};

export default useFlow;
