import { fromPromise } from 'xstate';

import { v4hostName } from '../constant';
import { request } from '../request/request.ts';
import { ResponseBody } from '../request/response';
import { GetMatchingResolutionsInput, ResolutionMatchingData } from '../types';

export const getMatchingResolutions = fromPromise(
  async ({ input }: GetMatchingResolutionsInput): Promise<ResolutionMatchingData> => {
    const res = await request<ResponseBody<ResolutionMatchingData>>(
      `${v4hostName()}/resolution-matchings`,
      {
        method: 'POST',
        payload: {
          return_items: input.payload.returnItems,
          is_gift_return: input.payload.isGiftReturn,
          exchange_items: input.payload.exchangeItems,
        },
        headers: {
          'returns-authorization': input.token,
        },
      },
    );
    return res?.data || Promise.reject(res);
  },
);
