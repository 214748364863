import { Maybe, PresentmentMoney } from '../common';

interface Phone {
  country_code: string;
  number: string;
}
export interface Ineligibility {
  reason: NonReturnableReason;
  context?: {
    context?: { item_selected_question_id?: string; terminate_flow_message?: string };
  };
}
export type ReturnableItem = {
  external_id: string;
  quantity: number;
  overriding_ineligibilities: Ineligibility[];
};

export interface HiddenItem {
  external_id: string;
}
export interface ReturnCareInfo {
  eligible: boolean;
}
export interface OrderResponse {
  missing_phone_number: boolean;
  order: Orders;
  returnable_items: ReturnableItem[];
  non_returnable_items: {
    external_id: string;
    quantity: number;
    ineligibilities: Ineligibility[];
  }[];
  store: {
    url: string;
  };
  order_ineligibilities: Ineligibility[];

  hidden_items: HiddenItem[];
  return_care: Maybe<ReturnCareInfo>;
  /**
   * @description - 后端根据 returnable item 算出来的 returnable value
   * - 作用是 pos 场景在没有创建 rma 的情况下，提供一个最大 refundable value 的建议数据
   */
  returnable_value_set: {
    presentment_money: PresentmentMoney | null;
    shop_money: PresentmentMoney | null;
  };
}

export interface AmountSet {
  presentment_money: PresentmentMoney;
  shop_money?: PresentmentMoney;
}
export interface Orders {
  app: {
    key: string;
    platform: AppPlatform;
  };
  created_at: string;

  order_id: string;

  external_id: string;
  id: string;
  items: OrderItem[];

  metrics: {
    placed_at: string;
  };
  order_name: string;
  order_number: string;

  organization: {
    id: string;
  };
  shipping_address: ShippingAddress | null;

  shipping_discount_set: AmountSet;
  shipping_tax_set: AmountSet;
  shipping_total_set: AmountSet;
  discount_total_set: AmountSet;
  subtotal_set: AmountSet;
  tax_total_set: AmountSet;
  order_total_set: AmountSet;

  tags: string[];
  taxes_included: boolean;
}

export interface ShippingAddress {
  description?: string;
  company?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  address_line_1?: string;
  address_line_2?: string;
  address_line_3?: string;
  city?: string;
  state?: string;
  country?: string;
  postal_code?: string;
  phone?: Phone;
  type?: string;
  tax_number?: string;
  external_id?: string;
}

export type AppPlatform = 'shopify' | 'bigcommerce' | 'sfcc' | string;

export enum NonReturnableReason {
  ReturnWindowByOrderDate = 'return_window_by_order_date',
  ItemCategories = 'item_categories',
  ItemTags = 'item_tags',
  ItemSKU = 'item_sku',
  DiscountItems = 'discount_items',
  MultipleReturns = 'multiple_returns',
  ReturnWindowByFulfillDate = 'return_window_by_fulfill_date',
  OrderItemId = 'order_item_id',
  ReturnsCenterExchangeOrder = 'returns_center_exchange_order',
  ReturnWindowByDeliveryDate = 'return_window_by_delivery_date',
  UnfulfilledItem = 'unfulfilled_item',
  BlockByItemDiscountPercentage = 'block_by_item_discount_percentage',
  DiscountCode = 'discount_code',
  DiscountValue = 'discount_value',
  DiscountPercentage = 'discount_percentage',
  /**
   * @description 匹配上 workflow 不可退
   */
  ReturnWindowByCondition = 'return_window_by_condition',
  BaseReturnWindow = 'base_return_window',
  ReturnableQuantity = 'returnable_quantity',
  UnfulfilledOrder = 'unfulfilled_order',
}

// 将这两个字段抽取出来,方便单元测试做假数据
export interface BaseOrderItem {
  categories: string[];
  tags: string[];
}

export enum OrderItemType {
  NORMAL = 'normal',
  BUNDLE = 'bundle',
  bundledItem = 'bundled_item',
}

export interface OrderItem extends BaseOrderItem {
  external_id: string;
  sku: string;
  quantity: number;
  image_urls: string[];
  discount_set: AmountSet;
  tax_set: AmountSet;
  unit_price_set: AmountSet;
  base_price_set: AmountSet; // 折前展示价
  discounted_base_price_set: AmountSet; // 折后展示价
  unit_price_incl_tax_set: AmountSet; // 含税价
  tax_lines: {
    price_set: AmountSet;
    rate: number;
    title: string;
  }[];
  external_product_id: string;
  external_variant_id: string;
  title: string;
  variant_title: string;
  product_title: string;
  product_url: string;
  properties:
    | null
    | {
        name: string;
        value: string;
      }[];
  type: OrderItemType;
  external_parent_id: string | null; // 曾用名 parent_id
  return_rule: BundleReturnType; // 曾用名 bundle_return_type
  bundled_items: OrderItem[];
}

export enum BundleReturnType {
  WHOLE = 'whole_return',
  PARTIAL = 'partial_return',
}

export enum EligibilityReasons {
  UnfulfilledOrder = 'unfulfilled_order',
  MultipleReturns = 'multiple_returns',
  ReturnsCenterExchangeOrder = 'returns_center_exchange_order',
  ReturnsCenterReplacementOrder = 'returns_center_replacement_order',
  ItemCategories = 'item_categories',
  ItemTags = 'item_tags',
}
