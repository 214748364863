import { ActorRefFrom, SnapshotFrom, assertEvent, assign, setup } from 'xstate';

import { getReturnPolicy } from '../promise';
import { Policy } from '../types/returnPolicy';

export interface ReturnPolicySubFlowContext {
  returnPolicyData?: Policy;
}

type Event =
  | {
      type: 'GET_RETURN_POLICY';
      data: {
        shopHostName: string;
      };
    }
  | {
      type: 'UPDATE_RETURN_POLICY_IN_PREVIEW';
      data: {
        content: string;
      };
    };

// FIXME: 不应该是 MainFlow 启动，UI 自己启动，不属于主流程
export const returnPolicySubFlow = setup({
  types: {
    context: {} as ReturnPolicySubFlowContext,
    events: {} as Event,
  },
  actors: {
    getReturnPolicy,
  },
  actions: {
    updateContextInPreview: () => {},
  },
}).createMachine({
  initial: 'idle',

  states: {
    idle: {
      on: {
        GET_RETURN_POLICY: 'getReturnPolicy',
      },
    },
    getReturnPolicy: {
      initial: 'loading',
      states: {
        loading: {
          tags: ['loading'],
          invoke: {
            src: 'getReturnPolicy',
            input: ({ event }) => {
              assertEvent(event, 'GET_RETURN_POLICY');
              return { shopHostName: event.data.shopHostName };
            },
            onDone: {
              target: 'success',
              actions: assign({
                returnPolicyData: ({ event }) => event.output,
              }),
            },
            onError: 'error',
          },
        },
        success: {
          type: 'final',
        },
        error: {
          type: 'final',
        },
      },
    },
  },
  on: {
    UPDATE_RETURN_POLICY_IN_PREVIEW: {
      actions: 'updateContextInPreview',
    },
  },
});

export type ReturnPolicySubFlowActorRef = ActorRefFrom<typeof returnPolicySubFlow>;

export type ReturnPolicySubFlowSnapshot = SnapshotFrom<typeof returnPolicySubFlow>;
