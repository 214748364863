'use client';

import React, { useEffect, useState } from 'react';

// import { useUniversalRouting } from 'returns/hooks/useUniversalRouting';
import { Box, Card, Flex } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import {
  EventName,
  PayloadInUpdatePreviewData,
  PreviewMessageData,
  UpdatePreviewDataType,
} from '@aftership/preview-kit/business/rc';
import { usePreviewContext } from '@aftership/preview-kit/client';
import { Policy } from '@aftership/returns-logics-core';
import { useReturnPolicy } from 'returns-logics/react';

import RichTextRender from '@/components/RichTextRender/RichTextRender';
import { useMessageBridge } from '@/features/returns/hooks/useMessageBridge';
import { useShopInfo } from '@/features/returns/hooks/useShopInfo';
import useDevice from '@/hooks/useDevice';

import { policyMobile } from './style.css';
import template from './template';

const PrivacyPolicy = () => {
  const shop = useShopInfo();

  const { isPreview } = usePreviewContext();
  const { context, currentStep, ref } = useReturnPolicy(isPreview);

  const returnPolicyData = context?.returnPolicyData;
  const isMobile = useDevice().mobile;

  const [policyData, setPolicyData] = useState<Policy>({} as Policy);

  useEffect(() => {
    if (currentStep?.isLoading || !returnPolicyData) return;

    setPolicyData({
      ...returnPolicyData,
      content: returnPolicyData.content ?? template,
    });
  }, [returnPolicyData, currentStep?.isLoading]);

  let policyContent = policyData?.content ?? '';

  const messageBridge = useMessageBridge();

  useEffect(() => {
    const removeEventListener = messageBridge.onMessage({
      type: EventName.UpdatePreviewData,
      callback: (data: PreviewMessageData[EventName.UpdatePreviewData]) => {
        if (data.type === UpdatePreviewDataType.UpdatePolicyContent) {
          ref.send({
            type: 'UPDATE_RETURN_POLICY_IN_PREVIEW',
            data: {
              content: (data.payload as PayloadInUpdatePreviewData['update_policy_content'])
                .content,
            },
          });
        }
      },
    });
    return () => {
      removeEventListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPreview) {
    policyContent = policyContent.replaceAll('<a ', '<a style="pointer-events: none;" ');
  }

  const content = (
    <>
      <Flex justifyContent='center'>
        <RichTextRender
          componentType='style'
          content={`#policyContent * { margin: revert} #policyContent {width: 100%}`}
        />
        <RichTextRender
          id='policyContent'
          content={policyContent}
          style={{ fontFamily: shop?.returns_page_body_font }}
        />
      </Flex>
    </>
  );

  if (isMobile) {
    return (
      <Box className={policyMobile} width={'100%'} height={'100%'} backgroundColor='white'>
        {content}
      </Box>
    );
  }

  return (
    <Card width={824} paddingY={SpacingVars['10']} paddingX={SpacingVars['12']}>
      <Box minHeight={'40vh'}>{content}</Box>
    </Card>
  );
};

export default PrivacyPolicy;
