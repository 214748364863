import { useTranslation } from 'react-i18next';

import { IMainContext } from '@aftership/returns-logics-core';

import { useCachedAppProxy } from '@/features/returns/hooks/useCachedAppProxy';

export const RC_SHOP_NOW = 'rc_sn'; // rc_shopnow

interface GeneratePlatformEfaUrlParams {
  productUrl?: string;
  redirectUrl: string;
}

export const queryParamsToPersist = ['mode', 'lang', 'returns_page_access'] as const;

export function attachPersistQueryParamsToUrl(url: string) {
  const urlObject = new URL(url, location.origin);

  queryParamsToPersist.forEach((key) => {
    const value = new URLSearchParams(window.location.search).get(key);
    if (value) {
      urlObject.searchParams.set(key, value);
    }
  });
  return urlObject.toString();
}

export const useGeneratePlatformEfaUrl = (mainContext: IMainContext) => {
  const { order: orders } = mainContext?.request?.orders ?? {};
  const shopHostName = mainContext?.shopHostName;
  const shops = mainContext?.storeConfig?.shopInfo;
  const intentionId = mainContext?.intentionId ?? '';
  const sessionToken = (mainContext?.token ?? '').replace(/^Bearer\s/, '').trim();
  const { i18n } = useTranslation();
  const { pathPrefix } = useCachedAppProxy();
  const storeUrl = mainContext?.request?.orders?.store?.url ?? '';
  return {
    generatePlatformEfaUrl: ({ productUrl, redirectUrl }: GeneratePlatformEfaUrlParams) => {
      const searchParams = new URLSearchParams();
      const currency = orders?.order_total_set.presentment_money.currency;
      currency && searchParams.set('currency', currency);
      const referer = `${location.origin}${location.pathname}`;
      const appKey = orders?.app.key ?? '';
      const orgId = shops?.organization?.id ?? '';
      const shopnowParams = new URLSearchParams();
      shopnowParams.set('referer', attachPersistQueryParamsToUrl(referer));
      shopnowParams.set('appKey', appKey);
      shopnowParams.set('orgId', orgId);
      shopnowParams.set('intensionId', intentionId);
      shopnowParams.set('sessionToken', sessionToken);
      shopnowParams.set('language', i18n.language);
      shopnowParams.set('redirectUrl', attachPersistQueryParamsToUrl(redirectUrl || referer));
      shopnowParams.set('pathPrefix', pathPrefix);
      const encodeParams = btoa(shopnowParams.toString());

      searchParams.set(RC_SHOP_NOW, encodeParams);
      const url = productUrl ?? storeUrl;
      return `${url.includes('http') ? url : `//${url}`}?${searchParams.toString()}${
        shopHostName ? `&hostname=${shopHostName}` : ''
      }`;
    },
  };
};
