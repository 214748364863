import { t } from 'i18next';

import { SelectSingleItemSubFlowSnapshot } from '@aftership/returns-logics-core';

import { AskQuestionWorkflow, genAskQuestionWorkflowCode } from '@/i18n/dynamic';

export const generateTitle = (
  stepName: SelectSingleItemSubFlowSnapshot['value'],
  questionId?: string,
  questionTitle?: string,
): string => {
  if (typeof stepName === 'object' && Object.hasOwn(stepName, 'selectQuestionAnswer')) {
    return t(
      genAskQuestionWorkflowCode({
        flowId: questionId ?? '',
        type: AskQuestionWorkflow.Text,
      }),
      {
        rawValue: questionTitle,
        defaultValue: questionTitle,
      },
    );
  } else {
    switch (stepName) {
      case 'selectQuantity':
        return t('page.description.howManyYouReturn');
      case 'selectReason':
      case 'selectSubReason':
        return t('page.description.whyReturning');
      case 'fillMoreDetail':
        return t('page.description.moreDetails');
      default:
        return '';
    }
  }
};
