import { ShippingAddress } from '@aftership/returns-logics-core';

export const getFullShippingAddress = (shippingAddress?: ShippingAddress | null) => {
  if (!shippingAddress) {
    return '';
  }

  const {
    first_name,
    last_name,
    address_line_1,
    address_line_2,
    address_line_3,
    city,
    state,
    postal_code,
    country,
  } = shippingAddress || {};

  const fullShippingAddress = [
    `${first_name || ''} ${last_name || ''}`,
    address_line_1,
    address_line_2,
    address_line_3,
    city,
    state,
    postal_code,
    country,
  ]
    .filter(Boolean)
    .join(', ');

  return fullShippingAddress;
};

/**
 *  判断是否需要显示 QRCode
 */
export function isQRCodeEnable(courierSlug?: string, labelQrCode?: null | string) {
  // 有 QrCode 信息
  return labelQrCode && courierSlug;
}
