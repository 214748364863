import {
  BundleReturnType,
  OrderItemType,
  SelectionGroupingItem,
} from '@aftership/returns-logics-core';

import { SelectedItem } from '@/features/request-returns/types';

export const isCantSelectedByRoutingRule = (
  itemId: string,
  itemSelectionGroupings: SelectionGroupingItem[],
  selectedItems: SelectedItem[],
) => {
  // 判断当前 item 是否与已选中的 item 是否有 condition 交集
  const matchingAnyGroups = itemSelectionGroupings
    // 先从所有组合的 item_ids 中找出包含当前已选中 item item_id 的组合
    .filter((group) =>
      // 如果没有选中任何 item , 则 filter 出所有组合, 因为[].every() 为 true
      selectedItems.every((item) => group.item_ids.includes(item.itemId)),
    )
    // 再从这些组合中找出包含当前 item item_id 的组合,如果存在,则可选,否则不可选
    .some((group) => group.item_ids.includes(itemId));
  return !matchingAnyGroups;
};

export const isCantSelectedByLimit = (
  itemId: string,
  isLimitSingleItemPerReturn: boolean,
  selectedItems: SelectedItem[],
) =>
  selectedItems.length > 0 &&
  isLimitSingleItemPerReturn &&
  selectedItems.every((item) => item.itemId !== itemId);

/**
 *  根据规则,查询给定的 returnableItem 的各种状态信息
 * @param returnableItem 商品的基础信息
 * @param selectedItems 选中的 item  + line item 列表
 * @param itemSelectionGrouping 所有 item  + line item 可退的组合
 * @param currentExpandId 当前展开的 item id
 * @param isLimitSingleItemPerReturn  是否开启了一次只能退一个商品
 */
export const queryReturnItemStatus = (
  returnableItem: RequestReturn.ReturnableItemProps,
  selectedItems: Array<SelectedItem>,
  itemSelectionGrouping: Array<SelectionGroupingItem>,
  currentExpandId: string,
  isLimitSingleItemPerReturn: boolean,
) => {
  const { returnable, itemId, parentId, returnRule, type, bundles } = returnableItem;
  const returnInfo = selectedItems.find(
    (selectedItem) => itemId === selectedItem.itemId && parentId === selectedItem.parentId,
  );
  const isSelected = Boolean(returnInfo);

  const isSomeLineItemSelected =
    type === OrderItemType.BUNDLE &&
    (bundles?.some((item) =>
      selectedItems.find((selectedItem) => selectedItem.itemId === item.itemId),
    ) ??
      false);

  const expanded = itemId === currentExpandId || isSomeLineItemSelected;
  const cantSelectedByRoutingRule = isCantSelectedByRoutingRule(
    itemId,
    itemSelectionGrouping,
    selectedItems,
  );
  const cantSelectedByLimit = isCantSelectedByLimit(
    itemId,
    isLimitSingleItemPerReturn,
    selectedItems,
  );

  const expandability =
    type === OrderItemType.BUNDLE &&
    returnRule === BundleReturnType.PARTIAL &&
    (bundles?.length ?? 0) > 0 &&
    !isSelected &&
    !isSomeLineItemSelected;

  const canWholeReturn =
    returnable &&
    expandability &&
    (bundles?.every(
      (item) => !selectedItems.find((selectedItem) => selectedItem.itemId === item.itemId),
    ) ??
      true) &&
    !cantSelectedByLimit &&
    !cantSelectedByRoutingRule;

  return {
    returnInfo,
    expanded,
    isSelected,
    expandability,
    cantSelectedByRoutingRule,
    cantSelectedByLimit,
    canWholeReturn,
  };
};
